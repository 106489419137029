import React, { useEffect, useState } from "react";
import * as Modal from "../Modal/modal";
import Select from "react-tailwindcss-select";
import { useAlert } from "react-alert";

export default function EdgeOverlay({
  editEdge,
  setEditEdge,
  onCloseCallback,
  reactFlowInstance,
  refreshEdges,
  deleteEdge,
  setRecomputeMapWhenUpdate,
}) {
  const [saveEditEdge, setSaveEditEdge] = useState(false);
  const [freeSrcInterfaces, setFreeSrcInterfaces] = useState([]);
  const [freeDstInterfaces, setFreeDstInterfaces] = useState([]);
  const [edgeIsComputed, setEdgeIsComputed] = useState(false);
  const alert = useAlert();

  const getAllInterfaces = (node) => {
    if (node.data.interface_data) {
      return Object.keys(node.data.interface_data);
    } else {
      return [];
    }
  };

  const getInterfacesInUse = (node) => {
    return reactFlowInstance
      .getEdges()
      .filter(
        (edge) => edge["source"] === node.id || edge["target"] === node.id
      )
      .map((edge) => {
        if (edge["source"] === node.id) {
          return edge.data.SourceInterfaceValue;
        } else {
          return edge.data.DestinationInterfaceValue;
        }
      })
      .filter((value_if_not_undefined) => value_if_not_undefined);
  };

  useEffect(() => {
    const srcNode = reactFlowInstance.getNode(editEdge.source);
    const dstNode = reactFlowInstance.getNode(editEdge.target);

    const srcFreeInterfaces = getAllInterfaces(srcNode).filter(
      (int) => !getInterfacesInUse(srcNode).includes(int)
    );
    const dstFreeInterfaces = getAllInterfaces(dstNode).filter(
      (int) => !getInterfacesInUse(dstNode).includes(int)
    );

    setFreeSrcInterfaces(
      srcFreeInterfaces.map((int) => new Object({ label: int, value: int }))
    );
    setFreeDstInterfaces(
      dstFreeInterfaces.map((int) => new Object({ label: int, value: int }))
    );

    setEdgeIsComputed(editEdge.data.Computed);
  }, []);

  useEffect(() => {
    if (saveEditEdge) {
      setSaveEditEdge(false);
      saveEdge("SAVE");
    }
  }, [editEdge, saveEditEdge]);

  const saveEdge = (action) => {
    setRecomputeMapWhenUpdate(true);
    if (action === "DELETE") {
      deleteEdge(editEdge);
      onCloseCallback();
    } else {
      refreshEdges();
    }
  };

  return (
    <Modal.Frame
      onClose={() => {
        onCloseCallback();
        // get onClose from map like node information ?
      }}
    >
      <Modal.Head>Connection view</Modal.Head>
      <Modal.Body>
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-row w-full justify-center items-center grow">
            <div className="flex flex-col w-1/2">
              <div className="block text-gray-base text-sm font-bold align-center w-1/3 mb-2">
                Network device 1:
              </div>
              <div className="flex flex-row items-center mb-2 w-full">
                <div className="block text-gray-base text-sm align-center w-1/3">
                  Device Name:
                </div>
                <div className="block text-gray-base text-sm text-optistream-txt-blue align-center w-64">
                  {reactFlowInstance.getNode(editEdge.source).data.HostName}
                </div>
              </div>
              <div className="flex flex-row items-center mb-2 ">
                <div className="block text-gray-base text-sm align-center w-1/3">
                  Open interfaces:
                </div>
                <div>
                  <Select
                    primaryColor={"indigo"}
                    value={{
                      value: editEdge.data.SourceInterfaceValue,
                      label: editEdge.data.SourceInterfaceLabel,
                      disabled: false,
                    }}
                    isSearchable={true}
                    isDisabled={
                      freeSrcInterfaces.length === 0 || edgeIsComputed
                    }
                    name="interface"
                    onChange={(element) => {
                      setEditEdge({
                        ...editEdge,
                        data: {
                          ...editEdge.data,
                          SourceInterfaceValue: element.value,
                          SourceInterfaceLabel: element.label,
                        },
                      });
                      //setSaveEditEdge(true);
                    }}
                    options={freeSrcInterfaces}
                    classNames={{
                      menuButton: ({ isDisabled }) =>
                        `flex w-64 text-sm border border-gray-300 rounded shadow-sm
                                                    ${
                                                      editEdge.data
                                                        .SourceInterfaceLabel ===
                                                      ""
                                                        ? "text-red-primary"
                                                        : "text-gray-base"
                                                    }  
                                                    transition-all duration-300 focus:outline-none ${
                                                      isDisabled
                                                        ? "bg-gray-200"
                                                        : "bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                                                    }`,
                      menu: "absolute z-10 w-64 bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-200",
                      listItem: ({ isSelected }) =>
                        `block transition duration-200 px-2 py-2 cursor-pointer 
                                                    select-none truncate rounded ${
                                                      isSelected
                                                        ? `text-optistream-txt-white bg-blue-menu`
                                                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                                                    }`,
                    }}
                  />
                </div>
              </div>
              {freeSrcInterfaces.length === 0 && !edgeIsComputed ? (
                <div className="block italic text-red-primary text-sm align-center">
                  No free interface or no configuration file loaded
                </div>
              ) : null}
            </div>
            <div className="flex flex-col w-1/2">
              <div className="block text-gray-base text-sm font-bold align-center w-1/3 mb-2">
                Network device 2:
              </div>
              <div className="flex flex-row items-center mb-2 w-full">
                <div className="block text-gray-base text-sm align-center w-1/3">
                  Device Name:
                </div>

                <div className="block text-gray-base text-sm text-optistream-txt-blue align-center w-64">
                  {reactFlowInstance.getNode(editEdge.target).data.HostName}
                </div>
              </div>
              <div className="flex flex-row items-center mb-2 ">
                <div className="block text-gray-base text-sm align-center w-1/3">
                  Open interfaces:
                </div>
                <div>
                  <Select
                    primaryColor={"indigo"}
                    value={{
                      value: editEdge.data.DestinationInterfaceValue,
                      label: editEdge.data.DestinationInterfaceLabel,
                      disabled: false,
                    }}
                    isSearchable={true}
                    isDisabled={
                      freeDstInterfaces.length === 0 || edgeIsComputed
                    }
                    name="equipmentType"
                    onChange={(element) => {
                      setEditEdge({
                        ...editEdge,
                        data: {
                          ...editEdge.data,
                          DestinationInterfaceValue: element.value,
                          DestinationInterfaceLabel: element.label,
                        },
                      });
                      //setSaveEditEdge(true);
                    }}
                    options={freeDstInterfaces}
                    classNames={{
                      menuButton: ({ isDisabled }) =>
                        `flex w-64 text-sm border border-gray-300 rounded shadow-sm 
                                                    ${
                                                      editEdge.data
                                                        .DestinationInterfaceLabel ===
                                                      ""
                                                        ? "text-red-primary"
                                                        : "text-gray-base"
                                                    }  
                                                    transition-all duration-300 focus:outline-none ${
                                                      isDisabled
                                                        ? "bg-gray-200"
                                                        : "bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                                                    }`,
                      menu: "absolute z-10 w-64 bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-200",
                      listItem: ({ isSelected }) =>
                        `block transition duration-200 px-2 py-2 cursor-pointer 
                                                    select-none truncate rounded ${
                                                      isSelected
                                                        ? `text-optistream-txt-white bg-blue-menu`
                                                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                                                    }`,
                    }}
                  />
                </div>
              </div>
              {freeDstInterfaces.length === 0 && !edgeIsComputed ? (
                <div className="block italic text-red-primary text-sm align-center">
                  No free interface or no configuration file loaded
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex flex-row justify-end items-center pt-2">
            {edgeIsComputed ? null : (
              <>
                {" "}
                <button
                  className=" mt-2 bg-optistream-blue text-optistream-txt-white rounded h-8 text-sm font-bold w-24"
                  onClick={() => {
                    setSaveEditEdge(true);
                    //onCloseCallback();
                  }}
                >
                  Save
                </button>
                <button
                  className="mt-2 ml-4 bg-red-primary text-optistream-txt-white rounded h-8 font-bold w-24"
                  onClick={() => saveEdge("DELETE")}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal.Frame>
  );
}
