const destructiveMethods = ["post", "put", "patch", "delete"];
const resourceEtagMapping = {};

export function saveEtag(config) {
  if (config.headers.etag) {
    const resource = config.config.url;
    const etag = config.headers.etag;

    resourceEtagMapping[resource] = etag;
  }
  return config;
}

export function sendEtag(config) {
  if (
    destructiveMethods.includes(config.method) &&
    config.url in resourceEtagMapping
  ) {
    config.headers["if-match"] = resourceEtagMapping[config.url];
  }
  return config;
}
