import React, { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";
import { XSquareFill } from "react-bootstrap-icons";

import { useAPI } from "../../../../utils";

export default function NodeOverlayTabApplications({ editNode, setEditNode }) {
  const [businessServices, setBusinessServices] = useState([]);
  const [applications, setApplications] = useState([]);
  const [appOptions, setAppOptions] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [displayHierarchy, setDisplayHierarchy] = useState({});

  const { listBusinessServices, listApplications } = useAPI();

  useEffect(() => {
    listBusinessServices()
      .then(setBusinessServices)
      .catch((e) => void e);
  }, []);

  useEffect(() => {
    const fetchApps = async () => {
      const appPromises = businessServices.map((bs) =>
        listApplications(bs.id).then((apps) => apps)
      );

      var allApps = [];

      const appsResult = await Promise.all(appPromises);

      appsResult.forEach((apps) => {
        allApps = [...allApps, ...apps];
      });

      setApplications(allApps);
    };

    fetchApps();
  }, [businessServices]);

  useEffect(() => {
    var hierarchy = {};

    applications.forEach((app) => {
      const bs = getBSById(app.business_service_id);
      if (bs) {
        if (!hierarchy[bs.business_domain]) {
          hierarchy[bs.business_domain] = [];
        }
        if (!hierarchy[bs.business_domain][bs.name]) {
          hierarchy[bs.business_domain][bs.name] = [];
        }
        hierarchy[bs.business_domain][bs.name].push(app);
      }
    });

    const domains = [
      ...new Set(businessServices.map((bs) => bs.business_domain)),
    ];

    const opts = domains.map((domain) => {
      const appsForDomain = applications.filter(
        (app) => getBSById(app.business_service_id).business_domain === domain
      );
      return new Object({
        label: domain,
        options: appsForDomain.map((app) => {
          return new Object({
            label: `${getBSById(app.business_service_id).name} - ${app.name}`,
            value: app.id,
          });
        }),
      });
    });

    setAppOptions(opts);
  }, [applications]);

  useEffect(() => {
    var hierarchy = {};

    (editNode.data.BusinessServices || []).forEach((conf) => {
      const bs = getBSById(conf.businessService);
      const app = getAppById(conf.appId);
      if (bs && app) {
        if (!hierarchy[bs.business_domain]) {
          hierarchy[bs.business_domain] = {};
        }

        if (!hierarchy[bs.business_domain][bs.id]) {
          hierarchy[bs.business_domain][bs.id] = [];
        }

        hierarchy[bs.business_domain][bs.id].push(app.id);
      }
    });
    setDisplayHierarchy(hierarchy);
  }, [editNode, businessServices, applications]);

  const getBSById = (BSId) => businessServices.find((bs) => bs.id == BSId);

  const getAppById = (appId) => applications.find((app) => app.id == appId);

  const saveSelectedApp = () => {
    var configuredApps = editNode.data.BusinessServices || [];

    if (!configuredApps.find((elt) => elt.appId === selectedApp.value)) {
      configuredApps.push({
        businessService: getBSById(
          getAppById(selectedApp.value).business_service_id
        ).id,
        appId: selectedApp.value,
        appName: getAppById(selectedApp.value).name,
      });
    }

    setSelectedApp(null);

    setEditNode({
      ...editNode,
      data: {
        ...editNode.data,
        BusinessServices: configuredApps,
      },
    });
  };

  const deleteApp = (appId) => {
    const updatedApps = editNode.data.BusinessServices.filter(
      (conf) => conf.appId != appId
    );

    setEditNode({
      ...editNode,
      data: {
        ...editNode.data,
        BusinessServices: updatedApps,
      },
    });
  };

  return (
    <div className="flex flex-col text-gray-base w-full">
      <div className="flex flex-row items-center space-x-6">
        <Select
          options={appOptions}
          value={selectedApp}
          onChange={setSelectedApp}
        />{" "}
        <button
          className="bg-optistream-btn-default active:bg-optistream-btn-clicked text-optistream-txt-white 
              h-8 text-sm w-40 rounded font-bold disabled:opacity-50"
          disabled={selectedApp === null}
          onClick={saveSelectedApp}
        >
          Add
        </button>
      </div>
      <div className="pt-4">
        {Object.keys(displayHierarchy).map((domain) => (
          <ul>
            <li className="text-blue-medium uppercase font-bold pt-1">
              {domain}
            </li>
            <li>
              {Object.keys(displayHierarchy[domain]).map((bsId) => (
                <ul className="indent-2">
                  <li className="font-semibold">{getBSById(bsId).name}</li>
                  <li className="indent-4">
                    {displayHierarchy[domain][bsId].map((appId) => (
                      <p>
                        <XSquareFill
                          width={20}
                          height={20}
                          color="crimson"
                          class="inline"
                          onClick={() => deleteApp(appId)}
                        />{" "}
                        {getAppById(appId).name}{" "}
                      </p>
                    ))}
                  </li>
                </ul>
              ))}
            </li>
          </ul>
        ))}
      </div>
    </div>
  );
}
