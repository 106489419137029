import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import Insights from "@mui/icons-material/Insights";
import ChecklistIcon from "@mui/icons-material/Checklist";

import * as ROUTES from "../../../constants/routes";

export const navData = [
  {
    id: 1,
    type: "link",
    icon: <HomeIcon />,
    text: "Home",
    link: ROUTES.HOME,
  },
  {
    id: 2,
    type: "separator",
  },
  {
    id: 3,
    type: "link",
    icon: <Insights />,
    text: "Business security cockpit",
    link: ROUTES.BUSINESS_COCKPIT,
  },
  {
    id: 4,
    type: "separator",
  },
  {
    id: 5,
    type: "link",
    icon: <ChecklistIcon />,
    text: "Analysis history",
    link: ROUTES.ANALYSIS_HISTORY,
  },
  {
    id: 6,
    type: "separator",
  },
  {
    id: 7,
    type: "link",
    icon: <SettingsIcon />,
    text: "Business process",
    link: ROUTES.CONFIG_BUSINESS_SERVICE,
  },
];
