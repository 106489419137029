import { useEffect, useRef } from "react";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

// From https://pgarciacamou.medium.com/react-simple-polling-custom-hook-usepollingeffect-1e9b6b8c9c71
function usePollingEffect(
  asyncCallback,
  stopCondition,
  dependencies = [],
  { interval = 1_000, onCleanUp = () => {} } = {}
) {
  const timeoutIdRef = useRef(null);
  useEffect(() => {
    let _stopped = false;

    if (!stopCondition()) {
      // Side note: preceding semicolon needed for IIFEs.
      (async function pollingCallback() {
        try {
          await asyncCallback();
        } finally {
          // Set timeout after it finished, unless stopped
          timeoutIdRef.current =
            !_stopped && setTimeout(pollingCallback, interval);
        }
      })();
    }
    // Clean up if dependencies change
    return () => {
      _stopped = true; // prevent racing conditions
      clearTimeout(timeoutIdRef.current);
      onCleanUp();
    };
  }, [...dependencies, interval]);
}

export default { usePrevious, usePollingEffect };
