import React, { useState, useEffect } from "react";

import { Chart as ChartJS, ArcElement, Filler, Tooltip } from "chart.js";

import { Doughnut } from "react-chartjs-2";

ChartJS.register(Filler, Tooltip, ArcElement);

function getColor(valueScore) {
  if (valueScore === "-") return "rgb(155, 155, 155)";
  if (valueScore < 25) return "#C62828";
  if (valueScore < 50) return "#E57373";
  if (valueScore < 75) return "#81C784";

  return "#2E7D32";
}

export default function WidgetScoreChart({ valueScore }) {
  const mainColor = getColor(valueScore);
  const [displayText, setDisplayText] = useState("");
  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });

  const options = {
    cutout: "65%",
    animation: {
      animateRotate: true,
      animateScale: false,
    },
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      tooltip: {
        titleFont: {
          size: 14,
          lineHeight: 1.5,
        },
        bodyFont: {
          size: 14,
          lineHeight: 1.8,
        },
      },
    },
  };

  useEffect(() => {
    if (valueScore === "-") {
      setDisplayText("N/A");
      setDataChart({
        labels: ["Secured paths", "Paths at risk"],
        datasets: [
          {
            label: "Score",
            data: [0, 100],
            backgroundColor: [mainColor, "rgb(220, 220, 220)"],
            hoverOffset: 4,
          },
        ],
      });
    } else {
      setDisplayText(valueScore);
      setDataChart({
        labels: ["Secured paths", "Paths at risk"],
        datasets: [
          {
            label: "Score",
            data: [valueScore, 100 - valueScore],
            backgroundColor: [mainColor, "rgb(220, 220, 220)"],
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [valueScore]);

  const pluginsText = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = ((height * 1.25) / 100).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.fillStyle = mainColor;
        ctx.textBaseline = "top"; //"top";
        var text = displayText,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2 + (fontSize * 10) / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <Doughnut data={dataChart} options={options} plugins={pluginsText} redraw />
  );
}
