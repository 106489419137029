import React, { useState, useEffect } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  Filler,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";

import { Line } from "react-chartjs-2";

ChartJS.register(
  Filler,
  Tooltip,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

//const labels = Utils.months({ count: 7 });

export default function WidgetScoreHistory({ historicalData }) {
  const data = {
    labels: historicalData.date,
    datasets: [
      {
        label: "Score",
        data: historicalData.score,

        pointRadius: 4,
        pointBackgroundColor: "rgb(30, 136, 229)",

        borderColor: "rgb(30, 136, 229)",
        backgroundColor: "rgb(30, 136, 229, 0.33)",
        fill: true,
        tension: 0.2,
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Line Chart",
        },
      },
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 20,
            font: {
              size: 14,
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
  };
  return <Line data={data} options={config.options} />;
}
