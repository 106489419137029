import React, { useState } from "react";
import { ZoomIn, XCircle, HandIndexThumbFill } from "react-bootstrap-icons";
import * as Utils from "../misc/utils";

import { nodeDisplayName } from "../misc/utils";

export default function DetailServiceAttackPath({
  analysis,
  targetBusinessServicePaths,
}) {
  const [expandIndex, setExpandIndex] = useState(null);

  const onCloseExpand = () => {
    setExpandIndex(null);
  };

  const map = JSON.parse(analysis.snapshot.map);
  const nodeNameFromId = (nodeId) => {
    const node = map.nodes.find((node) => node.id === nodeId);

    return nodeDisplayName(node);
  };

  const stringifyHighRiskServices = (openedServices) => {
    var res = "";
    for (const [defaultPort, proto] of Object.entries(openedServices)) {
      if (proto.score > 0.8)
        if (res === "") res += proto.service + " (" + defaultPort + ")";
        else res += ", " + proto.service + " (" + defaultPort + ")";
    }

    return res;
  };

  const stringifyAltRiskServices = (openedServices) => {
    var res = "";
    for (const [defaultPort, proto] of Object.entries(openedServices)) {
      if (proto.score <= 0.8 && proto.score > 0.5)
        if (res === "") res += proto.service + " (" + defaultPort + ")";
        else res += ", " + proto.service + " (" + defaultPort + ")";
    }

    return res;
  };

  const displayServiceCategory = (audited_protos, protos, category) => {
    const structuredProto = Utils.structureOpenProto(audited_protos, protos)[
      category
    ];

    return (
      <div className="flex flex-wrap fit">
        {Object.keys(structuredProto).map((label, index) => (
          <div className="group relative mx-4 flex justify-start">
            <div
              className={` mb-1 mr-1 rounded-full text-optistream-txt-white px-2 py-0.5 w-fit h-fit ${
                category === "Pivot"
                  ? "bg-vulnCategory-Pivot"
                  : category === "Data"
                  ? "bg-vulnCategory-Data"
                  : "bg-vulnCategory-AttackDiscovery"
              }`}
            >
              {label}
            </div>
            <span className="absolute right-10 scale-0 w-96 rounded bg-gray-800 p-2 text-sm text-optistream-txt-white group-hover:scale-100 z-10 delay-300">
              {Object.keys(structuredProto[label]).map((service) => (
                <li>
                  {service} {"[Port(s): "}
                  {structuredProto[label][service].map((port, indexPort) => (
                    <>
                      {indexPort !== 0 && ", "}
                      {port}
                    </>
                  ))}
                  {"]"}
                </li>
              ))}
            </span>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="flex flex-row w-full h-full bg-white p-4">
      <div className="flex flex-col w-full px-4">
        <div className=" overflow-auto mt-4">
          <table className=" text-xs text-left text-black-light flex flex-col w-full">
            <thead className="text-xs text-optistream-txt-blue uppercase bg-gray-background flex w-full ">
              <tr className="flex flex-row w-full">
                <th
                  scope="col"
                  className="px-2 py-2 items-center justify-start flex w-2/12"
                >
                  From <br /> (Zone of origin)
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 items-center justify-start flex w-1/12"
                >
                  To <br /> (Target)
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 items-center justify-start flex w-1/12"
                >
                  # Pivots
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 items-center justify-center flex w-3/12"
                >
                  Risk of pivot
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 items-center justify-center flex w-2/12"
                >
                  Risk on data
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 items-center justify-center flex w-2/12"
                >
                  Risk on attack / discovery
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 text-center items-center justify-center flex w-1/12"
                >
                  Expand
                </th>
              </tr>
            </thead>
            <tbody className="flex flex-col w-full">
              {targetBusinessServicePaths.map((path, index) =>
                path.bypass_routes.map((bypass, bypassIndex) => (
                  <div className="flex flex-col w-full">
                    <tr
                      className={`flex w-full flex-row border-y hover:bg-blue-faded-medium ${
                        expandIndex === index + "-" + bypassIndex &&
                        "bg-blue-faded-medium"
                      }`}
                      key={index}
                    >
                      <th
                        scope="row"
                        className="px-2 py-2 font-medium whitespace-nowrap flex w-2/12"
                      >
                        {nodeNameFromId(path.src_node)}
                      </th>
                      <th
                        scope="row"
                        className="px-2 py-2 font-medium whitespace-nowrap flex w-1/12"
                      >
                        {nodeNameFromId(path.dst_node)}
                      </th>
                      <td className="px-2 py-2 flex w-1/12">
                        {bypass.route.length - 2}
                      </td>
                      <td className="px-2 py-2 flex w-3/12"></td>
                      <td className="px-2 py-2 flex w-2/12"></td>
                      <td className="px-2 py-2 flex w-2/12"></td>

                      <td className="text-center text-base flex w-1/12 items-center justify-center">
                        {expandIndex === null ||
                        expandIndex !== index + "-" + bypassIndex ? (
                          <button
                            onClick={() =>
                              setExpandIndex(index + "-" + bypassIndex)
                            }
                          >
                            <ZoomIn className="text-optistream-txt-blue" />
                          </button>
                        ) : (
                          <button onClick={() => setExpandIndex(null)}>
                            <XCircle className="text-red-primary" />
                          </button>
                        )}
                      </td>
                    </tr>
                    {expandIndex === index + "-" + bypassIndex &&
                      bypass.route.map((step, stepIndex) => (
                        <>
                          {stepIndex ? (
                            <tr className="flex flex-row w-full">
                              <th
                                scope="row"
                                className="px-2 py-2 font-medium whitespace-nowrap  flex w-2/12"
                              >
                                {
                                  //step === path.src_node &&
                                  nodeNameFromId(bypass.route.at(stepIndex - 1))
                                }
                              </th>
                              <th
                                scope="row"
                                className="px-2 py-2 font-medium whitespace-nowrap flex w-1/12"
                              >
                                {
                                  //step === path.dst_node &&
                                  nodeNameFromId(step)
                                }
                              </th>

                              <td className="px-2 py-2  flex w-1/12"></td>
                              <td className="px-2 py-2  flex w-3/12">
                                {displayServiceCategory(
                                  analysis.result["audited_protos"],
                                  bypass.opened_services[stepIndex - 1].protos,
                                  "Pivot"
                                )}
                              </td>
                              <td className="px-2 py-2 flex w-2/12">
                                {displayServiceCategory(
                                  analysis.result["audited_protos"],
                                  bypass.opened_services[stepIndex - 1].protos,
                                  "Data"
                                )}
                              </td>
                              <td className="px-2 py-2 flex w-2/12">
                                {displayServiceCategory(
                                  analysis.result["audited_protos"],
                                  bypass.opened_services[stepIndex - 1].protos,
                                  "AttackDiscovery"
                                )}
                              </td>

                              <td className="px-2 py-2 flex w-1/12"></td>
                            </tr>
                          ) : null}
                        </>
                      ))}
                  </div>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
