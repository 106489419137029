import colorLib from "@kurkle/color";
import * as ROUTES from "../../../constants/routes";

export function groupBusinessServicesByDomain(businessServices) {
  return businessServices.reduce((acc, businessService) => {
    businessService.business_domain
      ? (acc[businessService.business_domain] =
          acc[businessService.business_domain] || []).push(businessService)
      : (acc[""] = acc[""] || []).push(businessService);

    return acc;
  }, {});
}

export const businessSensitivityLevels = [
  { value: 0, label: "Commodity" },
  { value: 1, label: "Standard" },
  { value: 2, label: "Sensitive" },
  { value: 3, label: "Critical" },
];

export const businessSensitivityToString = (sensitivityInteger) => {
  switch (sensitivityInteger) {
    case 0:
      return "Commodity";
    case 1:
      return "Standard";
    case 2:
      return "Sensitive";
    case 3:
      return "Critical";
  }
};

export const buildDstNodeToBusinessServicesDict = (auditResult) => {
  const dictNodeToBS = {};
  auditResult.business_services.forEach((bs) => {
    bs.associated_nodes.forEach((node) => {
      (dictNodeToBS[node] = dictNodeToBS[node] || []).push({
        id: bs.id,
        name: bs.name,
        sensitivity: bs.sensitivity,
        business_domain: bs.business_domain,
      });
    });
  });

  return dictNodeToBS;
};

export const CHART_COLORS = [
  "rgb(153, 102, 255)", // purple:
  "rgb(54, 162, 235)", // blue:
  "rgb(75, 192, 192)", // green:
  "rgb(255, 205, 86)", // yellow:
  "rgb(255, 159, 64)", // orange:
  "rgb(255, 99, 132)", // red
  "rgb(201, 203, 207)", // grey:
];
export const CHART_COLORS_ALPHA = [
  "rgba(153, 102, 255, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(255, 205, 86, 0.2)",
  "rgba(255, 159, 64, 0.2)",
  "rgba(255, 99, 132, 0.2)",
  "rgba(201, 203, 207, 0.2)",
];

export const threatExposureLevels = [
  { value: "Limited exposure", label: "Limited exposure" },
  { value: "Contained exposure", label: "Contained exposure" },
  { value: "Strong exposure", label: "Strong exposure" },
  { value: "Public exposure", label: "Public exposure" },
];

export function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

export const computeNbSecuredPaths = (analysis_result, targetNodes = null) => {
  let cptWeakerRoutes = 0;
  let cptTotalRoutes = 0;
  let matched = false;
  if (analysis_result && analysis_result["result"]) {
    for (const audited_path of analysis_result["result"]) {
      if (!targetNodes || targetNodes.includes(audited_path.dst_node)) {
        matched = true;
        cptTotalRoutes++;
        if (audited_path.nb_weaker_alternate_routes) cptWeakerRoutes++;
      }
    }
  }
  if (matched) {
    return Math.round((1 - cptWeakerRoutes / cptTotalRoutes) * 100);
  } else {
    return 100;
  }
};

export const structureOpenProto = (audited_protos, protos) => {
  const res = {
    Pivot: {},
    Data: {},
    AttackDiscovery: {},
  };
  protos.forEach((port) => {
    if (port && audited_protos[port]) {
      const cat = audited_protos[port].category;
      const label = audited_protos[port].label;
      const service = audited_protos[port].service;
      if (res[cat][label])
        if (res[cat][label][service]) res[cat][label][service].push(port);
        else res[cat][label][service] = [port];
      else {
        res[cat][label] = {};
        res[cat][label][service] = [port];
      }
    }
  });
  return res;
};

export const analysisTypes = [
  // { value: "internet_exposure", label: "Internet exposure", page: "#" },
  {
    value: "high_risk_routes",
    label: "High risk routes",
    page: ROUTES.RISKY_ROUTES,
  },
  {
    value: "lateralization",
    label: "Lateralization porosity",
    page: ROUTES.INFILTRATION,
  },
  // {
  //   value: "cross_zone_reachability",
  //   label: "Cross-zone reachability",
  //   page: "#",
  // },
  // { value: "data_exflitration", label: "Direct data exfiltration", page: "#" },
];

export const analysisStatus = [
  { value: "READY", label: "Ready" },
  { value: "PROCESSING", label: "Processing" },
  { value: "QUEUED", label: "Queued" },
  { value: "ERROR", label: "Error" },
];

export const analysisTypeToName = (value) => {
  return analysisTypes.find((entry) => entry.value === value).label;
};

export const nodeDisplayName = (node) => {
  return (
    node.data.DisplayName ||
    node.data.HostName ||
    node.data.IP ||
    "Unnamed node"
  );
};
