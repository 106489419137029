import React from "react";
//import ReactDOM from 'react-dom';
import "./styles/app.css";
import App from "./App";

import { createRoot } from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
//import 'bootstrap/dist/css/bootstrap.min.css';

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { Check2Circle, XCircle, InfoCircle } from "react-bootstrap-icons";

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 2000,
  offset: "20px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 60,
  },
};

const AlertTemplate = ({ style, options, message, close }) => (
  <div>
    {options.type === "success" ? (
      <div
        className="flex mt-24 p-4 mb-4 min-w-96 
                                border-t-4 
                                bg-optistream-alert-successBg 
                                text-optistream-alert-successTxt
                                border-optistream-alert-successBorder
                                dark:text-optistream-alert-dark-successTxt 
                                dark:bg-optistream-alert-dark-successBg 
                                dark:border-optistream-alert-dark-successBorder 
                                items-center"
      >
        <div className="scale-150">
          <Check2Circle />
        </div>
        <div className="ml-3 text-base font-medium">{message}</div>
      </div>
    ) : options.type === "error" ? (
      <div
        className="flex mt-24 p-4 mb-4 min-w-96 
                                    border-t-4 
                                    text-optistream-alert-errorTxt
                                    border-optistream-alert-errorBorder
                                    bg-optistream-alert-errorBg
                                    dark:text-optistream-alert-dark-errorTxt
                                    dark:bg-optistream-alert-dark-errorBg
                                    dark:border-optistream-alert-dark-errorBorder
                                    items-center"
      >
        <div className="scale-150">
          <XCircle />
        </div>
        <div className="ml-3 text-base font-medium">{message}</div>
      </div>
    ) : (
      <div
        className="flex mt-24 p-4 mb-4 min-w-96 
                                    border-t-4 
                                    text-optistream-alert-infoTxt
                                    border-optistream-alert-infoBorder 
                                    bg-optistream-alert-infoBg
                                    dark:text-optistream-alert-dark-infoTxt
                                    dark:bg-optistream-alert-dark-infoBg 
                                    dark:border-optistream-alert-dark-infoBg 
                                    items-center"
      >
        <div className="scale-150">
          <InfoCircle />
        </div>
        <div className="ml-3 text-base font-medium">{message}</div>
      </div>
    )}
  </div>
);

const msalInstance = new PublicClientApplication(msalConfig);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <MsalProvider instance={msalInstance}>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  </MsalProvider>
);
