import { useState } from "react";

export default function NodeOverlayFormIPConfigurable({
  editNode,
  setEditNode,
  setRecomputeMapWhenUpdate,
}) {
  const [IPAddress, setIPAddress] = useState(editNode.data.IP);
  const [netmask, setNetmask] = useState(editNode.data.NetMask);
  const [gateway, setGateway] = useState(editNode.data.Gateway);

  return (
    <fieldset className="border border-gray-100 p-2 h-full">
      <legend className="font-bold">IP configuration</legend>
      <table className="w-full table-fixed  border-separate border-spacing-y-2">
        <tr>
          <td>
            <label for="ipAddress" className="text-gray-base">
              IP Address:
            </label>
          </td>
          <td>
            <input
              id="ipAddress"
              type="text"
              placeholder="192.168.0.1"
              className="text-sm text-gray-base w-full p-2 border border-gray-primary rounded disabled:bg-gray-200"
              value={IPAddress}
              onChange={(evt) => {
                setIPAddress(evt.target.value);
              }}
              onBlur={() => {
                if (IPAddress === editNode.data.IP) {
                  return;
                }
                setRecomputeMapWhenUpdate(true);
                setEditNode({
                  ...editNode,
                  data: {
                    ...editNode.data,
                    IP: IPAddress,
                  },
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label for="netmask" className="text-gray-base">
              Netmask:
            </label>
          </td>
          <td>
            <input
              id="netmask"
              type="text"
              placeholder="Automatically computed when connected"
              className="text-sm text-gray-base w-full p-2 border border-gray-primary rounded disabled:bg-gray-200"
              value={netmask}
              onChange={(evt) => {
                setNetmask(evt.target.value);
              }}
              onBlur={() => {
                if (netmask === editNode.data.NetMask) {
                  return;
                }
                setRecomputeMapWhenUpdate(true);
                setEditNode({
                  ...editNode,
                  data: {
                    ...editNode.data,
                    NetMask: netmask,
                  },
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label for="gateway" className="text-gray-base">
              Gateway:
            </label>
          </td>
          <td>
            <input
              id="gateway"
              type="text"
              placeholder="Automatically computed when connected"
              className="text-sm text-gray-base w-full p-2 border border-gray-primary rounded disabled:bg-gray-200"
              value={gateway}
              onChange={(evt) => {
                setGateway(evt.target.value);
              }}
              onBlur={() => {
                if (gateway === editNode.data.Gateway) {
                  return;
                }
                setRecomputeMapWhenUpdate(true);
                setEditNode({
                  ...editNode,
                  data: {
                    ...editNode.data,
                    Gateway: gateway,
                  },
                });
              }}
            />
          </td>
        </tr>
      </table>
    </fieldset>
  );
}
