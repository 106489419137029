import React, { useState } from "react";
import * as Modal from "../Modal/modal";

import ConfirmDialog from "../misc/confirmDialog";

export default function NoteNodeOverlay({
  editNode,
  setEditNode,
  onCloseCallback,
  deleteNode,
}) {
  const [label, setLabel] = useState(editNode.data.Label);

  return (
    <Modal.Frame
      onClose={() => {
        onCloseCallback();
      }}
    >
      <Modal.Head>Sticky note</Modal.Head>
      <Modal.Body>
        <div className="flex flex-col text-gray-base w-full h-full">
          <div className="grow flex flex-col text-gray-base w-full">
            {" "}
            <textarea
              className="whitespace-pre-line bg-white grow my-1"
              value={label}
              onChange={(evt) => {
                setLabel(evt.target.value);
              }}
              onBlur={() => {
                if (label === editNode.data.Label) return;

                setEditNode({
                  ...editNode,
                  data: {
                    ...editNode.data,
                    Label: label,
                  },
                });
              }}
            />
          </div>
          <div className="flex flex-row justify-end items-center pt-2">
            <button
              className="mt-2 bg-red-primary text-optistream-txt-white rounded h-8 font-bold text-sm w-24 disabled:opacity-50"
              onClick={() =>
                ConfirmDialog(
                  "You are about to definitely delete this note?",
                  () => {
                    deleteNode(editNode);
                    onCloseCallback();
                  }
                )
              }
            >
              Delete
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal.Frame>
  );
}
