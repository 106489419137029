import React, { memo } from "react";
import {
  Handle,
  Position,
  useStore,
  getConnectedEdges,
  useReactFlow,
} from "reactflow";

import { Sticky } from "react-bootstrap-icons";

function NoteNode({ id, data }) {
  const reactFlowInstance = useReactFlow();

  return (
    <div
      className="px-4 py-2 w-30 h-22 
        shadow-md rounded-md bg-white border border-stone-400"
      style={{
        borderStyle: "solid",
        backgroundColor: "#FFF9C4",
      }}
    >
      <div className="flex flex-col items-center align-middle">
        <div className="h-2/3 flex flex-col align-middle items-start">
          <div className="mx-2 my-2">
            <div className="text-sm font-bold text-optistream-txt-blue whitespace-pre-wrap">
              {data.Label}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(NoteNode);
