import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import Spinner from "./components/misc/spinner";
import { useAPI, DateUtils } from "../utils";
import { useAlert } from "react-alert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Command } from "react-bootstrap-icons";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ConfirmDialog from "./components/misc/confirmDialog";

export default function Home() {
  const [newMapName, setNewMapName] = useState("");
  const [activeMaps, setActiveMaps] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displaySnapshotForMapId, setDisplaySnapshotForMapId] = useState(-1);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileWasUploaded, setFileWasUploaded] = useState(false);
  const [latestSnapshots, setLatestSnapshots] = useState({});

  const navigate = useNavigate();

  const isInvalid = newMapName === "";
  const alert = useAlert();
  const {
    listWorkspaces,
    getLatestSnapshot,
    createWorkspace,
    importWorkspaceFile,
    deleteWorkspace,
    cloneWorkspace,
  } = useAPI();

  useEffect(() => {
    if (activeMaps !== null) setLoading(false);
  }, [activeMaps]);

  useEffect(() => {
    listWorkspaces()
      .then(setActiveMaps)
      .catch((e) => void e);
  }, []);

  const createNewMap = () => {
    createWorkspace(newMapName)
      .then((workspace) => {
        navigate(`${ROUTES.MAP}/${workspace.id}`);
      })
      .catch((e) => void e);
  };

  const loadMap = (mapId, snapshotId = null) => {
    if (snapshotId) navigate(`${ROUTES.MAP}/${mapId}?ss=${snapshotId}`);
    else navigate(`${ROUTES.MAP}/${mapId}`);
  };

  const handleFileUploadChanged = (e) => {
    if (e.target.files) {
      setFileToUpload(e.target.files[0]);
    }
  };

  const uploadFile = () => {
    if (!fileToUpload) {
      return;
    }
    importWorkspaceFile(fileToUpload)
      .then(() => {
        setFileToUpload(null);
        setFileWasUploaded(true);
        alert.success("Workspace successfully imported!");
      })
      .catch((e) => void e);
  };

  useEffect(() => {
    if (fileWasUploaded) {
      listWorkspaces()
        .then(setActiveMaps)
        .catch((e) => void e);
      setFileWasUploaded(false);
    }
  }, [fileWasUploaded]);
  useEffect(() => {
    if (activeMaps) {
      const fetchLatestSnapshots = async () => {
        const snapshotPromises = activeMaps.map((activeMap) =>
          getLatestSnapshot(activeMap.id).then((snapshot) => [
            activeMap.id,
            snapshot,
          ])
        );

        const latestSnapshots = {};
        try {
          const snapshotResults = await Promise.all(snapshotPromises);
          snapshotResults.forEach(([id, snapshot]) => {
            latestSnapshots[id] = snapshot;
          });
        } catch (error) {
          console.error("Error fetching latest snapshot", error);
        }

        setLatestSnapshots(latestSnapshots);
      };

      fetchLatestSnapshots();
    }
  }, [activeMaps]);

  //             <div className="flex flex-col h-full ml-4 mr-16 w-4/5 justify-center items-center bg-white">
  //               <img
  //                 src="/images/map.jpg"
  //                 className="-skew-y-6 h-1/2 shadow-lg shadow-gray-500 rounded"
  //                 alt="map-image"
  //               />
  //               <div className="mt-24 text-optistream-txt-blue text-lg font-bold">
  //                 Complete your first workspace, run an audit and know you weak
  //                 spots
  //               </div>
  //             </div>

  const handleDeleteWorkspace = async (workspaceId) => {
    try {
      await deleteWorkspace(workspaceId);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  const handleCloneWorkspace = async (workspaceId) => {
    try {
      await cloneWorkspace(workspaceId);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-background w-full p-12 justify-center">
      <div className="flex flex-col h-full w-full justify-start items-start bg-white p-8">
        <div className="w-full flex">
          <div className="w-1/2 flex flex-col">
            <div className="flex text-blue-optistream font-bold text-base uppercase mb-4">
              Create a new workspace:
            </div>
            <div className="flex flex-row justify-start items-center">
              <input
                aria-label="Enter your new map's name"
                type="text"
                placeholder="Enter your new map's name"
                className="text-sm text-gray-base w-60 py-2 px-4 h-10 border border-gray-primary rounded"
                onChange={({ target }) => setNewMapName(target.value)}
                value={newMapName}
              />
              <button
                disabled={isInvalid}
                onClick={createNewMap}
                className={`bg-optistream-btn-default active:bg-optistream-btn-clicked ml-4 text-optistream-txt-white h-8 text-sm w-36  rounded font-bold
        ${isInvalid && "opacity-50"}`}
              >
                Create workspace
              </button>
            </div>
          </div>
          <div className="w-1/2 flex flex-col">
            <div className="flex text-blue-optistream font-bold text-base uppercase mb-4">
              Upload a workspace (.optistream):
            </div>
            <div className="flex flex-row justify-start items-center">
              <label
                htmlFor="dropzone-file"
                className="flex flew-col w-60 text-gray-base 
                                                           inline-block align-middle items-center text-center justify-center
                                                           cursor-pointer bg-white border border-dashed rounded 
                                                           border-gray-primary hover:bg-gray-100  h-10"
              >
                <div className="text-sm text-gray-500 align-middle">
                  {fileToUpload ? fileToUpload.name : "Click to select a file"}
                </div>

                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  accept=".optistream"
                  onChange={handleFileUploadChanged}
                />
              </label>
              <button
                disabled={!fileToUpload}
                onClick={uploadFile}
                className={`bg-optistream-blue active:bg-optistream-blueClicked ml-4 text-optistream-txt-white h-8 text-sm w-36  rounded font-bold
        ${!fileToUpload && "opacity-50"}`}
              >
                Upload workspace
              </button>
            </div>
          </div>
        </div>
        {activeMaps !== null && activeMaps.length ? (
          <>
            <div className="flex text-blue-optistream font-bold text-base uppercase mt-6 mb-2 w-full text-left">
              My workspaces:
            </div>
            <div className="w-full overflow-auto pt-6 flex flex-col items-center">
              {activeMaps.length && (
                <div
                  className="flex flex-row justify-center bg-optistream-orange text-optistream-txt-white                     
                            items-center mb-2 border-b-2 border-optistream-orange rounded-lg"
                >
                  <div className="w-72 py-4 px-2 text-sm font-bold ">
                    Workspace
                  </div>

                  <div className="w-44 px-2 text-sm font-bold ">
                    Last Update
                  </div>
                  <div className="px-2 w-44 text-sm font-bold ">
                    Creation Date
                  </div>
                  <div className="w-20 text-sm font-bold text-center">Load</div>
                  <div className="w-20 text-sm font-bold text-center">
                    Duplicate
                  </div>
                  <div className="w-20 text-sm font-bold text-center">
                    Delete
                  </div>
                </div>
              )}
              <div>
                {activeMaps
                  .sort((map1, map2) =>
                    DateUtils.compareDates(
                      new Date(latestSnapshots[map1.id]?.updated_at),
                      new Date(latestSnapshots[map2.id]?.updated_at)
                    )
                  )
                  .map((activeMapRef, index) => (
                    <div
                      key={index}
                      className="flex flex-row justify-center
                      hover:bg-optistream-grayHighlight
                      hover:shadow-xl 
                      items-center mb-2 border-b-2 border-optistream-orange"
                    >
                      <div
                        className={`w-72 h-16 rounded-lg 
                                    flex flex-col items-center 
                                    
                                    font-normal justify-evenly ${
                                      displaySnapshotForMapId ===
                                        activeMapRef.id &&
                                      "bg-blue-faded-medium"
                                    }`}
                      >
                        <div
                          className="cursor-pointer h-full w-full flex flex-col items-start justify-evenly font-normal hover:font-bold"
                          onClick={() => loadMap(activeMapRef.id)}
                        >
                          <div className="text-base px-2 py-1 text-start text-blue-optistream">
                            {activeMapRef.name}
                          </div>
                        </div>
                      </div>

                      <div className="font-normal text-xs px-2 w-44">
                        <div className="font-normal text-xs px-2 w-44">
                          {latestSnapshots[activeMapRef.id]
                            ? DateUtils.formatDate(
                                latestSnapshots[activeMapRef.id].updated_at
                              )
                            : "Loading..."}
                        </div>
                      </div>
                      <div className="font-normal text-xs px-2 w-44">
                        {DateUtils.formatDate(activeMapRef.created_at)}
                      </div>
                      <div className="w-20 flex justify-center">
                        <button
                          className="h-8 w-8 justify-center items-center flex  border rounded-full text-optistream-txt-white
               bg-optistream-btn-default border  cursor-pointer hover:shadow-lg active:bg-optistream-btn-clicked"
                          onClick={() => loadMap(activeMapRef.id)}
                        >
                          <CloudUploadIcon className="scale-75" />
                        </button>
                      </div>
                      <div className="w-20 flex justify-center">
                        <button
                          className="h-8 w-8 justify-center items-center flex  border rounded-full text-optistream-txt-white
               bg-optistream-btn-alternative border  cursor-pointer hover:shadow-lg active:bg-optistream-btn-altClicked"
                          onClick={() => {
                            ConfirmDialog(
                              "Do you want to duplicate your workspace ?",
                              () => handleCloneWorkspace(activeMapRef.id)
                            );
                          }}
                        >
                          <ContentCopyIcon className="scale-75" />
                        </button>
                      </div>
                      <div className="w-20 flex justify-center">
                        <div
                          className="h-8 w-8 justify-center items-center flex  border rounded-full text-optistream-txt-white
               bg-red-primary border cursor-pointer hover:shadow-lg active:bg-optistream-redClicked"
                          onClick={() => {
                            ConfirmDialog(
                              "Do you want to delete your workspace ?",
                              () => handleDeleteWorkspace(activeMapRef.id)
                            );
                          }}
                        >
                          <DeleteForeverIcon className="scale-75" />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </>
        ) : activeMaps !== null && !activeMaps.length ? (
          <div className="flex flex-col h-full w-full bg-white p-4 shrink-0 grow-0 items-center">
            <div className="flex flex-col justify-center items-center text-left mb-7 mx-2 h-full w-full">
              <div className="w-full h-full flex justify-center items-center flex-col">
                <div className="text-lg text-optistream-txt-blue mb-8 font-bold">
                  Create your first workspace{" "}
                </div>
                <img className="w-96" src="/images/no_workspace.jpeg" />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full w-full bg-white p-4 shrink-0 grow-0 items-center">
            <div className="flex flex-col justify-center items-center h-full w-full text-left mb-7 mx-2">
              <div className="flex text-optistream-txt-blue font-bold text-lg uppercase mt-2 mb-4">
                Retrieving workspaces
              </div>
              <Spinner size="16" />
            </div>
          </div>
        )}{" "}
      </div>
    </div>
  );
}
