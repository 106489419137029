import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { useAlert } from "react-alert";

export default function WorkspaceTabs({ workspaceId }) {
  const [page, setPage] = useState("");
  const [wsId, setWsId] = useState("");

  useEffect(() => {
    const path = window.location.pathname;
    const rx = /(\/[a-zA-Z]+)\/(\d+)/g;
    const split = rx.exec(path);
    setPage(split[1]);
    setWsId(split[2]);
  }, []);

  return (
    <div className="border-b-2 border-optistream-orange h-12 w-full flex">
      <Link to={ROUTES.MAP + "/" + workspaceId}>
        <div
          className={`w-40 h-full bg-white rounded-t-2xl items-center justify-center flex
                      text-sm
                      ${
                        ROUTES.MAP === page
                          ? "border-t-2 border-x-2 border-optistream-orange text-optistream-txt-blue font-bold"
                          : "border-t border-x border-optistream-gray"
                      }`}
        >
          MAP
        </div>
      </Link>

      {/* <Link to={"#"}>
        <div
          className={`w-40 h-full bg-white rounded-t-2xl text-center items-center justify-center flex 
                      text-xs italic text-optistream-grayBase
                      ${
                        null === page
                          ? "border-t-2 border-x-2 border-optistream-orange text-optistream-txt-blue font-bold"
                          : "border-t border-x border-optistream-gray"
                      }`}
        >
          DIRECT INTERNET
          <br />
          EXPOSURE
        </div>
      </Link> */}
      <Link to={`${ROUTES.RISKY_ROUTES}/${workspaceId}`}>
        <div
          className={`w-40 h-full bg-white rounded-t-2xl items-center text-center justify-center flex 
                      text-xs
                      ${
                        ROUTES.RISKY_ROUTES === page ||
                        ROUTES.RISKY_ROUTES_DETAILS === page
                          ? "border-t-2 border-x-2 border-optistream-orange text-optistream-txt-blue font-bold"
                          : "border-t border-x border-optistream-gray"
                      }`}
        >
          HIGH RISK <br />
          ROUTES
        </div>
      </Link>
      <Link to={`${ROUTES.INFILTRATION}/${workspaceId}`}>
        <div
          className={`w-40 h-full bg-white rounded-t-2xl items-center text-center justify-center flex 
                      text-xs
                      ${
                        ROUTES.INFILTRATION === page ||
                        ROUTES.INFILTRATION_DETAILS === page
                          ? "border-t-2 border-x-2 border-optistream-orange text-optistream-txt-blue font-bold"
                          : "border-t border-x border-optistream-gray"
                      }`}
        >
          LATERALIZATION <br />
          POROSITY
        </div>
      </Link>

      {/* <Link to={"#"}>
        <div
          className={`w-40 h-full bg-white rounded-t-2xl text-center items-center justify-center flex text-sm 
                      text-xs italic text-optistream-grayBase
                      ${
                        null === page
                          ? "border-t-2 border-x-2 border-optistream-orange bg-optistream-blue"
                          : "border-t border-x border-optistream-gray"
                      }`}
        >
          CROSS-ZONE <br />
          REACHABILITY
        </div>
      </Link> */}
      <Link to={"#"}>
        <div
          className={`w-40 h-full bg-white rounded-t-2xl text-center items-center justify-center flex text-sm 
                      text-xs italic text-optistream-grayBase
                      ${
                        null === page
                          ? "border-t-2 border-x-2 border-optistream-orange bg-optistream-blue"
                          : "border-t border-x border-optistream-gray"
                      }`}
        >
          DIRECT DATA
          <br />
          EXFILTRATION
        </div>
      </Link>
      <Link to={"#"}>
        <div
          className={`w-40 h-full bg-white rounded-t-2xl text-center items-center justify-center flex text-sm 
                      text-xs italic text-optistream-grayBase
                      ${
                        null === page
                          ? "border-t-2 border-x-2 border-optistream-orange bg-optistream-blue"
                          : "border-t border-x border-optistream-gray"
                      }`}
        >
          SECURITY POLICY
          <br />
          COMPLIANCE
        </div>
      </Link>
    </div>
  );
}
