export const securityLevelIsCritical = (securityLevel) => {
  switch (securityLevel) {
    case "no_access":
      return false;
    case "application_protos_allowed":
      return false;
    case "admin_protos_allowed":
      return true;
    case "full_access":
      return true;
  }
};

export const securityLevelToLabel = (securityLevel) => {
  switch (securityLevel) {
    case "no_access":
      return "No opened access";
    case "application_protos_allowed":
      return "Applications protocols opened";
    case "admin_protos_allowed":
      return "Admin protocols opened";
    case "full_access":
      return "No control performed";
  }
};

export const securityLevelToRate = (securityLevel) => {
  switch (securityLevel) {
    case "no_access":
      return 3;
    case "application_protos_allowed":
      return 2;
    case "admin_protos_allowed":
      return 1;
    case "full_access":
      return 0;
  }
};

export const computeKPI = (analysis) => {
  const nbSecuredRoutes = analysis.result.result.filter((route) => {
    return !securityLevelIsCritical(route.security_level);
  }).length;

  const nbRoutes = analysis.result.result.length;

  return Math.round((nbSecuredRoutes / nbRoutes) * 100);
};
