import React, { useState, useEffect, useMemo } from "react";
import { useAPI, DateUtils } from "../utils";
import { useParams, Link } from "react-router-dom";

import { analysisTypes } from "./components/misc/utils";
import { analysisStatus } from "./components/misc/utils";
import ClipLoader from "react-spinners/ClipLoader";

import Modal from "react-modal";

export default function AnalysisHistory() {
  const [analyses, setAnalyses] = useState([]);
  const [workspaceFilter, setWorkspaceFilter] = useState("All Workspaces");
  const [typeFilter, setTypeFilter] = useState("All Types");
  const [statusFilter, setStatusFilter] = useState("All Status");

  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const { workspaceId } = useParams();

  const { listAnalyses } = useAPI();

  useEffect(() => {
    listAnalyses({
      workspace_id: workspaceId,
    })
      .then(setAnalyses)
      .catch((e) => void e)
      .finally(() => setLoading(false));
  }, []);

  const handleWorkspaceFilterChange = (event) => {
    setWorkspaceFilter(event.target.value);
  };

  const handleTypeFilterChange = (event) => {
    setTypeFilter(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const workspaceOptions = useMemo(() => {
    const options = analyses.reduce(
      (acc, analysis) => {
        if (!acc.includes(analysis.snapshot.workspace.name)) {
          acc.push(analysis.snapshot.workspace.name);
        }
        return acc;
      },
      ["All Workspaces"]
    );
    //options.unshift();
    return options;
  }, [analyses]);

  const typeOptions = useMemo(() => {
    return ["All Types", ...analysisTypes.map(({ value, label }) => label)];
  }, []);

  const statusOptions = useMemo(() => {
    return ["All Status", ...analysisStatus.map(({ value, label }) => label)];
  }, []);

  const filteredAnalyses = useMemo(() => {
    const selectedStatusValue =
      statusFilter === "All Status"
        ? null
        : analysisStatus.find(({ label }) => label === statusFilter)?.value;

    return analyses.filter(
      (analysis) =>
        (workspaceFilter === "All Workspaces" ||
          analysis.snapshot.workspace.name === workspaceFilter) &&
        (typeFilter === "All Types" ||
          analysis.type ===
            analysisTypes.find((a) => a.label === typeFilter).value) &&
        (!selectedStatusValue || analysis.status === selectedStatusValue)
    );
  }, [analyses, workspaceFilter, typeFilter, statusFilter]);

  const openErrorModal = (errorMessage) => {
    setErrorMessage(errorMessage);
    setErrorModalIsOpen(true);
  };

  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };
  const customStyles = {
    overlay: {
      backgroundColor: "transparent",
      zIndex: 1000,
      backdropFilter: "blur(5px)",
      WebkitBackdropFilter: "blur(5px)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "4px",
      width: "auto",
      maxHeight: "80vh",
      overflow: "auto",
    },
  };

  return (
    <div className="flex flex-col h-screen bg-gray-background w-full p-12 justify-center">
      <div className="flex flex-col h-full w-full justify-start items-start bg-white p-4">
        <div className="flex p-2 text-optistream-txt-blue font-bold text-base uppercase ">
          Analysis history
        </div>
        <p className="py-4 px-2 text-base text-optistream-txt-default">
          All your analyses consolidated in one spot. Retrieve the info you want
          in one click.
        </p>

        {loading ? (
          <div className="w-full h-full flex justify-center items-center flex-col">
            <ClipLoader color={"#027F9C"} loading={true} size={80} />
          </div>
        ) : analyses.length !== 0 ? (
          <div className="flex w-full justify-center overflow-auto pt-6">
            <table className="border-collapse border-b border-optistream-orange">
              <thead>
                <tr className="border-y border-optistream-orange">
                  <th className="w-72 py-2 pr-4">
                    <select
                      id="workspaceFilter"
                      value={workspaceFilter}
                      onChange={handleWorkspaceFilterChange}
                      className="border border-optistream-gray 
                                text-optistream-txt-blue text-base font-normal
                                rounded-lg focus:ring-optistream-blue focus:border-optistream-blue block w-full p-2.5"
                    >
                      {workspaceOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th className="w-60 pr-4">
                    <select
                      id="typeFilter"
                      placeholder="Analysis"
                      value={typeFilter}
                      onChange={handleTypeFilterChange}
                      className="border border-optistream-gray 
                                text-optistream-txt-blue text-base font-normal
                                rounded-lg focus:ring-optistream-blue focus:border-optistream-blue block w-full p-2.5"
                    >
                      {typeOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th className="w-44 pr-4">
                    <select
                      id="statusFilter"
                      value={statusFilter}
                      onChange={handleStatusFilterChange}
                      className="border border-optistream-gray 
                                text-optistream-txt-blue text-base font-normal
                                rounded-lg focus:ring-optistream-blue focus:border-optistream-blue block w-full p-2.5"
                    >
                      {statusOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th className="w-48 pr-4">
                    <div className="text-optistream-txt-blue text-base font-normal w-full pl-4">
                      Analysis completed at
                    </div>
                  </th>
                  <th className="w-42 pr-4 ">
                    <div className="text-optistream-txt-blue text-base font-normal pl-4">
                      Analysis Results
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredAnalyses.map((analysis, index) => (
                  <tr
                    key={index}
                    className="hover:bg-optistream-grayHighlight text-sm text-optistream-txt-default"
                  >
                    <td className="p-4">
                      {analysis.snapshot && analysis.snapshot.workspace
                        ? analysis.snapshot.workspace.name
                        : ""}
                    </td>
                    <td className="p-4 text-center">
                      {analysis.type &&
                      analysisTypes.find((a) => a.value === analysis.type)
                        ? analysisTypes.find((a) => a.value === analysis.type)
                            .label
                        : ""}
                    </td>

                    <td className="p-4 text-center">{analysis.status}</td>
                    <td className="p-4 text-center">
                      {["READY", "ERROR"].includes(analysis.status)
                        ? DateUtils.formatDate(analysis.updated_at)
                        : null}
                    </td>
                    <td className="p-4 text-center">
                      {analysis.error_message ? (
                        <>
                          <button
                            onClick={() =>
                              openErrorModal(analysis.error_message)
                            }
                            className="bg-optistream-red active:bg-optistream-redClicked 
                            rounded-lg text-optistream-txt-white h-8 w-24 px-2"
                          >
                            View Error
                          </button>
                          <Modal
                            isOpen={errorModalIsOpen}
                            onRequestClose={closeErrorModal}
                            contentLabel="Error Modal"
                            style={customStyles}
                          >
                            <div className="p-4">
                              <h2 className="text-lg font-bold mb-2">Error</h2>
                              <p>{errorMessage}</p>
                              <button
                                onClick={closeErrorModal}
                                style={{
                                  backgroundColor: "#027F9C",
                                  color: "#fff",
                                  padding: "4px 8px",
                                  borderRadius: "4px",
                                  textDecoration: "none",
                                  marginRight: "10px",
                                }}
                              >
                                Close
                              </button>
                            </div>
                          </Modal>
                        </>
                      ) : (
                        <Link
                          to={`${
                            analysisTypes.find((a) => a.value === analysis.type)
                              .page
                          }/${analysis.snapshot.workspace_id}?id=${
                            analysis.id
                          }`}
                        >
                          <button
                            className="h-8 w-16 bg-optistream-btn-default active:bg-optistream-btn-clicked 
                                      text-optistream-txt-white px-2 rounded-lg
                                      disabled:opacity-50"
                            disabled={analysis.status !== "READY"}
                          >
                            Go
                          </button>
                        </Link>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="w-full h-full flex justify-center items-center flex-col">
            <div className="text-lg text-optistream-txt-blue mb-8 font-bold">
              No analysis has been performed{" "}
            </div>
            <img className="w-96" src="/images/no_analysis.png" />
          </div>
        )}
      </div>
    </div>
  );
}
