import React, { useRef, useState, setState, useEffect } from "react";
import Modal from "react-modal";
import { InfoCircle, ArrowRight, XCircle } from "react-bootstrap-icons";
import * as d3 from "d3";

export default function DetailE2EGraph({ analysis }) {
  // HACK: avoid scroll glitches on (i) button within right-side links table
  document.body.style.overflow = "hidden";

  const ref = useRef();
  const displayWindow = useRef();
  const [showFilteredLinks, setShowFilteredLinks] = useState(false);
  const [showIPLink, setShowIPLink] = useState(false);
  const [colorizeSubnets, setColorizeSubnets] = useState(false);
  const [hideIntraSubnets, setHideIntraSubnets] = useState(false);
  const [nodeIP, setNodeIP] = useState("");
  const [nodeName, setNodeName] = useState("");
  const [nodes, setNodes] = useState();
  const [inLinks, setInLinks] = useState();
  const [outLinks, setOutLinks] = useState();
  const [currentLink, setCurrentLink] = useState();
  const [graph, setGraph] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isTransiting, setIsTransiting] = useState(false);
  const [isHiddenIntraSubnetsTransiting, setIsHiddenIntraSubnetsTransiting] =
    useState(false);
  const [isColorizeTransiting, setIsColorizeTransiting] = useState(false);
  const [showNodeDetails, setShowNodeDetails] = useState(false);

  function openModal(link) {
    setCurrentLink(link);
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const showFilteredLinksOnClick = () => {
    setShowFilteredLinks(!showFilteredLinks);
  };

  const showIPLinkOnClick = () => {
    setShowIPLink(!showIPLink);
  };

  function compute_node_color2(d) {
    if (graph) {
      var color = d3
        .scaleLinear()
        .domain(d3.extent(graph.nodes.map((d) => d.betweeness)))
        .range(["#027F9C", "red"]);

      return color(d.betweeness);
    }
  }

  useEffect(() => {
    if (colorizeSubnets) {
      var color = d3.scaleOrdinal(d3.schemeCategory10);
      d3.selectAll("g circle")
        .transition()
        .on("start", () => setIsColorizeTransiting(true))
        .on("end", () => setIsColorizeTransiting(false))
        .duration(750)
        .style("fill", (d) => color(d.subnet));
    } else {
      d3.selectAll("g circle")
        .transition()
        .on("start", () => setIsColorizeTransiting(true))
        .on("end", () => setIsColorizeTransiting(false))
        .duration(750)
        .style("fill", compute_node_color2);
    }
  }, [colorizeSubnets]);

  const colorizeSubnetsOnClick = (evt) => {
    if (isColorizeTransiting) return;
    setColorizeSubnets(!colorizeSubnets);
  };

  useEffect(() => {
    if (graph)
      graph.links.forEach((link) => {
        if (link.source.subnet == link.target.subnet) {
          d3.select(
            "g line[source='" +
              link.source.id +
              "'][target='" +
              link.target.id +
              "']"
          )
            .transition()
            .on("start", () => setIsHiddenIntraSubnetsTransiting(true))
            .duration(100)
            .style("stroke", "red")
            .transition()
            .on("end", () => setIsHiddenIntraSubnetsTransiting(false))
            .duration(1000)
            .style("stroke", hideIntraSubnets ? "white" : "grey")
            .attr("stroke-width", 1);
        }
      });

    // HACK: redefine callbacks, if not, `hideIntraSubnets` is still at the same value
    d3.selectAll("g circle").on("mouseover", node_or_label_hover);
    d3.selectAll("g circle").on("mouseout", node_or_label_mouseout);
    d3.selectAll("g text").on("mouseover", node_or_label_hover);
    d3.selectAll("g text").on("mouseout", node_or_label_mouseout);
  }, [hideIntraSubnets]);

  const hideIntraSubnetsOnClick = (evt) => {
    if (isHiddenIntraSubnetsTransiting) return;
    setHideIntraSubnets(!hideIntraSubnets);
  };

  function compute_link_color(d) {
    if (!showFilteredLinks || d.weight == 1) {
      return "grey";
    }

    /* Normalization between (1, 10) */
    var domain = [1, 10];
    var color = d3
      .scaleLinear(d3.extent(graph.nodes.map((d) => d.weight)))
      .domain(domain)
      .range(["green", "red"]);

    var min_weight = Math.min(...graph.links.map((d) => d.weight));
    var max_weight = Math.max(...graph.links.map((d) => d.weight));

    var norm_weight =
      domain[0] +
      ((d.weight - min_weight) * (domain[1] - domain[0])) /
        (max_weight - min_weight);
    return color(norm_weight);
  }

  const node_or_label_hover = (event, d) => {
    var linked_nodes = [d];
    var in_links = [];
    var out_links = [];

    // Make label bold
    d3.select("g[id='" + d.id + "'] text")
      .style("font-size", "0.75em")
      .attr("font-weight", 700);

    d3.selectAll("g line").style("stroke-width", function (l) {
      if (d === l.source) {
        linked_nodes.push(l.target);
        out_links.push(l);
        return 3;
      } else if (d === l.target) {
        linked_nodes.push(l.source);
        in_links.push(l);
        return 3;
      }

      return 0.5;
    });

    d3.selectAll("g line").style("stroke", function (l) {
      if (hideIntraSubnets && l.source.subnet == l.target.subnet) return;
      var in_link = in_links.filter(
        (il) => il.source == l.source && il.target == l.target
      );

      var out_link = out_links.filter(
        (ol) => ol.source == l.target && ol.target == l.source
      );

      if (in_link[0] && out_link[0]) {
        return "brown";
      } else if (l.source == d) {
        return "green";
      } else if (l.target == d) {
        return "red";
      }

      return compute_link_color(l);
    });

    d3.selectAll("g cirle").style("opacity", function (n) {
      if (!linked_nodes.includes(n)) {
        return 0.2;
      }

      return 1;
    });

    d3.select("g[id='" + d.id + "'] circle")
      .transition()
      .duration(250)
      .attr("r", (d) => compute_node_radius(d) * 1.25);
  };

  function node_or_label_mouseout(event, d) {
    d3.select("g[id='" + d.id + "'] text")
      .style("font-size", "0.5em")
      .attr("font-weight", 600);

    d3.selectAll("g circle").style("opacity", 1);
    d3.selectAll("g line").style("stroke-width", 1);
    d3.selectAll("g line").style("stroke", function (l) {
      if (hideIntraSubnets && l.source.subnet == l.target.subnet) return;
      return compute_link_color(l);
    });

    d3.select("g[id='" + d.id + "'] circle")
      .transition()
      .duration(250)
      .attr("r", compute_node_radius);
  }

  useEffect(() => {
    const margin = { top: 10, right: 10, bottom: 10, left: 10 },
      width = displayWindow.current.clientWidth - margin.left - margin.right,
      height =
        displayWindow.current.clientHeight * 0.85 - margin.top - margin.bottom;

    // HACK: we redefine another `compute_node_color` that doesn't check for `graph` initialization
    function compute_node_color(d) {
      var color = d3
        .scaleLinear()
        .domain(d3.extent(graph.nodes.map((d) => d.betweeness)))
        .range(["#027F9C", "red"]);

      return color(d.betweeness);
    }

    var zoom = d3.zoom().on("zoom", zoomed);

    function zoomed(e) {
      d3.select("svg g").attr("transform", e.transform);
    }

    d3.select("svg g").remove();

    const svg = d3
      .select(ref.current)
      .call(zoom)
      .on("dblclick.zoom", null)
      .attr("width", displayWindow.current.clientWidth * 1)
      .attr("height", displayWindow.current.clientHeight * 1)
      .style("background", "white")
      .append("g");

    const e2egraph = analysis.result.e2egraph;
    var nodes = [];
    e2egraph.nodes.forEach(([node, attrs]) => {
      nodes.push({
        id: node,
        ip: attrs.IP,
        subnet: attrs.Subnet,
        name: attrs.DisplayName,
        out_degree: attrs.OutDegree,
        betweeness: attrs.Betweeness1 + attrs.Betweeness2,
      });
    });

    setNodes(nodes);

    var links = [];
    e2egraph.edges.forEach(([source, target, attrs]) => {
      links.push({
        source: source,
        target: target,
        weight: attrs.weight,
        permit_ports: attrs.permit_ports,
        route: attrs.route_with_interfaces,
      });
    });

    var graph = { links: links, nodes: nodes };
    setGraph(graph);

    /* Black magic from here: https://gist.github.com/nitaku/7483341 */
    var _ref = graph.links;
    var _ref2;
    var _i, l, n, _len, _j, _len2;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      l = _ref[_i];
      _ref2 = graph.nodes;
      for (_j = 0, _len2 = _ref2.length; _j < _len2; _j++) {
        n = _ref2[_j];
        if (l.source === n.id) {
          l.source = n;
          continue;
        }
        if (l.target === n.id) {
          l.target = n;
          continue;
        }
      }
    }

    function compute_ip_distance(l) {
      var src_subnet = l.source.subnet.split("/")[0];
      var dst_subnet = l.target.subnet.split("/")[0];
      if (src_subnet == dst_subnet) {
        return 0;
      }
      return 50;
    }

    const simulation = d3
      .forceSimulation(graph.nodes)
      .force("charge", d3.forceManyBody().strength(-1000))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force(
        "link",
        d3.forceLink().links(graph.links).distance(compute_ip_distance)
      )
      .on("tick", ticked);

    function compute_link_opacity(d) {
      if (!showFilteredLinks || d.weight == 1) {
        // No filtering
        return 0.2;
      }

      return 0.6;
    }

    function compute_link_width(d) {
      if (!showFilteredLinks || d.weight == 1) {
        // No filtering
        return 1;
      }

      return 2;
    }

    const link = svg
      .selectAll("line")
      .data(graph.links)
      .join("line")
      .attr("source", (d) => d.source.id)
      .attr("target", (d) => d.target.id)
      .style("stroke", (d) => compute_link_color(d))
      .attr("stroke-opacity", (d) => compute_link_opacity(d))
      .attr("stroke-width", (d) => compute_link_width(d));

    function node_or_label_on_click(event, d) {
      if (event.defaultPrevented) return;

      var in_links = [];
      var out_links = [];
      links.map((l) => {
        if (d.id === l.source.id) {
          out_links.push(l);
        } else if (d.id === l.target.id) {
          in_links.push(l);
        }
      });

      setInLinks(in_links);
      setOutLinks(out_links);
      setNodeIP(d.ip);
      setNodeName(d.name);

      svg
        .selectAll(".selected")
        .style("stroke", "grey")
        .style("stroke-width", "1")
        .classed("selected", false);

      d3.select("g[id='" + d.id + "'] circle")
        .classed("selected", true)
        .transition()
        .duration(250)
        .style("stroke", "#ED7B23")
        .style("stroke-width", "3")
        .transition()
        .duration(250)
        .attr("r", compute_node_radius)
        .style("stroke", "#ED7B23");

      setShowNodeDetails(true);
    }

    var node = svg
      .selectAll("g")
      .data(graph.nodes)
      .join("g")
      .attr("id", (n) => n.id)
      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      );

    function compute_node_radius(d) {
      var scale = d3
        .scaleLinear()
        .domain(d3.extent(graph.nodes.map((d) => d.out_degree)))
        .range([10, 30]);

      return scale(d.out_degree);
    }

    node
      .append("circle")
      .attr("r", compute_node_radius)
      .style("fill", compute_node_color)
      .style("stroke", "grey")
      .style("stroke-width", "1")
      .style("cursor", "pointer")
      .on("click", node_or_label_on_click)
      .on("mouseover", node_or_label_hover)
      .on("mouseout", node_or_label_mouseout);

    node
      .append("text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .style("fill", "black")
      .style("cursor", "pointer")
      .style("font-size", "0.5em")
      .attr("font-weight", 600)
      .text((d) => d.name)
      .attr("x", function (d) {
        return d.x;
      })
      .attr("y", function (d) {
        return d.y;
      })
      .on("click", node_or_label_on_click)
      .on("mouseover", node_or_label_hover)
      .on("mouseout", node_or_label_mouseout);

    function ticked() {
      link
        .attr("x1", function (d) {
          return d.source.x;
        })
        .attr("y1", function (d) {
          return d.source.y;
        })
        .attr("x2", function (d) {
          return d.target.x;
        })
        .attr("y2", function (d) {
          return d.target.y;
        });

      node.attr("transform", (d) => `translate(${d.x + 6},${d.y - 6})`);
    }

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }
  }, [showFilteredLinks]);

  function linkOver(evt, link, enable) {
    if (link.source === undefined || link.target === undefined) {
      return;
    }

    d3.select(
      "g line[source='" + link.source.id + "'][target='" + link.target.id + "']"
    ).style("stroke-width", enable ? 10 : 1);

    d3.selectAll(".tr-link").style("font-weight", "normal");
    evt.target.parentNode.style = enable
      ? "font-weight: bold"
      : "font-weight: normal";
  }

  function compute_node_radius(d) {
    var nodes = d3.selectAll("g circle").data();
    // HACK: bug if we try to access `graph.nodes` here (undefined at start...)
    // we use instead a new selection
    var scale = d3
      .scaleLinear()
      .domain(d3.extent(nodes.map((d) => d.out_degree)))
      .range([10, 30]);

    return scale(d.out_degree);
  }

  function hotPointOver(evt, node, enable) {
    evt.target.parentNode.style =
      "cursor: pointer;" +
      (enable ? "font-weight: bold" : "font-weight: normal");
    //evt.target.parentNode.style = "border-left: " + (enable ? "3px solid" : "0px solid");
  }

  function hotPointClick(evt, node) {
    if (isTransiting) {
      return;
    }

    var in_links = [];
    var out_links = [];
    graph.links.map((l) => {
      if (node.id === l.source.id) {
        out_links.push(l);
      } else if (node.id === l.target.id) {
        in_links.push(l);
      }
    });

    setInLinks(in_links);
    setOutLinks(out_links);
    setNodeIP(node.ip);
    setNodeName(node.name);

    d3.selectAll(".selected")
      .style("stroke", "grey")
      .style("stroke-width", "1")
      .classed("selected", false);

    d3.select("g[id='" + node.id + "'] circle")
      .classed("selected", true)
      .transition()
      .on("start", () => setIsTransiting(true))
      .duration(250)
      .attr("r", (d) => compute_node_radius(d) * 1.25)
      .style("stroke", "#ED7B23")
      .style("stroke-width", "3")
      .transition()
      .on("end", () => setIsTransiting(false))
      .duration(250)
      .attr("r", compute_node_radius)
      .style("stroke", "#ED7B23");

    setShowNodeDetails(true);
  }

  function get_hotpoint_score(nodes, n) {
    return (
      (n.betweeness / Math.max(...nodes.map((n) => n.betweeness))) * 100 + "%"
    );
  }

  function sort_link_by_ip(a, b, is_in) {
    var ip_a = undefined;
    var ip_b = undefined;
    if (is_in) {
      ip_a = a.source.ip;
      ip_b = b.source.ip;
    } else {
      ip_a = a.target.ip;
      ip_b = b.target.ip;
    }

    ip_a = Number(
      ip_a
        .split("/")[0]
        .split(".")
        .map((num) => `000${num}`.slice(-3))
        .join("")
    );
    ip_b = Number(
      ip_b
        .split("/")[0]
        .split(".")
        .map((num) => `000${num}`.slice(-3))
        .join("")
    );
    return ip_a - ip_b;
  }

  let subtitle;
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div className="flex w-full h-full">
      {currentLink ? (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
          <div className="text-right">
            <button onClick={closeModal}>
              <XCircle className="text-optistream-blue text-2xl hover:cursor-pointer" />
            </button>
          </div>
          <table className="my-2">
            <tr>
              <td>
                <div className="mb-1 mr-1 rounded text-optistream-txt-white px-2 py-0.5 w-fit h-fit bg-routePopup-src font-bold">
                  {currentLink.source.name}
                </div>
              </td>
              <td>
                <ArrowRight size="1.5em" />
              </td>
              <td>
                <div className="mb-1 mr-1 rounded text-optistream-txt-white px-2 py-0.5 w-fit h-fit bg-routePopup-dst font-bold">
                  {currentLink.target.name}
                </div>
              </td>
            </tr>
          </table>
          <table>
            <thead>
              <th className="bg-blue-100 border border-gray-300 px-4 py-2 text-center">
                Node
              </th>
              <th className="bg-blue-100 border border-gray-300 px-4 py-2 text-center">
                Interface IN
              </th>
              <th className="bg-blue-100 border border-gray-300 px-4 py-2 text-center">
                Interface OUT
              </th>
            </thead>
            <tr>
              <td className="border border-gray-300 px-4 py-2 font-mono">
                {currentLink.source.name}
              </td>
              <td className="border border-gray-300 px-4 py-2 font-mono bg-gray-200"></td>
              <td className="border border-gray-300 px-4 py-2 font-mono text-center">
                {currentLink.route[0][2]}
              </td>
            </tr>
            {currentLink.route.slice(1, -1).map(([src, if_in, if_out]) => (
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-mono">
                  {src}
                </td>
                <td className="border border-gray-300 px-4 py-2 font-mono text-center">
                  {if_in}
                </td>
                <td className="border border-gray-300 px-4 py-2 font-mono text-center">
                  {if_out}
                </td>
              </tr>
            ))}
            <tr>
              <td className="border border-gray-300 px-4 py-2 font-mono">
                {currentLink.target.name}
              </td>
              <td className="border border-gray-300 px-4 py-2 font-mono text-center">
                {currentLink.route.slice(-1)[0][1]}
              </td>
              <td className="border border-gray-300 px-4 py-2 font-mono bg-gray-200"></td>
            </tr>
          </table>
        </Modal>
      ) : null}

      <div className="flex flex-row w-full h-full relative">
        <div className="w-52 h-full flex flex-col justify-evenly">
          <div>
            <table className="w-full shadow-md ">
              <thead>
                <tr>
                  <th
                    className="bg-blue-100 border py-1 text-center table-fixed"
                    colspan={"2"}
                  >
                    GRAPH OPTIONS
                  </th>
                </tr>
              </thead>
              <tr onClick={showFilteredLinksOnClick}>
                <td className="pl-3 text-xs uppercase cursor-pointer select-none">
                  Show filtered links
                </td>
                <td className="pl-2">
                  <input
                    type="checkbox"
                    onChange={showFilteredLinksOnClick}
                    checked={showFilteredLinks}
                  />
                </td>
              </tr>
              <tr onClick={colorizeSubnetsOnClick}>
                <td className="pl-3 text-xs uppercase cursor-pointer select-none">
                  Colorize subnets
                </td>
                <td className="pl-2">
                  <input
                    type="checkbox"
                    onChange={colorizeSubnetsOnClick}
                    checked={colorizeSubnets}
                  />
                </td>
              </tr>
              <tr onClick={hideIntraSubnetsOnClick}>
                <td className="pl-3 text-xs uppercase cursor-pointer select-none">
                  Hide intra-subnets
                </td>
                <td className="pl-2">
                  <input
                    type="checkbox"
                    onChange={hideIntraSubnetsOnClick}
                    checked={hideIntraSubnets}
                  />
                </td>
              </tr>
              <tr onClick={showIPLinkOnClick}>
                <td className="pl-3 text-xs uppercase cursor-pointer select-none">
                  Show IPs
                </td>
                <td className="pl-2">
                  <input
                    type="checkbox"
                    onChange={showIPLinkOnClick}
                    checked={showIPLink}
                  />
                </td>
              </tr>
            </table>
          </div>
          <div>
            <table className="w-full shadow-md mt-4">
              <thead>
                <tr>
                  <th
                    className="bg-blue-100 border py-1 text-center table-fixed"
                    colspan={"2"}
                  >
                    HOT POINTS
                  </th>
                </tr>
              </thead>
              {nodes
                ? nodes
                    .sort((a, b) => b.betweeness - a.betweeness)
                    .filter((n) => n.betweeness >= 0.001)
                    .map((n) => (
                      <tr
                        id={n.id}
                        className="border-x text-sm"
                        onClick={(e) => hotPointClick(e, n)}
                        onMouseOver={(e) => hotPointOver(e, n, true)}
                        onMouseLeave={(e) => hotPointOver(e, n, false)}
                      >
                        <td
                          width="100px"
                          style={{
                            fontFamily: "monospace",
                            backgroundImage:
                              "linear-gradient(to right, rgba(252,189,6,0.1) 1%, rgba(237,123,35,0.4) 100%)",
                            backgroundSize: [
                              get_hotpoint_score(nodes, n),
                              "100%",
                            ],
                            backgroundRepeat: "no-repeat",
                          }}
                        >
                          {n.name}
                        </td>
                        <td className="text-center" width="50px">
                          {(Math.round(n.betweeness * 1000) / 1000).toFixed(3)}
                        </td>
                      </tr>
                    ))
                : ""}
            </table>
          </div>
        </div>
        <div className="flex w-[calc(100%-208px)] h-full flex-row p-4 justify-center">
          <div
            className="h-full w-full flex justify-center items-center bg-white"
            ref={displayWindow}
          >
            <svg id="NetGraph" ref={ref} />
          </div>
        </div>
        {showNodeDetails && (
          <div
            id="table-links"
            className={`opacity-90 overflow-auto px-2 absolute w-2/5 min-w-96 right-0 h-full bg-white ${
              !showNodeDetails && "display-none"
            }`}
          >
            <div className="w-full pt-2">
              <div className="flex flex-row w-full justify-center items-center">
                <XCircle
                  className="text-optistream-blue text-2xl hover:cursor-pointer"
                  onClick={() => setShowNodeDetails(false)}
                />
                <div className="w-full text-center text-sm flex flex-col">
                  {nodeName ? (
                    <>
                      <div>
                        <span className="text-optistream-orange text-base font-bold font-mono">
                          {nodeName}
                        </span>
                      </div>
                      <div>
                        <span className="text-optistream-orange font-bold">
                          ({nodeIP})
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <table className="shadow-md bg-white table-fixed mt-2 min-w-full">
                <thead>
                  <tr className="bg-optistream-orangeClicked py-4 text-sm text-left">
                    <th className="w-10 py-2"></th>
                    <th className="w-32 py-2">SOURCE</th>
                    <th className="w-32 py-2">TARGET</th>
                    <th className="min-w-10 py-2">PORTS</th>
                  </tr>
                </thead>
                <tr>
                  <th
                    colspan={"4"}
                    className="text-optistream-orange text-base text-center border-b-4 p-2"
                  >
                    IN
                  </th>
                </tr>
                {inLinks ? (
                  inLinks
                    .sort((a, b) => sort_link_by_ip(a, b, true))
                    .map((l) => (
                      <tr
                        id={l.index}
                        className="border px-2 py-4 tr-link"
                        onMouseOver={(e) => linkOver(e, l, true)}
                        onMouseLeave={(e) => linkOver(e, l, false)}
                      >
                        <td className="px-2 py-1 cursor-pointer">
                          <InfoCircle onClick={() => openModal(l)} />
                        </td>
                        <td className="font-mono text-sm py-1 px-2 min-w-40 select-none">
                          {showIPLink
                            ? l.source.ip.split("/")[0]
                            : l.source.name}
                        </td>
                        <td className="font-mono text-sm py-1 px-2 min-w-40 select-none">
                          {l.target.name}
                        </td>
                        <td className="py-1 pr-2 min-w-32 select-none">
                          <div>
                            {l.permit_ports.length ===
                            Object.keys(analysis.result["audited_protos"])
                              .length ? (
                              <div className="whitespace-nowrap my-1 mr-1 rounded text-optistream-txt-white px-2 py-0.5 w-fit h-fit bg-portFilters-nofilter text-xs font-bold">
                                No filters
                              </div>
                            ) : (
                              l.permit_ports
                                .sort((a, b) => {
                                  if (a.length === b.length) {
                                    return a > b ? 1 : -1;
                                  } else if (a.length < b.length) {
                                    return -1;
                                  }

                                  return 1;
                                })
                                .map((port) => (
                                  <>
                                    <span className="whitespace-nowrap my-1 mr-1 rounded text-optistream-txt-white px-2 py-0.5 w-fit h-fit bg-portFilters-port text-xs font-bold">
                                      {port.toUpperCase()}
                                    </span>
                                  </>
                                ))
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <th
                      colspan={"4"}
                      className="text-center text-sm font-normal italic"
                    >
                      No selected node
                    </th>
                  </tr>
                )}
                <tr>
                  <th
                    colspan="4"
                    className="text-optistream-orange text-base text-center border-b-4 p-2"
                  >
                    OUT
                  </th>
                </tr>
                {outLinks ? (
                  outLinks
                    .sort((a, b) => sort_link_by_ip(a, b, false))
                    .map((l) => (
                      <tr
                        id={l.index}
                        className="border px-8 py-4 tr-link"
                        onMouseOver={(e) => linkOver(e, l, true)}
                        onMouseLeave={(e) => linkOver(e, l, false)}
                      >
                        <td className="pl-2 py-1 cursor-pointer">
                          <InfoCircle onClick={() => openModal(l)} />
                        </td>
                        <td className="font-mono text-sm py-1 px-2 min-w-40 select-none">
                          {l.source.name}
                        </td>
                        <td className="font-mono text-sm py-1 px-2 min-w-40 select-none">
                          {showIPLink
                            ? l.target.ip.split("/")[0]
                            : l.target.name}
                        </td>
                        <td className="py-1">
                          <div>
                            {l.permit_ports.length ===
                            Object.keys(analysis.result["audited_protos"])
                              .length ? (
                              <div className="mb-1 mr-1 rounded text-optistream-txt-white px-2 py-0.5 w-fit h-fit bg-portFilters-nofilter text-xs font-bold">
                                No filters
                              </div>
                            ) : (
                              l.permit_ports
                                .sort((a, b) => {
                                  if (a.length === b.length) {
                                    return a > b ? 1 : -1;
                                  } else if (a.length < b.length) {
                                    return -1;
                                  }

                                  return 1;
                                })
                                .map((port) => (
                                  <>
                                    <span className="whitespace-nowrap mb-1 mr-1 rounded text-optistream-txt-white px-2 py-0.5 w-fit h-fit bg-portFilters-port text-xs font-bold">
                                      {port.toUpperCase()}
                                    </span>
                                  </>
                                ))
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <th
                      colspan={"4"}
                      className="text-center text-sm font-normal italic"
                    >
                      No selected node
                    </th>
                  </tr>
                )}
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
