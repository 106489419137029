const dateTimeFormat = new Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

function toDateObject(o) {
  if (typeof o === "string" || o instanceof String) {
    return new Date(o);
  } else if (o instanceof Date) {
    return o;
  } else {
    throw new Error("Passed object neither a date nor a string");
  }
}

function formatDate(date) {
  return dateTimeFormat.format(toDateObject(date));
}

function compareDates(d1, d2) {
  d1 = toDateObject(d1);
  d2 = toDateObject(d2);
  return new Date(d2) - new Date(d1);
}

export default { formatDate, compareDates };
