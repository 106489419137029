import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import Fuse from "fuse.js";
import Select from "react-tailwindcss-select";

import {
  Wifi,
  Bricks,
  Router,
  HddNetwork,
  PcHorizontal,
  PcDisplayHorizontal,
  Diagram3,
  Check2Circle,
  XCircle,
  Pencil,
  Sticky,
  ZoomIn,
  BoxArrowDown,
  HandIndex,
  BoxArrowInDownRight,
  FolderSymlink,
  FiletypeCsv,
  ChevronDoubleDown,
  ChevronDoubleUp,
} from "react-bootstrap-icons";
import { useAlert } from "react-alert";

import { useAPI } from "../../../utils";
import { useReactFlow } from "reactflow";
import AWSIcon from "react-aws-icons/dist/aws/logo/AWS";
import Internet from "react-aws-icons/dist/aws/general/Internet";
import UploadZIPConfig from "./uploadZIPConfig";
import SyncAWSConfig from "./SyncAWSConfig";
import { analysisTypes } from "../misc/utils";

export default function Sidebar({
  editMapName,
  setEditMapName,
  setSaveStatus,
  setCurrentSnapshotStatus,
  showMinimap,
  showMinimapOnClick,
  nodes,
  snapshotId,
  workspaceId,
  dynamicPath,
  setDynamicPath,
  callCheckAnalysisConditions,
  analysisToPerform,
  setAnalysisToPerform,
}) {
  const onDragStartNetworkNode = (event, nodeType, eqptType) => {
    //event.dataTransfer.setData('application/reactflow', 'networkNode');
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("eqptType", eqptType);
    event.dataTransfer.effectAllowed = "move";
  };

  const { exportWorkspaceFile, traceroute } = useAPI();

  const [showWorkspaceMngt, setShowWorkspaceMngt] = useState(true);
  const [tmpNewName, setTmpNewName] = useState("");
  const [editMapNameMode, setEditMapNameMode] = useState(false);

  const [searchTerms, setSearchTerms] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [searchResultInfoDetailsList, setSearchResultInfoDetailsList] =
    useState([]);
  const [searchResultServicesDetailsList, setSearchResultServicesDetailsList] =
    useState([]);
  const [searchResultList, setSearchResultList] = useState([]);

  const [showTraceroute, setShowTraceroute] = useState(true);
  const [tracertSrc, setTracertSrc] = useState(null);
  const [tracertDst, setTracertDst] = useState(null);
  const [selectedReachabilitySvc, setSelectedReachabilitySvc] = useState(null);
  const [tracertWithACL, setTracertWithACL] = useState(true);

  const [nodeSelectionList, setNodeSelectionList] = useState([]);

  const [importOverlay, setImportOverlay] = useState(null);
  const { setViewport, zoomIn, zoomOut, setCenter } = useReactFlow();

  const [showAnalysis, setShowAnalysis] = useState(false);

  const [importMode, setImportMode] = useState("manual");
  const [showBuild, setShowBuild] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);

  const alert = useAlert();

  const reachabilityServices = [
    {
      value: "ECHO-REQUEST",
      label: "PING",
    },
    {
      value: "SSH",
      label: "SSH",
    },
    {
      value: "tcp/3389",
      label: "RDP",
    },
    {
      value: "TELNET",
      label: "TELNET",
    },
    {
      value: "HTTP",
      label: "HTTP",
    },
    {
      value: "tcp/8080",
      label: "HTTP (8080)",
    },
    {
      value: "HTTPS",
      label: "HTTPS",
    },
    {
      value: "DNS",
      label: "DNS",
    },
    {
      value: "SNMP",
      label: "SNMP",
    },

    {
      value: "TCP/",
      label: "** TCP **",
    },

    {
      value: "UDP",
      label: "** UDP **",
    },
  ];

  useEffect(() => {
    const fuse = new Fuse(nodes, {
      keys: [
        "data.DisplayName",
        "data.InformationItems",
        "data.IP",
        "data.BusinessServices.appName",
      ],
    });
    const results = fuse.search(searchTerms).map(({ item }) => item);

    if (searchTerms.length >= 2 && results.length) {
      setSearchResultList(results);
      const detailResultsInfoList = results.map((node, index) => {
        if (!node.data.InformationItems) return [];

        const infoItems = JSON.parse(node.data.InformationItems);
        const fuseInfo = new Fuse(infoItems, { keys: ["title", "desc"] });
        const resultsInfo = fuseInfo
          .search(searchTerms)
          .map(({ item }) => item);

        return resultsInfo;
      });

      const detailResultsServicesList = results.map((node, index) => {
        if (!node.data.BusinessServices) return [];

        const infoItems = node.data.BusinessServices.map((o) => {
          return o.appName;
        });
        const fuseSvc = new Fuse(infoItems);
        const resultsSvc = fuseSvc.search(searchTerms).map(({ item }) => item);

        return resultsSvc;
      });

      setSearchResultInfoDetailsList(detailResultsInfoList);
      setSearchResultServicesDetailsList(detailResultsServicesList);
    } else {
      setSearchResultList([]);
      setSearchResultInfoDetailsList([]);
      setSearchResultServicesDetailsList([]);
    }
  }, [searchTerms]);

  useEffect(() => {
    setNodeSelectionList(
      nodes
        .filter(
          (elt) => elt.data.NodeType === "terminal" && elt.data.interface_data
          //(elt) => elt.data.interface_data
        )
        .map(
          (node, index) =>
            new Object({
              label:
                node.data.DisplayName ||
                node.data.HostName ||
                node.data.IP ||
                "",
              value: node.id,
              interface: node.data.interfaces
                ? node.data.interfaces[0]
                : "Unnamed node",
              ip: node.data.IP,
            })
        )
    );
  }, [nodes]);

  useEffect(() => {
    setDynamicPath([]);
  }, [tracertDst, tracertDst, tracertWithACL, selectedReachabilitySvc]);

  const onClose = () => {
    setImportOverlay(null);
  };

  const callTraceroute = () => {
    alert.info("Traceroute launched ... wait for result");
    traceroute(
      workspaceId,
      snapshotId,
      tracertSrc.value,
      tracertDst.value,
      tracertWithACL,
      selectedReachabilitySvc.value
    )
      .then((res) => {
        if (res.result === "ACCEPTED") alert.success("Traceroute successful");
        else if (
          res.result === "NEIGHBOR_UNREACHABLE" ||
          res.result === "NO_ROUTE"
        )
          alert.error("Traceroute: target unreachable");
        else if (res.result === "DENIED_OUT" || res.result === "DENIED_IN")
          alert.error("Traceroute: target denied");
        else alert.info(res.result);
        setDynamicPath(res.path);
      })
      .catch((e) => void e);
  };

  const clearTraceroute = (full = true) => {
    if (dynamicPath.length) {
      setDynamicPath([]);
      if (full) {
        setTracertDst(null);
        setTracertSrc(null);
        setSelectedReachabilitySvc(null);
      }
    }
  };

  const handleImportButtonClick = () => {
    setShowOverlay(true);
  };

  return (
    <div className="w-[16vw] h-full grow-0 overflow-auto">
      <div className="w-full p-4">
        <div className="flex flex-col">
          <div className="flex text-optistream-txt-blue font-bold text-base uppercase my-2 relative justify-between">
            <div>Workspace management</div>
            <button onClick={() => setShowWorkspaceMngt(!showWorkspaceMngt)}>
              {showWorkspaceMngt ? <ChevronDoubleUp /> : <ChevronDoubleDown />}
            </button>
          </div>
          <div
            id="workspaceMngt"
            className={`${!showWorkspaceMngt && "hidden"}`}
          >
            <div className="flex flex-row items-center ml-2 text-sm justify-between">
              <div className="flex flex-row">
                <div className="align-middle text-black-light">Name: </div>
                <div className="ml-1 align-middle text-optistream-txt-blue">
                  {editMapName}
                </div>
              </div>
              <div
                className="bg-optistream-btn-default rounded-full h-6 w-6 flex justify-center items-center text-optistream-txt-white p-1 mr-2 cursor-pointer"
                onClick={() => {
                  setTmpNewName(editMapName);
                  setEditMapNameMode(true);
                }}
              >
                <Pencil />
              </div>
            </div>
            {editMapNameMode ? (
              <div className="flex flex-row items-center">
                <input
                  type="text"
                  placeholder="New map name"
                  className="text-sm text-gray-base w-48 mt-1 ml-2 mr-3 px-4 h-8 border border-gray-primary rounded"
                  onChange={({ target }) => setTmpNewName(target.value)}
                  value={tmpNewName}
                />
                <button
                  onClick={() => {
                    setEditMapName(tmpNewName);
                    setEditMapNameMode(false);
                    setSaveStatus(true);
                  }}
                  className="flex h-8 w-8 scale-150 text-optistream-txt-blue items-center justify-center"
                >
                  <Check2Circle />
                </button>
                <button
                  onClick={() => setEditMapNameMode(false)}
                  className="flex h-8 w-8 scale-150 text-optistream-txt-orange  items-center justify-center ml-2"
                >
                  <XCircle />
                </button>
              </div>
            ) : null}
            <div className="flex flex-row items-center ml-2 mt-1 justify-between">
              <div className="text-sm text-black-light">Show minimap: </div>
              <Switch
                onChange={showMinimapOnClick}
                checked={showMinimap}
                className="scale-75 align-middle"
              />
            </div>
            {/* <div className="ml-2 mt-2">
          <span className="text-base">Show interfaces:</span>
          <Switch
            onChange={switchShowInterface}
            checked={showInterface}
            className="ml-2 mr-2 align-middle"
          />
        </div> */}
            {window.location.hostname === "localhost" && (
              <div className="flex flex-row items-center ml-2 mt-1 justify-between">
                <div className="text-sm text-black-light">Export map: </div>
                <button
                  className="bg-optistream-btn-default rounded-full h-6 w-6 flex justify-center items-center text-optistream-txt-white p-1 mr-2 cursor-pointer"
                  onClick={() => {
                    clearTraceroute();
                    exportWorkspaceFile(workspaceId, editMapName);
                  }}
                >
                  <BoxArrowDown />
                </button>
              </div>
            )}
          </div>

          <div className="flex ml-2 text-optistream-txt-blue font-bold text-base uppercase mt-4 mb-2 justify-between">
            <div>Analysis</div>
            <button onClick={() => setShowAnalysis(!showAnalysis)}>
              {showAnalysis ? <ChevronDoubleUp /> : <ChevronDoubleDown />}
            </button>
          </div>
          <div id="analysis" className={`ml-2 ${!showAnalysis && "hidden"}`}>
            <div className="w-full">
              <Select
                primaryColor={"indigo"}
                value={analysisToPerform}
                isMultiple={true}
                name="Analysis"
                placeholder="Select analysis"
                onChange={(element) => {
                  setAnalysisToPerform(element);
                }}
                options={analysisTypes}
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex w-56 border border-gray-300 rounded shadow-sm text-gray-base
                      transition-all duration-300  bg-white
                      mb-1 hover:border-gray-400 
                      focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500/20 
                      ${analysisToPerform ? "text-xs" : " text-sm"}`,
                  menu: "absolute z-10 w-56 bg-white shadow-lg border rounded py-1 text-sm text-gray-200 mb-1",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer
                      select-none truncate rounded ${
                        isSelected
                          ? `text-optistream-txt-white bg-blue-menu`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                }}
              />
            </div>
            <div className="flex flex-row w-full items-center justify-evenly mt-1">
              <button
                className={`bg-optistream-btn-default mt-2 active:bg-optistream-btn-clicked text-optistream-txt-white 
              h-8 text-sm w-40 rounded font-bold flex flex-row items-center 
              justify-center ${analysisToPerform === null && "opacity-50"}`}
                disabled={analysisToPerform === null}
                onClick={() => {
                  clearTraceroute();
                  callCheckAnalysisConditions();
                }}
              >
                <HandIndex className="mr-2" /> Check conditions
              </button>
            </div>
          </div>
          <div className="flex ml-2 text-optistream-txt-blue font-bold text-base uppercase mt-4 mb-2 justify-between">
            <div>Search</div>
            <button onClick={() => setShowSearch(!showSearch)}>
              {showSearch ? <ChevronDoubleUp /> : <ChevronDoubleDown />}
            </button>
          </div>
          <div id="search" className={`${!showSearch && "hidden"}`}>
            <input
              type="text"
              placeholder="Key words"
              className="text-sm text-gray-base w-11/12 mx-2 pl-4 h-8 border border-gray-primary rounded"
              value={searchTerms}
              onChange={(evt) => {
                clearTraceroute(false);
                setSearchTerms(evt.target.value);
              }}
            />
          </div>
        </div>
        {searchTerms === "" && (
          <>
            <div className="flex ml-2 text-optistream-txt-blue font-bold text-base uppercase mt-4 mb-2 justify-between">
              <div>Traceroute</div>
              <button onClick={() => setShowTraceroute(!showTraceroute)}>
                {showTraceroute ? <ChevronDoubleUp /> : <ChevronDoubleDown />}
              </button>
            </div>
            <div
              id="traceroute"
              className={`ml-2 ${!showTraceroute && "hidden"}`}
            >
              <div className="flex flex-col items-start">
                <div className="flex align-middle text-sm text-black-light mr-2 w-16">
                  Source:{" "}
                </div>
                <div className="w-full">
                  <Select
                    primaryColor={"indigo"}
                    value={
                      tracertSrc
                        ? {
                            value: tracertSrc.value,
                            label: tracertSrc.label,
                            disabled: false,
                          }
                        : null
                    }
                    isSearchable={true}
                    isClearable={true}
                    name="tracertSrc"
                    placeholder="Select source"
                    onChange={(element) => {
                      setTracertSrc(element);
                    }}
                    options={nodeSelectionList}
                    classNames={{
                      menuButton: ({ isDisabled }) =>
                        `flex w-full text-sm border border-gray-300 rounded shadow-sm text-gray-base
                            transition-all duration-300 focus:outline-none bg-white
                            hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20 mb-1`,
                      menu: "absolute z-10 w-full bg-white shadow-lg border rounded py-1 text-sm text-gray-200 mb-1",
                      listItem: ({ isSelected }) =>
                        `block transition duration-200 px-2 py-2 cursor-pointer
                                                          select-none truncate rounded ${
                                                            isSelected
                                                              ? `text-optistream-txt-white bg-blue-menu`
                                                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                                                          }`,
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start">
                <div className="flex align-middle text-sm text-black-light w-16 mr-2 mt-2">
                  Dest:{" "}
                </div>
                <div className="w-full">
                  <Select
                    primaryColor={"indigo"}
                    value={
                      tracertDst
                        ? {
                            value: tracertDst.value,
                            label: tracertDst.label,
                            disabled: false,
                          }
                        : null
                    }
                    isSearchable={true}
                    isClearable={true}
                    name="tracertSrc"
                    placeholder="Select destination"
                    onChange={(element) => {
                      setTracertDst(element);
                    }}
                    options={nodeSelectionList}
                    classNames={{
                      menuButton: ({ isDisabled }) =>
                        `flex w-full text-sm border border-gray-300 rounded shadow-sm text-gray-base
                            transition-all duration-300 focus:outline-none bg-white
                            hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20 mb-1`,
                      menu: "absolute z-10 w-full bg-white shadow-lg border rounded py-1 text-sm text-gray-200 mb-1",
                      listItem: ({ isSelected }) =>
                        `block transition duration-200 px-2 py-2 cursor-pointer
                                                          select-none truncate rounded ${
                                                            isSelected
                                                              ? `text-optistream-txt-white bg-blue-menu`
                                                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                                                          }`,
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start">
                <div className="flex align-middle text-sm text-black-light w-16 mr-2 mt-2">
                  Protocol:{" "}
                </div>
                <div className="w-full">
                  <Select
                    primaryColor={"indigo"}
                    value={selectedReachabilitySvc}
                    isSearchable={true}
                    isClearable={true}
                    name="reachabilitySvc"
                    placeholder="Select a service"
                    onChange={(element) => {
                      setSelectedReachabilitySvc(element);
                    }}
                    options={reachabilityServices}
                    classNames={{
                      menuButton: ({ isDisabled }) =>
                        `flex w-full text-sm border border-gray-300 rounded shadow-sm text-gray-base
                            transition-all duration-300 focus:outline-none bg-white
                            hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20 mb-1`,
                      menu: "absolute z-10 w-full bg-white shadow-lg border rounded py-1 text-sm text-gray-200 mb-1",
                      listItem: ({ isSelected }) =>
                        `block transition duration-200 px-2 py-2 cursor-pointer
                                                          select-none truncate rounded ${
                                                            isSelected
                                                              ? `text-optistream-txt-white bg-blue-menu`
                                                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                                                          }`,
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="flex align-middle text-sm text-black-light w-14 mr-2 ">
                  ACL ?
                </div>
                <Switch
                  onChange={() => setTracertWithACL(!tracertWithACL)}
                  checked={tracertWithACL}
                  className="scale-75 align-middle"
                />
              </div>
              <div className="flex flex-row w-full items-center justify-evenly mt-1">
                <button
                  className={`bg-optistream-btn-default active:bg-optistream-btn-clicked text-optistream-txt-white 
              h-8 text-sm w-24 rounded font-bold flex flex-row items-center 
              justify-center ${
                (tracertSrc === null ||
                  tracertDst === null ||
                  selectedReachabilitySvc === null) &&
                "opacity-50"
              }`}
                  disabled={
                    tracertSrc === null ||
                    tracertDst === null ||
                    selectedReachabilitySvc === null
                  }
                  onClick={() => {
                    clearTraceroute(false);
                    callTraceroute();
                  }}
                >
                  <HandIndex className="mr-2" /> Run
                </button>
                <button
                  className={`bg-optistream-btn-alternative ml-2 active:bg-btn-altClicked text-optistream-txt-white 
              h-8 text-sm w-24 rounded font-bold flex flex-row items-center 
              justify-center 
              }`}
                  onClick={() => {
                    clearTraceroute();
                  }}
                >
                  <XCircle className="mr-2" /> Clear
                </button>
              </div>
            </div>
          </>
        )}
        {searchTerms === "" && (
          <>
            <div className="flex ml-2 text-optistream-txt-blue font-bold text-base uppercase mt-4 mb-2 justify-between">
              <div>Build & update map</div>
              <button onClick={() => setShowBuild(!showBuild)}>
                {showBuild ? <ChevronDoubleUp /> : <ChevronDoubleDown />}
              </button>
            </div>
            <div id="build" className={`${!showBuild && "hidden"}`}>
              <>
                <div className="flex flex-row w-full items-center justify-evenly">
                  <button
                    className={`bg-optistream-btn-default my-2 active:bg-optistream-btn-clicked text-optistream-txt-white h-8 text-sm w-24  
          rounded font-bold flex flex-row items-center justify-center ${
            importMode === "manual" && "opacity-50"
          }`}
                    onClick={() => setImportMode("manual")}
                  >
                    <HandIndex className="mr-2" /> Manual
                  </button>
                  <button
                    className={`bg-optistream-btn-default my-2 active:bg-optistream-btn-clicked text-optistream-txt-white h-8 text-sm w-24  
          rounded font-bold flex flex-row items-center justify-center ${
            importMode === "auto" && "opacity-50"
          }`}
                    onClick={() => setImportMode("auto")}
                  >
                    <BoxArrowInDownRight className="mr-6" />
                    Auto
                  </button>
                </div>
                {importMode === "manual" ? (
                  <div className="flex w-full items-center justify-center ml-2 text-black-light italic text-sm mb-1 mt-4">
                    Drag & Drop elements
                  </div>
                ) : null}
                {importMode === "manual" ? (
                  <div className="flex flex-col justify-evenly relative ">
                    <div className="flex flex-col py-2 ">
                      <div className="flex flex-col">
                        {/* Network */}
                        <div className="text-sm text-optistream-txt-orange font-bold ml-2">
                          Network :
                        </div>
                        <>
                          <div className="flex flex-row items-center mb-1 ">
                            <div className="ml-3 flex w-2/3  text-sm justify-start align-center">
                              - Switch
                            </div>
                            <div className="flex w-1/3 justify-center">
                              <div
                                className="flex border border-orange-medium text-optistream-txt-blue  rounded-full h-8 w-8 cursor-grab items-center justify-center"
                                onDragStart={(event) =>
                                  onDragStartNetworkNode(
                                    event,
                                    "core",
                                    "Switch"
                                  )
                                }
                                draggable
                              >
                                <HddNetwork />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row items-center mb-1 ">
                            <div className="ml-3 flex w-2/3  text-sm justify-start align-center">
                              - Router
                            </div>
                            <div className="flex w-1/3 justify-center">
                              <div
                                className="flex border border-orange-medium text-optistream-txt-blue  rounded-full h-8 w-8 cursor-grab items-center justify-center"
                                onDragStart={(event) =>
                                  onDragStartNetworkNode(
                                    event,
                                    "core",
                                    "Router"
                                  )
                                }
                                draggable
                              >
                                <Router />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row items-center mb-1 ">
                            <div className="ml-3 flex w-2/3  text-sm justify-start align-center">
                              - Firewall
                            </div>
                            <div className="flex w-1/3 justify-center">
                              <div
                                className="flex border border-orange-medium text-optistream-txt-blue  rounded-full h-8 w-8 cursor-grab items-center justify-center"
                                onDragStart={(event) =>
                                  onDragStartNetworkNode(
                                    event,
                                    "core",
                                    "Firewall"
                                  )
                                }
                                draggable
                              >
                                <Bricks />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row items-center mb-1 ">
                            <div className="ml-3 flex w-2/3  text-sm justify-start align-center">
                              - WiFi spot
                            </div>
                            <div className="flex w-1/3 justify-center">
                              <div
                                className="flex border border-orange-medium text-optistream-txt-blue  rounded-full h-8 w-8 cursor-grab items-center justify-center"
                                onDragStart={(event) =>
                                  onDragStartNetworkNode(event, "core", "Wifi")
                                }
                                draggable
                              >
                                <Wifi />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row items-center mb-1 ">
                            <div className="ml-3 flex w-2/3  text-sm justify-start align-center">
                              - Internet Service Provider
                            </div>
                            <div className="flex w-1/3 justify-center">
                              <div
                                className="flex border border-orange-medium text-optistream-txt-blue  rounded-full h-8 w-8 cursor-grab items-center justify-center"
                                onDragStart={(event) =>
                                  onDragStartNetworkNode(event, "core", "isp")
                                }
                                draggable
                              >
                                <Internet />
                              </div>
                            </div>
                          </div>
                        </>
                        {/* Endpoints */}
                        <div className="text-sm text-optistream-txt-orange font-bold ml-2">
                          Endpoints:
                        </div>
                        <div className="flex flex-row items-center mb-1 ">
                          <div className="ml-3 flex w-2/3  text-sm justify-start align-center">
                            - Server
                          </div>
                          <div className="flex w-1/3 justify-center">
                            <div
                              className="flex border border-blue-medium text-optistream-txt-orange  rounded-full h-8 w-8 cursor-grab items-center justify-center"
                              onDragStart={(event) =>
                                onDragStartNetworkNode(
                                  event,
                                  "terminal",
                                  "Server"
                                )
                              }
                              draggable
                            >
                              <PcHorizontal />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center mb-1 ">
                          <div className="ml-3 flex w-2/3  text-sm justify-start align-center">
                            - PC
                          </div>
                          <div className="flex w-1/3 justify-center">
                            <div
                              className="flex border border-blue-medium text-optistream-txt-orange  rounded-full h-8 w-8 cursor-grab items-center justify-center"
                              onDragStart={(event) =>
                                onDragStartNetworkNode(event, "terminal", "PC")
                              }
                              draggable
                            >
                              <PcDisplayHorizontal />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center mb-1 ">
                          <div className="ml-3 flex w-2/3  text-sm justify-start align-center">
                            - LAN / VLAN
                          </div>
                          <div className="flex w-1/3 justify-center">
                            <div
                              className="flex border border-blue-medium text-optistream-txt-orange  rounded-full h-8 w-8 cursor-grab items-center justify-center"
                              onDragStart={(event) =>
                                onDragStartNetworkNode(event, "group", "(V)LAN")
                              }
                              draggable
                            >
                              <Diagram3 />
                            </div>
                          </div>
                        </div>

                        {/* Misc */}
                        <div className="text-sm text-optistream-txt-orange font-bold ml-2">
                          Miscellaneous :
                        </div>
                        <div className="flex flex-row items-center mb-1 ">
                          <div className="ml-3 flex w-2/3 text-sm justify-start align-center">
                            - Notes
                          </div>
                          <div className="flex w-1/3 justify-center">
                            <div
                              className="flex bg-orange-note rounded-full h-8 w-8 cursor-grab items-center justify-center"
                              onDragStart={(event) =>
                                onDragStartNetworkNode(event, "note", "Note")
                              }
                              draggable
                            >
                              <Sticky />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col w-full items-center">
                    <button
                      className="border bg-btn-gray active:bg-btn-grayClicked rounded-lg w-32 h-16 flex flex-row text-xs items-center justify-center mt-4"
                      onClick={() => setImportOverlay("config_files")}
                    >
                      <FolderSymlink className="mr-2 text-optistream-txt-blue text-4xl" />{" "}
                      Upload bulk configs
                    </button>
                    <button
                      className="border bg-btn-gray active:bg-btn-grayClicked rounded-lg w-32 h-16 flex flex-row text-xs items-center justify-center mt-4"
                      onClick={() => setImportOverlay("csv")}
                    >
                      <FiletypeCsv className="mr-2 text-optistream-txt-blue text-4xl" />
                      Upload CSV configs
                    </button>
                    <button
                      className="border bg-btn-gray active:bg-btn-grayClicked rounded-lg w-32 h-16 flex flex-row text-xs items-center mt-4"
                      onClick={() => setImportOverlay("aws")}
                    >
                      <AWSIcon size={66} className="mr-3" /> Zipped config
                    </button>
                    <button
                      className="border bg-btn-gray active:bg-btn-grayClicked rounded-lg w-32 h-16 flex flex-row text-xs items-center mt-4"
                      onClick={handleImportButtonClick}
                    >
                      <AWSIcon size={66} className="mr-3" /> Sync aws configs
                    </button>
                    {showOverlay && (
                      <SyncAWSConfig
                        workspaceId={workspaceId}
                        snapshotId={snapshotId}
                        setCurrentSnapshotStatus={setCurrentSnapshotStatus}
                        onCloseCallback={() => setShowOverlay(false)}
                      />
                    )}
                    <button className="border bg-btn-gray active:bg-btn-grayClicked rounded-lg w-32 h-16 flex flex-row text-xs items-center justify-center mt-4">
                      <img src="/images/tufin.png" className="flex w-24" />
                    </button>
                  </div>
                )}
              </>
            </div>
          </>
        )}

        <div className="flex flex-col">
          {searchTerms !== "" ? (
            searchResultList.length ? (
              <div className="flex flex-col mt-2">
                <div className="flex ml-2 text-base font-bold">Results </div>
                {searchResultList.map((node, index) => (
                  <div className="flex flex-col ml-2 border-b mt-2 pb-2">
                    <div className="flex flex-row items-center justify-between">
                      <div key={index} className="text-base uppercase">
                        {node.data.EquipmentType}: {node.data.DisplayName}
                      </div>

                      <div
                        className="cursor-pointer text-optistream-txt-blue"
                        onClick={() =>
                          setCenter(node.position.x, node.position.y, {
                            duration: 800,
                          })
                        }
                      >
                        <ZoomIn />
                      </div>
                    </div>
                    {searchResultInfoDetailsList[index].length ? (
                      <div className="ml-4">
                        <div className="font-bold italic">
                          Found in Information
                        </div>
                        {searchResultInfoDetailsList[index].map(
                          (detail, subIndex) => (
                            <div key={`${index}-${subIndex}`}>
                              <span className="underline">{detail.title}:</span>
                              <br />
                              {detail.desc}
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                    {searchResultServicesDetailsList[index].length ? (
                      <div className="ml-4">
                        <div className="font-bold italic">
                          Attached to application
                        </div>
                        {searchResultServicesDetailsList[index].map(
                          (appName, subIndex) => (
                            <div key={`${index}-${subIndex}`}>
                              Name: {appName}
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : (
              <div className="italic ml-4 mt-2">No result found</div>
            )
          ) : null}
        </div>

        {importOverlay && (
          <UploadZIPConfig
            workspaceId={workspaceId}
            snapshotId={snapshotId}
            setCurrentSnapshotStatus={setCurrentSnapshotStatus}
            onCloseCallback={onClose}
            importType={importOverlay}
          />
        )}
      </div>
    </div>
  );
}
