import React, { useEffect, useState } from "react";
import * as Modal from "../Modal/modal";
import Select from "react-tailwindcss-select";
import { useAlert } from "react-alert";
import Spinner from "../misc/spinner";
import { useAPI } from "../../../utils/API";
import { analysisTypeToName } from "../misc/utils";

import { CheckCircle, ClipboardX, XSquareFill } from "react-bootstrap-icons";

export default function AnalysisLauncherOverlay({
  workspaceId,
  alert,
  onCloseCallback,
  analysisToPerform,
}) {
  const [loading, setLoading] = useState(true);
  const [canBeRun, setCanBeRun] = useState(null);
  const [failedConditions, setFailedConditions] = useState(null);

  const { checkAnalyses, runAnalyses } = useAPI();

  const analysisTypes = analysisToPerform
    .filter((entry) => !entry.disabled)
    .map((entry) => entry.value);

  useEffect(() => {
    checkAnalyses(workspaceId, analysisTypes)
      .then(() => setCanBeRun(true))
      .catch((e) => {
        if (e.code === "ERR_BAD_REQUEST") {
          setCanBeRun(false);
          setFailedConditions(e.response.data);
        }
      });
  }, []);

  useEffect(() => {
    if (canBeRun === true || canBeRun === false) {
      setLoading(false);
    }
  }, [canBeRun]);

  const launchAnalyses = () => {
    runAnalyses(workspaceId, analysisTypes)
      .then(() => {
        alert.success("Analyses succesfully launched");
        onCloseCallback();
      })
      .catch((e) => void e);
  };

  return (
    <Modal.Frame
      onClose={() => {
        onCloseCallback();
      }}
    >
      <Modal.Head>Launch analyses</Modal.Head>
      <Modal.Body>
        <div className="flex flex-col h-4/5 w-full align-middle justify-center items-center">
          {loading ? (
            <div className="flex flex-col w-full text-black-full items-center text-base">
              <div className="mt-8">
                <Spinner />
              </div>
            </div>
          ) : failedConditions ? (
            <div className="flex flex-col w-full align-middle justify-center items-center">
              <ClipboardX
                width={100}
                height={100}
                color="crimson"
                className="m-4"
              />

              <ul>
                {Object.keys(failedConditions).map((condition) => (
                  <li key={condition} className="text-black-light my-1">
                    <XSquareFill
                      width={24}
                      height={24}
                      color="crimson"
                      class="inline"
                    />{" "}
                    {failedConditions[condition].msg}
                    {" ("}
                    {failedConditions[condition].analysis_types
                      .map(analysisTypeToName)
                      .join(", ")}
                    {")"}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="flex flex-col w-full align-middle justify-center items-center">
              <CheckCircle width={100} height={100} color="green" />
              <p class="text-optistream-txt-blue text-2xl my-10">
                All conditions to run the analyses are fullfilled
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col h-1/5 w-full align-middle justify-center items-center">
          <button
            className="bg-optistream-btn-default mt-2 active:bg-optistream-btn-clicked text-optistream-txt-white 
              h-8 text-sm w-40 rounded font-bold flex flex-row items-center 
              justify-center disabled:opacity-50"
            disabled={canBeRun !== true}
            onClick={launchAnalyses}
          >
            Run analyses
          </button>
        </div>
      </Modal.Body>
    </Modal.Frame>
  );
}
