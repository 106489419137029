import React, { FC, useCallback, useEffect, useState } from "react";
import {
  useStore,
  getStraightPath,
  getSimpleBezierPath,
  getSmoothStepPath,
  EdgeLabelRenderer,
  MarkerType,
  BaseEdge,
  useReactFlow,
} from "reactflow";
import { PencilFill, LockFill } from "react-bootstrap-icons";

import { getEdgeParams } from "./reactflowUtils.js";
import { DisplayMapContext } from "./displayMapContext.js";

function FloatingEdge({
  id,
  source,
  target,
  data,
  markerEnd,
  animated,
  style,
  showInterface,
}) {
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }
  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );

  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const iconSize = 10;

  return (
    <>
      {(animated ||
        ((sourceNode.type !== "group" || targetNode.type !== "group") &&
          !sourceNode.hasOwnProperty("parentNode") &&
          !targetNode.hasOwnProperty("parentNode"))) && (
        <DisplayMapContext.Consumer>
          {({ displayInterface, showAudit }) => (
            <>
              <path
                id={id}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
                style={animated ? { strokeWidth: 4, stroke: "red" } : style} //{showAudit ? style : { strokeWidth: 2, stroke: "red" }}
              />
              {/* <BaseEdge path={edgePath} style={style} animated={true} /> */}
              {showAudit === undefined || !showAudit ? (
                <EdgeLabelRenderer>
                  <div
                    style={{
                      position: "absolute",
                      transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                      fontSize: 12,
                      // everything inside EdgeLabelRenderer has no pointer events by default
                      // if you have an interactive element, set pointer-events: all
                      pointerEvents: "all",
                    }}
                    className="nodrag nopan"
                  >
                    <button className="w-4 h-4 rounded-full bg-gray-primary hover:shadow-lg hover:shadow-gray-500 text-optistream-txt-white text-sm flex justify-center items-center align-middle">
                      {data.Computed ? (
                        <LockFill size={iconSize} />
                      ) : (
                        <PencilFill size={iconSize} />
                      )}
                    </button>
                  </div>
                  {/* <g
                  transform={
                    "translate(" +
                    (labelX.toString() - iconSize / 2) +
                    " " +
                    (labelY.toString() - iconSize / 2) +
                    ")"
                  }
                  onClick={data.onClick}
                >
                  <circle
                    cx={iconSize / 2}
                    cy={iconSize / 2}
                    r={iconSize / 2 + 2}
                    fill="rgb(242, 242, 242)"
                  />
                  {data.Computed ? (
                    <LockFill size={iconSize} />
                  ) : (
                    <PencilFill size={iconSize} />
                  )}
                </g> */}
                </EdgeLabelRenderer>
              ) : null}

              {data.SourceInterfaceLabel !== "" && displayInterface ? (
                <EdgeLabelRenderer>
                  <div
                    style={
                      sourcePos === "top"
                        ? {
                            transform: `translate(0%, -100%) translate(${sx}px,${sy}px)`,
                          }
                        : sourcePos === "right"
                        ? {
                            transform: `translate(0%, -75%) translate(${sx}px,${sy}px)`,
                          }
                        : sourcePos === "bottom"
                        ? {
                            transform: `translate(0%, 0%) translate(${sx}px,${sy}px)`,
                          }
                        : {
                            transform: `translate(-100%, -25%) translate(${sx}px,${sy}px)`,
                          }
                    }
                    className="text-[8px] text-left absolute transform p-2"
                  >
                    <div>Interface: {data.SourceInterfaceLabel}</div>
                  </div>
                </EdgeLabelRenderer>
              ) : null}
              {data.DestinationInterfaceLabel !== "" && displayInterface ? (
                <EdgeLabelRenderer>
                  <div
                    style={
                      targetPos === "top"
                        ? {
                            transform: `translate(0%, -100%) translate(${tx}px,${ty}px)`,
                          }
                        : targetPos === "right"
                        ? {
                            transform: `translate(0%, -25%) translate(${tx}px,${ty}px)`,
                          }
                        : targetPos === "bottom"
                        ? {
                            transform: `translate(0%, -25%) translate(${tx}px,${ty}px)`,
                          }
                        : {
                            transform: `translate(-100%, -25%) translate(${tx}px,${ty}px)`,
                          }
                    }
                    className="text-[8px] text-left absolute transform p-4"
                  >
                    <div>Interface: {data.DestinationInterfaceLabel}</div>
                  </div>
                </EdgeLabelRenderer>
              ) : null}
            </>
          )}
        </DisplayMapContext.Consumer>
      )}
    </>
  );
}

export default FloatingEdge;
