import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  ReactFlowProvider,
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
  Controls,
  MiniMap,
  Panel,
} from "reactflow";

import NoteNode from "../map/noteNodeObject";
import NetworkNode from "../map/networkNodeObject";
import GroupNode from "../map/groupNodeObject";
import TerminalNode from "../map/terminalNodeObject";
import FloatingEdge from "../map/connectionObject";
import { DisplayMapContext } from "../map/displayMapContext";
import { findEdge } from "../map/reactflowUtils";

import { nodeDisplayName } from "../misc/utils";

const nodeTypes = {
  networkNode: NetworkNode,
  core: NetworkNode,
  terminal: TerminalNode,
  note: NoteNode,
  group: GroupNode,
};

const edgeTypes = {
  floating: FloatingEdge,
};

const defaultEdgeOptions = {
  style: { strokeWidth: 1, stroke: "black" },
  type: "floating",
};

export default function DetailServiceMap({
  analysis,
  businessService,
  targetBusinessServicePaths,
}) {
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const map = JSON.parse(analysis.snapshot.map);

  const [nodes, setNodes, onNodesChange] = useNodesState(map.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(map.edges);

  const [isDraggable, setIsDraggable] = useState(false);
  const [isSelectable, setIsSelectable] = useState(true);
  const [isConnectable, setIsConnectable] = useState(false);
  const [zoomOnScroll, setZoomOnScroll] = useState(false);
  const [panOnScroll, setPanOnScroll] = useState(false);
  const [panOnScrollMode, setPanOnScrollMode] = useState("free");
  const [zoomOnDoubleClick, setZoomOnDoubleClick] = useState(false);
  const [panOnDrag, setpanOnDrag] = useState(true);
  const [captureZoomClick, setCaptureZoomClick] = useState(false);
  const [captureZoomScroll, setCaptureZoomScroll] = useState(false);
  const [captureElementClick, setCaptureElementClick] = useState(false);

  const [selectedPath, setSelectedPath] = useState("");
  const [mapDisplayValue, setMapDisplayValue] = useState({
    displayInterface: false,
    showAudit: true,
    attackPath: {},
  });

  const onChangeAttackPath = (event) => {
    const auditedPathIndex = parseInt(event.target.value.split("-")[0]);
    const bypassPathIndex = parseInt(event.target.value.split("-")[1]);

    const auditedPath = targetBusinessServicePaths[auditedPathIndex];
    const byPassPath =
      targetBusinessServicePaths[auditedPathIndex].bypass_routes[
        bypassPathIndex
      ];

    setMapDisplayValue({
      ...mapDisplayValue,
      attackPath: {
        sourceId: auditedPath.src_node,
        destinationId: auditedPath.dst_node,
      },
    });

    // const findEdge = (src, dst) => {
    //   return map.edges.find(
    //     (edge) =>
    //       (edge.source === src && edge.target === dst) ||
    //       (edge.source === dst && edge.target === src)
    //   );
    // };

    const animatedEdgeIds = byPassPath.detailed_route.reduce(
      (acc, route_chunk) => {
        for (var i = 0; i < route_chunk.length - 1; i++) {
          var src = route_chunk[i];
          var dst = route_chunk[i + 1];
          var edge = findEdge(map, src, dst);
          //TODO: some edges are undefined but this should not happen
          if (edge) {
            acc.push(edge.id);
          }
        }
        return acc;
      },
      []
    );

    const dstNodes = byPassPath.detailed_route.reduce((acc, route_chunk) => {
      route_chunk.map((nodeId) => {
        if (
          !acc.includes(nodeId) &&
          nodeId !== auditedPath.src_node &&
          nodeId !== auditedPath.dst_node
        ) {
          acc.push(nodeId);
        }
      });
      return acc;
    }, []);

    const targetEdges = edges.map((edge) =>
      animatedEdgeIds.includes(edge.id)
        ? {
            ...edge,
            animated: true,
          }
        : { ...edge, animated: false }
    );

    const targetNodes = nodes.map((node, index) =>
      dstNodes.includes(node.id) && node.id !== auditedPath.dst_node
        ? {
            ...node,
            data: {
              ...node.data,
              Pivot: true,
              StepTerminalNode: byPassPath.route.indexOf(node.id),
            },
          }
        : { ...node, data: { ...node.data, Pivot: false } }
    );
    setEdges(targetEdges);
    setNodes(targetNodes);
    setSelectedPath(event.target.value);
  };

  const nodeNameFromId = (nodeId) => {
    const node = map.nodes.find((node) => node.id === nodeId);

    return nodeDisplayName(node);
  };

  return (
    <div className="flex w-full h-full bg-white dndflow">
      <DisplayMapContext.Provider value={mapDisplayValue}>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            defaultEdgeOptions={defaultEdgeOptions}
            onDragOver={onDragOver}
            //onNodeClick={onNodeClick}
            //onEdgeClick={onEdgeClick}

            elementsSelectable={isSelectable}
            nodesConnectable={isConnectable}
            nodesDraggable={isDraggable}
            zoomOnScroll={zoomOnScroll}
            panOnScroll={panOnScroll}
            panOnScrollMode={panOnScrollMode}
            zoomOnDoubleClick={zoomOnDoubleClick}
            //onConnect={onConnect}
            //onNodeClick={captureElementClick ? onNodeClick : undefined}
            //onNodeDragStart={onNodeDragStart}
            //onNodeDragStop={onNodeDragStop}
            panOnDrag={panOnDrag}
            //onPaneClick={captureZoomClick ? onPaneClick : undefined}
            //onPaneScroll={captureZoomScroll ? onPaneScroll : undefined}
            //onPaneContextMenu={captureZoomClick ? onPaneContextMenu : undefined}

            className="bg-teal-50"
            fitView
          >
            <Background color="#99b3ec" variant="dots" />
            <Controls />

            <MiniMap nodeStrokeWidth={3} zoomable pannable />

            <Panel position="top-left">
              <div>
                <label className="text-sm font-medium">
                  Select the attack path you want to analyze
                </label>
              </div>
              <div>
                <label>
                  <select
                    id="attackPathSelect"
                    value={selectedPath}
                    onChange={(event) => onChangeAttackPath(event)}
                    className="border border-blue-medium mt-1 p-1 rounded-md w-64"
                  >
                    <option style={{ display: "none" }}></option>
                    {targetBusinessServicePaths.map(
                      (auditedPath, auditedPathIndex) =>
                        auditedPath.bypass_routes
                          .sort((r1, r2) => r1.route.length - r2.route.length)
                          .slice(0, 15)
                          .map((bypass_route, bypassPathIndex) => (
                            <option
                              key={auditedPathIndex + "-" + bypassPathIndex}
                              value={auditedPathIndex + "-" + bypassPathIndex}
                            >
                              {nodeNameFromId(auditedPath.src_node)} -{" "}
                              {nodeNameFromId(auditedPath.dst_node)} :{" "}
                              {bypassPathIndex}
                            </option>
                          ))
                    )}
                  </select>
                </label>
              </div>
              <div className="mt-4">
                <span className="rounded-md border-4 border-green-300 py-1 px-2 text-sm">
                  Origin
                </span>
                <span className="rounded-md border-4 border-blue-medium py-1 px-2 text-sm ml-2">
                  Destination
                </span>
                <span className="rounded-md border-4 border-red-300 py-1 px-2 text-sm ml-2 ">
                  Pivots
                </span>
              </div>
            </Panel>
          </ReactFlow>
        </ReactFlowProvider>
      </DisplayMapContext.Provider>
    </div>
  );
}
