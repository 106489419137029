import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function ConfirmDialog(question, onValidate) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          className="flex flex-col text-center bg-white border border-optistream-blue 
                        text-optistream-txt-default w-auto h-52 p-4 justify-evenly"
        >
          <h1 className="text-optistream-txt-blue font-bold text-lg uppercase">
            Are you sure?
          </h1>
          <p className="text-optistream-txt-default">{question}</p>
          <div className="flex flex-row w-full justify-evenly">
            <button
              className="w-44 p-2 bg-optistream-btn-alternative active:bg-btn-altClicked border text-optistream-txt-white m-2 cursor-pointer text-base"
              onClick={onClose}
            >
              No
            </button>
            <button
              className="w-44 p-2 bg-optistream-btn-default active:bg-optistream-btn-clicked rounded  font-bold border text-optistream-txt-white m-2 cursor-pointer text-base"
              onClick={() => {
                onValidate();
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      );
    },
  });
}
