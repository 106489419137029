import React, { useState, useEffect } from "react";
import colorLib from "@kurkle/color";
import * as Utils from "./../misc/utils";

import {
  Chart as ChartJS,
  ArcElement,
  Filler,
  Legend,
  Tooltip,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";

import { Radar } from "react-chartjs-2";
import { securityLevelIsCritical } from "./utils";

ChartJS.register(
  Filler,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  RadialLinearScale
);

export const CHART_COLORS = [
  "rgb(255, 99, 132)", // red
  "rgb(54, 162, 235)", // blue:
  "rgb(75, 192, 192)", // green:
  "rgb(255, 159, 64)", // orange:
  "rgb(255, 205, 86)", // yellow:
  "rgb(153, 102, 255)", // purple:
  "rgb(201, 203, 207)", // grey:
];

export function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

export default function WidgetBusinessDomainRadar({ auditResult }) {
  const dictNodeToBS = Utils.buildDstNodeToBusinessServicesDict(auditResult);

  const resPerBusinessDomain = {};
  auditResult["result"].forEach((path) => {
    const impactedBusinessServices = dictNodeToBS[path.dst_node];
    const impactedBusinessDomains = impactedBusinessServices.reduce(
      (acc, bs) => {
        acc.add(bs.business_domain);
        return acc;
      },
      new Set()
    );

    const pathScore = securityLevelIsCritical(path.security_level) ? 1 : 0;

    impactedBusinessDomains.forEach((bs) =>
      resPerBusinessDomain[bs]
        ? (resPerBusinessDomain[bs] = {
            score: resPerBusinessDomain[bs].score + pathScore,
            nbPath: resPerBusinessDomain[bs].nbPath + 1,
          })
        : (resPerBusinessDomain[bs] = {
            score: pathScore,
            nbPath: 1,
          })
    );
  });

  const radarLabel = Object.keys(resPerBusinessDomain);
  const radarScores = radarLabel.map(
    (domain) =>
      100 -
      (resPerBusinessDomain[domain].score /
        resPerBusinessDomain[domain].nbPath) *
        100
  );

  if (radarLabel.length < 3) {
    const radarLength = radarLabel.length;
    for (var i = 0; i < 3 - radarLength; i++) radarLabel.push("");
  }

  const data = {
    labels: radarLabel,
    datasets: [
      {
        label: "% secured paths / domain",
        data: radarScores,
        borderColor: CHART_COLORS[0],
        backgroundColor: transparentize(CHART_COLORS[0], 1),
      },
    ],
  };

  const config = {
    type: "radar",
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false, position: "bottom" },
        filler: {
          propagate: false,
        },
      },
      interaction: {
        intersect: false,
      },

      scales: {
        r: {
          min: 0,
          suggestedMax: 50,
          pointLabels: {
            color: "#005c98",
            font: { size: 14, weight: "bold" },
          },

          ticks: {
            stepSize: 25,
            font: {
              size: 14,
            },
          },
        },
      },
    },
  };
  return <Radar data={data} options={config.options} />;
}
