import React, { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import Spinner from "../../misc/spinner";
import { ClipboardX, Cloud } from "react-bootstrap-icons";

import { DateUtils, useAPI } from "../../../../utils";

export default function NodeOverlayTabRawConfig({
  editNode,
  setRecomputeMapWhenUpdate,
  setCurrentSnapshotStatus,
  workspaceId,
  snapshotId,
}) {
  const { getConfigFile, uploadConfigFile } = useAPI();
  const [configFile, setConfigFile] = useState(null);
  const [configFileLoaded, setConfigFileLoaded] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const alert = useAlert();

  const nodeIsComputed = useMemo(
    () => editNode.data.NodeComputed === true,
    [editNode]
  );

  const nodeIsAWS = useMemo(() => editNode.data.Vendor === "AWS", [editNode]);

  useEffect(() => {
    getConfigFile(workspaceId, snapshotId, editNode.id)
      .then(setConfigFile)
      .catch((e) => void e)
      .finally(() => setConfigFileLoaded(true));
  }, []);

  useEffect(() => {
    if (!fileToUpload) {
      return;
    }
    setCurrentSnapshotStatus("PROCESSING");

    uploadConfigFile(workspaceId, snapshotId, editNode.id, fileToUpload)
      .then((newConfigFile) => {
        setFileToUpload(null);

        if (newConfigFile === "") {
          alert.info("Configuration file unchanged");
          setCurrentSnapshotStatus("READY");
        } else {
          alert.success("Configuration uploaded !");
          setConfigFile(newConfigFile);
          setCurrentSnapshotStatus("PROCESSING");
          setRecomputeMapWhenUpdate(true);
        }
      })
      .catch((e) => setCurrentSnapshotStatus("READY"));
  }, [fileToUpload]);

  const handleConfigFileUpload = (e) => {
    if (e.target.files) {
      setFileToUpload(e.target.files[0]);
    }
  };

  return (
    <div className="flex flex-col text-gray-base w-full">
      <div className="flex flex-row justify-between items-center pb-2">
        <p class="grow">
          {configFile
            ? `Last updated: ${DateUtils.formatDate(configFile.created_at)}`
            : null}
        </p>
        {!nodeIsComputed ? (
          <label
            htmlFor="dropzone-file"
            className="flex flew-col text-gray-base 
                     inline-block items-center
                     cursor-pointer bg-white border border-dashed rounded 
                     border-gray-primary px-2 h-10"
          >
            <div className="text-sm text-gray-500">
              Click to update a configuration file
            </div>

            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleConfigFileUpload}
            />
          </label>
        ) : null}
      </div>
      <div className="grow">
        {!configFileLoaded ? (
          <div className="flex flex-row justify-center items-center w-full h-full">
            <Spinner size="16" />
          </div>
        ) : configFile ? (
          <div className="whitespace-pre-line bg-white font-mono h-full">
            {configFile.raw}
          </div>
        ) : nodeIsAWS ? (
          <div className="h-full flex flex-col items-center justify-center">
            <Cloud width={100} height={100} color="gray" className="m-4" />
            <p className="text-black-light">AWS specific configuration</p>
          </div>
        ) : (
          <div className="h-full flex flex-col items-center justify-center">
            <ClipboardX
              width={100}
              height={100}
              color="crimson"
              className="m-4"
            />
            <p className="text-black-light">No configuration file</p>
          </div>
        )}{" "}
      </div>
    </div>
  );
}
