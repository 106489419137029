import React, { useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { useMsal } from "@azure/msal-react";

export default function Logout() {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "https://optistream.io",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  useEffect(() => {
    handleLogout("popup");
  }, []);

  return null;
}
