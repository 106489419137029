import ConfirmDialog from "../../misc/confirmDialog";
import { useState, useMemo } from "react";
import Select from "react-select";
import { threatExposureLevels } from "../../misc/utils";

import NodeOverlayFormIPConfigurable from "./forms/nodeOverlayFormIPConfigurable";
import NodeOverlayFormISP from "./forms/nodeOverlayFormISP";

export default function NodeOverlayTabGeneral({
  editNode,
  setEditNode,
  deleteCurrentNode,
  setRecomputeMapWhenUpdate,
}) {
  const [displayName, setDisplayName] = useState(
    editNode.data.DisplayName || ""
  );

  const isIPConfigurable = useMemo(() => {
    return (
      editNode.data.EquipmentType === "Server" ||
      editNode.data.EquipmentType === "PC"
    );
  }, [editNode]);

  return (
    <div className="flex flex-col text-gray-base w-full">
      <div className="grow">
        <div className="flex flex-row h-full space-x-1">
          <div className="w-1/2">
            <fieldset className="border border-gray-100 p-2 h-full">
              <legend className="font-bold">General information</legend>
              <table className="w-full table-fixed border-separate border-spacing-y-2">
                <tr>
                  <td>
                    <label htmlFor="hostname" className="text-gray-base">
                      Hostname:
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="hostname"
                      disabled={true}
                      value={editNode.data.HostName}
                      className="text-sm text-gray-base w-full p-2 border border-gray-primary rounded disabled:bg-gray-200"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="displayname" className="text-gray-base">
                      Display name:
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="displayname"
                      value={displayName}
                      onChange={(evt) => {
                        setDisplayName(evt.target.value);
                      }}
                      onBlur={() => {
                        if (displayName === editNode.data.DisplayName) {
                          return;
                        }
                        setEditNode({
                          ...editNode,
                          data: {
                            ...editNode.data,
                            DisplayName: displayName,
                          },
                        });
                      }}
                      className="text-sm text-gray-base w-full p-2 border border-gray-primary rounded disabled:bg-gray-200"
                    />
                  </td>
                </tr>
                {process.env && process.env.NODE_ENV === "development" ? (
                  <tr>
                    <td>
                      <label htmlFor="nodeid" className="text-gray-base">
                        Node ID (debug):
                      </label>
                    </td>
                    <td>
                      <input
                        type="text"
                        id="nodeid"
                        value={editNode.id}
                        disabled={true}
                        className="text-sm text-gray-base w-full p-2 border border-gray-primary rounded disabled:bg-gray-200"
                      />
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td>
                    <label htmlFor="displayname" className="text-gray-base">
                      Type:
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="displayname"
                      disabled={true}
                      value={editNode.data.EquipmentType}
                      className="text-sm text-gray-base w-full p-2 border border-gray-primary rounded disabled:bg-gray-200"
                    />
                  </td>
                </tr>
                <tr className={editNode.type === "terminal" ? "" : "hidden"}>
                  <td>
                    <label htmlFor="exposureLevel" className="text-gray-base">
                      Exposure level:
                    </label>{" "}
                    <button className="h-6 w-6 rounded-full bg-optistream-orange italic text-optistream-txt-white shadow-sm peer">
                      i
                    </button>
                    <span className="absolute hidden w-96 rounded bg-gray-800 p-2 text-sm text-optistream-txt-white peer-hover:block z-10 delay-300">
                      <ul className="marker:text-sky-400 list-disc pl-5 space-y-1 text-slate-50">
                        <li>
                          <span className="font-bold">Public exposure:</span>{" "}
                          component exposed on internet or accessible to guest
                        </li>
                        <li>
                          <span className="font-bold">Strong exposure:</span>{" "}
                          component in a DMZ or accessible to most employees
                        </li>
                        <li>
                          <span className="font-bold">Contained exposure:</span>{" "}
                          business / technical component with limited interfaces
                          & strong access controls
                        </li>
                        <li>
                          <span className="font-bold">Limited exposure:</span>{" "}
                          technical component only accessible from a very
                          limited set of users
                        </li>
                      </ul>
                    </span>
                  </td>
                  <td>
                    <Select
                      id="exposureLevel"
                      isClearable={true}
                      options={threatExposureLevels}
                      className="text-sm"
                      value={{
                        label: editNode.data.ExposureLevel,
                        value: editNode.data.ExposureLevel,
                      }}
                      onChange={(selectedOption) => {
                        if (
                          selectedOption !== null &&
                          selectedOption.value === editNode.data.ExposureLevel
                        ) {
                          return;
                        }

                        setEditNode({
                          ...editNode,
                          data: {
                            ...editNode.data,
                            ExposureLevel: selectedOption
                              ? selectedOption.value
                              : null,
                          },
                        });
                      }}
                    />
                  </td>
                </tr>
              </table>
            </fieldset>
          </div>
          <div className="w-1/2">
            {isIPConfigurable ? (
              <NodeOverlayFormIPConfigurable
                editNode={editNode}
                setEditNode={setEditNode}
                setRecomputeMapWhenUpdate={setRecomputeMapWhenUpdate}
              />
            ) : editNode.data.EquipmentType === "isp" ? (
              <NodeOverlayFormISP
                editNode={editNode}
                setEditNode={setEditNode}
                setRecomputeMapWhenUpdate={setRecomputeMapWhenUpdate}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center pt-2">
        <button
          className="mt-2 bg-red-primary text-optistream-txt-white rounded h-8 font-bold text-sm w-24 disabled:opacity-50"
          disabled={editNode.data.NodeComputed === true}
          title={
            editNode.data.NodeComputed === true
              ? "This node was automatically imported and cannot be deleted"
              : null
          }
          onClick={() =>
            ConfirmDialog(
              "You are about to definitely delete your element.",
              deleteCurrentNode
            )
          }
        >
          Delete
        </button>
      </div>
    </div>
  );
}
