import React, { useEffect, useState } from "react";

export default function NodeOverlayTabNotes({ editNode, setEditNode }) {
  const [userNotes, setUserNotes] = useState(editNode.data.userNotes);

  return (
    <div className="flex flex-col text-gray-base w-full">
      <textarea
        className="whitespace-pre-line bg-white font-mono grow my-1"
        value={userNotes}
        onChange={(evt) => {
          setUserNotes(evt.target.value);
        }}
        onBlur={() => {
          if (userNotes === editNode.data.userNotes) return;

          setEditNode({
            ...editNode,
            data: {
              ...editNode.data,
              userNotes: userNotes,
            },
          });
        }}
      />
    </div>
  );
}
