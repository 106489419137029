import React, { useState, useEffect, useMemo, useCallback } from "react";
import Spinner from "./components/misc/spinner";
import { useAPI } from "../utils";

import { ChevronDoubleUp, BookmarkX, Hourglass } from "react-bootstrap-icons";

import WidgetScoreChartForCockpit from "./components/misc/charts";

const MemoizedWidgetScoreChartForCockpit = React.memo(
  WidgetScoreChartForCockpit,
  (prevProps, nextProps) => {
    return prevProps.valueScore === nextProps.valueScore;
  }
);

export default function BusinessCockpitPage() {
  const [analyses, setAnalyses] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [businessServices, setBusinessServices] = useState({});
  const [applications, setApplications] = useState({});
  const [expandedDomains, setExpandedDomains] = useState([]);
  const [expandedBusinessServices, setExpandedBusinessServices] = useState({});

  const { listAnalyses, listBusinessServices, listApplications } = useAPI();

  useEffect(() => {
    listAnalyses()
      .then(setAnalyses)
      .catch((e) => void e);
  }, []);

  const handleWorkspaceChange = useCallback((event) => {
    setSelectedWorkspace(event.target.value);
    setIsLoading(true);
  }, []);
  const handleBusinessServiceToggle = (businessServiceId) => {
    setExpandedBusinessServices((prevExpandedBusinessServices) => ({
      ...prevExpandedBusinessServices,
      [businessServiceId]: !prevExpandedBusinessServices[businessServiceId],
    }));
  };

  const workspaceOptions = useMemo(() => {
    const options = analyses.reduce((acc, analysis) => {
      if (!acc.includes(analysis.snapshot.workspace.name)) {
        acc.push(analysis.snapshot.workspace.name);
      }
      return acc;
    }, []);
    return options;
  }, [analyses]);
  useEffect(() => {
    if (workspaceOptions.length > 0) {
      setSelectedWorkspace(workspaceOptions[0]);
    }
  }, [workspaceOptions]);

  const filteredAnalyses = useMemo(() => {
    return analyses.filter(
      (analysis) => analysis.snapshot.workspace.name === selectedWorkspace
    );
  }, [analyses, selectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace !== "") {
      setIsLoading(true);
      const lastLateralizationAnalysis = filteredAnalyses.find(
        (analysis) =>
          analysis.type === "lateralization" && analysis.status === "READY"
      );
      const lastHighRiskRoutesAnalysis = filteredAnalyses.find(
        (analysis) =>
          analysis.type === "high_risk_routes" && analysis.status === "READY"
      );

      const lastSecurityPolicyComplianceAnalysis = filteredAnalyses.find(
        (analysis) =>
          analysis.type === "security_policy_compliance" &&
          analysis.status === "READY"
      );

      const allBusinessServiceIds = new Set();
      const allApplicationPromises = [];

      [
        lastHighRiskRoutesAnalysis,
        lastLateralizationAnalysis,
        lastSecurityPolicyComplianceAnalysis,
      ].forEach((analysis) => {
        if (analysis) {
          const businessServiceIds = Object.values(
            JSON.parse(analysis.kpis).domains
          )
            .map((domain) => Object.keys(domain.business_services))
            .flat();

          businessServiceIds.forEach((businessServiceId) => {
            allBusinessServiceIds.add(businessServiceId);
          });

          const businessServiceIdsToFetchApplications = Object.values(
            JSON.parse(analysis.kpis).domains
          )
            .map((domain) => Object.keys(domain.business_services))
            .flat();

          businessServiceIdsToFetchApplications.forEach((businessServiceId) => {
            allApplicationPromises.push(
              listApplications(businessServiceId)
                .then((applications) => {
                  return applications;
                })
                .catch((e) => void e)
            );
          });
        }
      });

      listBusinessServices(Array.from(allBusinessServiceIds))
        .then((data) => {
          const businessServicesMap = data.reduce((acc, businessService) => {
            acc[businessService.id] = businessService;
            return acc;
          }, {});
          setBusinessServices(businessServicesMap);
        })
        .catch((e) => void e);

      Promise.all(allApplicationPromises)
        .then((applicationDataArrays) => {
          const allApplications = applicationDataArrays.flat();
          const applicationsMap = allApplications.reduce((acc, application) => {
            acc[application.id] = application;
            return acc;
          }, {});
          setApplications(applicationsMap);
        })
        .catch((e) => void e);
      setIsLoading(false);
    }
  }, [filteredAnalyses, selectedWorkspace]);

  const businessDomains = useMemo(() => {
    const allDomains = new Set();

    const addDomains = (kpis) => {
      if (kpis) {
        Object.keys(kpis.domains).forEach((domain) => {
          allDomains.add(domain);
        });
      }
    };

    filteredAnalyses.forEach((analysis) => {
      if (analysis.status === "READY") {
        const kpis = JSON.parse(analysis.kpis);
        addDomains(kpis);
      }
    });

    return Array.from(allDomains);
  }, [filteredAnalyses]);

  const handleDomainToggle = useCallback((domain) => {
    setExpandedDomains((prevExpandedDomains) => {
      if (prevExpandedDomains.includes(domain)) {
        return prevExpandedDomains.filter((d) => d !== domain);
      } else {
        return [...prevExpandedDomains, domain];
      }
    });
  }, []);

  const getDomainRow = (domain) => {
    const lastHighRiskRoutesAnalysis = filteredAnalyses.find(
      (analysis) => analysis.type === "high_risk_routes"
    );
    const lastLateralizationAnalysis = filteredAnalyses.find(
      (analysis) => analysis.type === "lateralization"
    );

    const lastSecurityPolicyComplianceAnalysis = filteredAnalyses.find(
      (analysis) => analysis.type === "security_policy_compliance"
    );

    const domainData = {
      high_risk_routes: lastHighRiskRoutesAnalysis
        ? JSON.parse(lastHighRiskRoutesAnalysis.kpis)?.domains[domain]
        : {},
      lateralization: lastLateralizationAnalysis
        ? JSON.parse(lastLateralizationAnalysis.kpis)?.domains[domain]
        : {},
      security_policy_compliance: lastSecurityPolicyComplianceAnalysis
        ? JSON.parse(lastSecurityPolicyComplianceAnalysis.kpis)?.domains[domain]
        : {},
    };

    const businessServicesData = {
      high_risk_routes: domainData.high_risk_routes?.business_services || {},
      lateralization: domainData.lateralization?.business_services || {},
      security_policy_compliance:
        domainData.security_policy_compliance?.business_services || {},
    };
    const businessServicesDataCombined = {
      ...businessServicesData.high_risk_routes,
      ...businessServicesData.lateralization,
      ...businessServicesData.security_policy_compliance,
    };
    const isHighRiskRoutesPending =
      (lastHighRiskRoutesAnalysis &&
        lastHighRiskRoutesAnalysis.status === "PROCESSING") ||
      (lastHighRiskRoutesAnalysis &&
        lastHighRiskRoutesAnalysis.status === "QUEUED");

    const isLateralizationPending =
      (lastLateralizationAnalysis &&
        lastLateralizationAnalysis.status === "PROCESSING") ||
      (lastLateralizationAnalysis &&
        lastLateralizationAnalysis.status === "QUEUED");

    const isSecurityPolicyCompliancePending =
      (lastSecurityPolicyComplianceAnalysis &&
        lastSecurityPolicyComplianceAnalysis.status === "PROCESSING") ||
      (lastSecurityPolicyComplianceAnalysis &&
        lastSecurityPolicyComplianceAnalysis.status === "QUEUEDs");

    return (
      <React.Fragment>
        <tr className="bg-blue-50">
          <td className="indent-2">{domain}</td>
          {lastHighRiskRoutesAnalysis &&
            lastHighRiskRoutesAnalysis.status === "READY" && (
              <td className="text-center">
                <div className="inline-block">
                  {JSON.parse(lastHighRiskRoutesAnalysis.kpis).domains[
                    domain
                  ] &&
                  JSON.parse(lastHighRiskRoutesAnalysis.kpis).domains[domain]
                    .score !== undefined ? (
                    <MemoizedWidgetScoreChartForCockpit
                      valueScore={Math.round(
                        JSON.parse(lastHighRiskRoutesAnalysis.kpis).domains[
                          domain
                        ].score * 100
                      )}
                    />
                  ) : (
                    <BookmarkX />
                  )}
                </div>
              </td>
            )}
          {isHighRiskRoutesPending && (
            <td>
              <Hourglass />
            </td>
          )}
          {!lastHighRiskRoutesAnalysis && (
            <td className="text-center">
              <div className="inline-block">
                <BookmarkX />
              </div>
            </td>
          )}
          {lastLateralizationAnalysis &&
            lastLateralizationAnalysis.status === "READY" && (
              <td className="text-center">
                <div className="inline-block">
                  {JSON.parse(lastLateralizationAnalysis.kpis).domains[
                    domain
                  ] &&
                  JSON.parse(lastLateralizationAnalysis.kpis).domains[domain]
                    .score !== undefined ? (
                    <MemoizedWidgetScoreChartForCockpit
                      valueScore={Math.round(
                        JSON.parse(lastLateralizationAnalysis.kpis).domains[
                          domain
                        ].score * 100
                      )}
                    />
                  ) : (
                    <BookmarkX />
                  )}
                </div>
              </td>
            )}
          {isLateralizationPending && (
            <td>
              <Hourglass />
            </td>
          )}
          {!lastLateralizationAnalysis && (
            <td className="text-center">
              <div className="inline-block">
                <BookmarkX />
              </div>
            </td>
          )}
          {lastSecurityPolicyComplianceAnalysis &&
            lastSecurityPolicyComplianceAnalysis.status === "READY" && (
              <td className="text-center">
                <div className="inline-block">
                  {JSON.parse(lastSecurityPolicyComplianceAnalysis.kpis)
                    .domains[domain] &&
                  JSON.parse(lastSecurityPolicyComplianceAnalysis.kpis).domains[
                    domain
                  ].score !== undefined ? (
                    <MemoizedWidgetScoreChartForCockpit
                      valueScore={Math.round(
                        JSON.parse(lastSecurityPolicyComplianceAnalysis.kpis)
                          .domains[domain].score * 100
                      )}
                    />
                  ) : (
                    <BookmarkX />
                  )}
                </div>
              </td>
            )}
          {isSecurityPolicyCompliancePending && (
            <td>
              <Hourglass />
            </td>
          )}
          {!lastSecurityPolicyComplianceAnalysis && (
            <td className="text-center">
              <div className="inline-block">
                <BookmarkX />
              </div>
            </td>
          )}
          <td>
            {!isHighRiskRoutesPending &&
              !isLateralizationPending &&
              !isSecurityPolicyCompliancePending && (
                <button
                  onClick={() => handleDomainToggle(domain)}
                  className="float-right mr-2.5"
                >
                  {expandedDomains.includes(domain) ? (
                    <ChevronDoubleUp />
                  ) : (
                    <ChevronDoubleUp className="rotate-180" />
                  )}
                </button>
              )}
          </td>
        </tr>
        {expandedDomains.includes(domain) &&
          Object.entries(businessServicesDataCombined).map(
            ([businessServiceId, businessServiceData]) => {
              const businessServiceName =
                businessServices[businessServiceId]?.name || "";

              return (
                <React.Fragment key={businessServiceId}>
                  <tr
                    style={{
                      backgroundColor: "#f7f7f7",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <td className="indent-4">{businessServiceName}</td>
                    {lastHighRiskRoutesAnalysis && (
                      <td className="text-center">
                        <div className="inline-block">
                          {" "}
                          {JSON.parse(lastHighRiskRoutesAnalysis.kpis)?.domains[
                            domain
                          ]?.business_services[businessServiceId]?.score !==
                          undefined ? (
                            <MemoizedWidgetScoreChartForCockpit
                              valueScore={Math.round(
                                JSON.parse(lastHighRiskRoutesAnalysis.kpis)
                                  ?.domains[domain].business_services[
                                  businessServiceId
                                ].score * 100
                              )}
                            />
                          ) : (
                            <BookmarkX />
                          )}
                        </div>
                      </td>
                    )}
                    {!lastHighRiskRoutesAnalysis && (
                      <td className="text-center">
                        <div className="inline-block">
                          <BookmarkX />
                        </div>
                      </td>
                    )}
                    {lastLateralizationAnalysis && (
                      <td className="text-center">
                        <div className="inline-block">
                          {" "}
                          {JSON.parse(lastLateralizationAnalysis.kpis)?.domains[
                            domain
                          ]?.business_services[businessServiceId]?.score !==
                          undefined ? (
                            <MemoizedWidgetScoreChartForCockpit
                              valueScore={Math.round(
                                JSON.parse(lastLateralizationAnalysis.kpis)
                                  ?.domains[domain].business_services[
                                  businessServiceId
                                ].score * 100
                              )}
                            />
                          ) : (
                            <BookmarkX />
                          )}
                        </div>
                      </td>
                    )}
                    {!lastLateralizationAnalysis && (
                      <td className="text-center">
                        <div className="inline-block">
                          <BookmarkX />
                        </div>
                      </td>
                    )}
                    {lastSecurityPolicyComplianceAnalysis && (
                      <td className="text-center">
                        <div className="inline-block">
                          {" "}
                          {JSON.parse(lastSecurityPolicyComplianceAnalysis.kpis)
                            ?.domains[domain]?.business_services[
                            businessServiceId
                          ]?.score !== undefined ? (
                            <MemoizedWidgetScoreChartForCockpit
                              valueScore={Math.round(
                                JSON.parse(
                                  lastSecurityPolicyComplianceAnalysis.kpis
                                )?.domains[domain].business_services[
                                  businessServiceId
                                ].score * 100
                              )}
                            />
                          ) : (
                            <BookmarkX />
                          )}
                        </div>
                      </td>
                    )}

                    {!lastSecurityPolicyComplianceAnalysis && (
                      <td className="text-center">
                        <div className="inline-block">
                          <BookmarkX />
                        </div>
                      </td>
                    )}
                    <td className="text-right">
                      <button
                        onClick={() =>
                          handleBusinessServiceToggle(businessServiceId)
                        }
                        className="float-right mr-2.5"
                      >
                        {expandedBusinessServices[businessServiceId] ? (
                          <ChevronDoubleUp />
                        ) : (
                          <ChevronDoubleUp className="rotate-180" />
                        )}
                      </button>
                    </td>
                  </tr>
                  {expandedBusinessServices[businessServiceId] &&
                    Object.entries(businessServiceData.applications).map(
                      ([applicationId, applicationData]) => {
                        const applicationName =
                          applications[applicationId]?.name || "";

                        return (
                          <tr key={applicationId}>
                            <td className="indent-6">{applicationName}</td>
                            {lastHighRiskRoutesAnalysis && (
                              <td className="text-center">
                                <div className="inline-block">
                                  {" "}
                                  {JSON.parse(lastHighRiskRoutesAnalysis.kpis)
                                    ?.domains[domain]?.business_services[
                                    businessServiceId
                                  ]?.applications[applicationId]?.score !==
                                  undefined ? (
                                    <MemoizedWidgetScoreChartForCockpit
                                      valueScore={Math.round(
                                        JSON.parse(
                                          lastHighRiskRoutesAnalysis.kpis
                                        )?.domains[domain].business_services[
                                          businessServiceId
                                        ].applications[applicationId].score *
                                          100
                                      )}
                                    />
                                  ) : (
                                    <BookmarkX />
                                  )}
                                </div>
                              </td>
                            )}
                            {!lastHighRiskRoutesAnalysis && (
                              <td className="text-center">
                                <div className="inline-block">
                                  <BookmarkX />
                                </div>
                              </td>
                            )}
                            {lastLateralizationAnalysis && (
                              <td className="text-center">
                                <div className="inline-block">
                                  {" "}
                                  {JSON.parse(lastLateralizationAnalysis.kpis)
                                    ?.domains[domain]?.business_services[
                                    businessServiceId
                                  ]?.applications[applicationId]?.score !==
                                  undefined ? (
                                    <MemoizedWidgetScoreChartForCockpit
                                      valueScore={Math.round(
                                        JSON.parse(
                                          lastLateralizationAnalysis.kpis
                                        )?.domains[domain].business_services[
                                          businessServiceId
                                        ].applications[applicationId].score *
                                          100
                                      )}
                                    />
                                  ) : (
                                    <BookmarkX />
                                  )}
                                </div>
                              </td>
                            )}
                            {!lastLateralizationAnalysis && (
                              <td className="text-center">
                                <div className="inline-block">
                                  <BookmarkX />
                                </div>
                              </td>
                            )}
                            {lastSecurityPolicyComplianceAnalysis && (
                              <td className="text-center">
                                <div className="inline-block">
                                  {" "}
                                  {JSON.parse(
                                    lastSecurityPolicyComplianceAnalysis.kpis
                                  )?.domains[domain]?.business_services[
                                    businessServiceId
                                  ]?.applications[applicationId]?.score !==
                                  undefined ? (
                                    <MemoizedWidgetScoreChartForCockpit
                                      valueScore={Math.round(
                                        JSON.parse(
                                          lastSecurityPolicyComplianceAnalysis.kpis
                                        )?.domains[domain].business_services[
                                          businessServiceId
                                        ].applications[applicationId].score *
                                          100
                                      )}
                                    />
                                  ) : (
                                    <BookmarkX />
                                  )}
                                </div>
                              </td>
                            )}
                            {!lastSecurityPolicyComplianceAnalysis && (
                              <td className="text-center">
                                <div className="inline-block">
                                  <BookmarkX />
                                </div>
                              </td>
                            )}
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                </React.Fragment>
              );
            }
          )}
      </React.Fragment>
    );
  };
  const lastHighRiskRoutesAnalysis = filteredAnalyses.find(
    (analysis) => analysis.type === "high_risk_routes"
  );
  const lastLateralizationAnalysis = filteredAnalyses.find(
    (analysis) => analysis.type === "lateralization"
  );

  const lastSecurityPolicyComplianceAnalysis = filteredAnalyses.find(
    (analysis) => analysis.type === "security_policy_compliance"
  );

  const highRiskRoutesGlobalScore = lastHighRiskRoutesAnalysis
    ? JSON.parse(lastHighRiskRoutesAnalysis.kpis).score
    : undefined;
  const lateralizationGlobalScore = lastLateralizationAnalysis
    ? JSON.parse(lastLateralizationAnalysis.kpis).score
    : undefined;
  const securityPolicyComplianceGlobalScore =
    lastSecurityPolicyComplianceAnalysis
      ? JSON.parse(lastSecurityPolicyComplianceAnalysis.kpis).score
      : undefined;
  return (
    <div className="flex flex-col h-screen bg-gray-background w-full p-12 justify-center">
      <div className="flex flex-col h-full w-full justify-start items-start bg-white p-4">
        <div className="flex p-2 text-optistream-txt-blue font-bold text-base uppercase ">
          Business Security Cockpit
        </div>
        <div className="py-4 px-2 text-base text-optistream-txt-default">
          <label className="font-medium mb-2 block">Select Workspace:</label>
          <select
            id="workspaceFilter"
            value={selectedWorkspace}
            onChange={handleWorkspaceChange}
            className="border border-optistream-gray text-optistream-txt-blue text-base font-normal rounded-lg focus:ring-optistream-blue focus:border-optistream-blue block w-full p-2.5"
          >
            {workspaceOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {isLoading && (
          <div className="flex justify-center items-center h-full">
            <Spinner />
          </div>
        )}
        {selectedWorkspace && !isLoading && (
          <div className="flex w-full justify-center overflow-auto pt-6">
            <table className="border-collapse border-b border-optistream-orange text-optistream-txt-blue text-base font-normal pl-4 table-fixed">
              <thead>
                <tr className="border-y border-optistream-orange">
                  <th className="w-72 py-2 pr-4">Analysis</th>
                  <th className="w-60 pr-4">Segmentation porosity</th>
                  <th className="w-44 pr-4">In-depth infiltration</th>
                  <th className="w-42 pr-4">Security policy compliance</th>
                  <th className="w-32 pr-4"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-blue-100">
                  <td className="text-bold indent-1 font-bold">
                    Workspace Security Level
                  </td>
                  <td className="text-center">
                    <div className="inline-block">
                      {highRiskRoutesGlobalScore !== undefined ? (
                        <MemoizedWidgetScoreChartForCockpit
                          valueScore={Math.round(
                            highRiskRoutesGlobalScore * 100
                          )}
                        />
                      ) : (
                        <BookmarkX />
                      )}
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="inline-block">
                      {lateralizationGlobalScore !== undefined ? (
                        <MemoizedWidgetScoreChartForCockpit
                          valueScore={Math.round(
                            lateralizationGlobalScore * 100
                          )}
                        />
                      ) : (
                        <BookmarkX />
                      )}
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="inline-block">
                      {securityPolicyComplianceGlobalScore !== undefined ? (
                        <MemoizedWidgetScoreChartForCockpit
                          valueScore={Math.round(
                            securityPolicyComplianceGlobalScore * 100
                          )}
                        />
                      ) : (
                        <BookmarkX />
                      )}
                    </div>
                  </td>
                  <td></td>
                </tr>

                {businessDomains.length > 0 ? (
                  businessDomains.map((domain, index) => (
                    <React.Fragment key={index}>
                      {getDomainRow(domain)}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center p-4">
                      <Spinner />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
