import React, { useState } from "react";
import * as Modal from "../Modal/modal";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useAlert } from "react-alert";
import { useAPI } from "../../../utils";

export default function UploadZIPConfig({
  workspaceId,
  snapshotId,
  setCurrentSnapshotStatus,
  onCloseCallback,
  importType,
}) {
  const alert = useAlert();
  const [fileToUpload, setFileToUpload] = useState(null);
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileToUpload(e.target.files[0]);
    }
  };

  const { uploadFile } = useAPI();

  const doUploadFile = () => {
    if (!fileToUpload) {
      return;
    }
    uploadFile(workspaceId, snapshotId, fileToUpload, importType)
      .then((data) => {
        setFileToUpload(null);
        setCurrentSnapshotStatus(data.snapshot.status);
        if (data.modified === true) {
          alert.success("File successfully imported!");
        } else {
          alert.info("Configuration unchanged");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          alert.error(error.response.data.error);
        } else {
          alert.error("An error occurred while uploading the file");
        }
      });
  };

  return (
    <Modal.Frame
      onClose={() => {
        onCloseCallback();
      }}
    >
      <Modal.Head>
        {importType === "aws"
          ? "AWS import: config file"
          : importType === "csv"
          ? "CSV import: config file"
          : "Bulk import: config file"}
      </Modal.Head>
      <Modal.Body>
        <div className="flex flex-col text-center text-black-light w-auto h-5/6 p-4 justify-evenly items-center">
          <h1 className="text-optistream-txt-blue font-bold text-lg uppercase">
            Select a file containing configuration files
          </h1>
          <label
            htmlFor="file"
            className="flex flex-col text-gray-base 
                            inline-block items-center justify-center w-1/2
                            cursor-pointer bg-white border border-dashed rounded 
                            border-gray-primary hover:bg-gray-100 px-2 h-10"
          >
            <div className="text-sm text-gray-500 ">
              {fileToUpload ? fileToUpload.name : "Click to select your file"}
            </div>

            <input
              id="file"
              type="file"
              className="hidden"
              accept={importType === "csv" ? ".csv" : ".zip"}
              onChange={handleFileChange}
            />
          </label>
          <div className="flex flex-row w-full justify-evenly">
            <button
              className="w-44 p-2 bg-optistream-btn-alternative active:bg-btn-altClicked border text-optistream-txt-white m-2 cursor-pointer text-base"
              onClick={() => {
                setFileToUpload(null);
                onCloseCallback();
              }}
            >
              Discard
            </button>
            <button
              disabled={!fileToUpload}
              className={`w-44 p-2 bg-optistream-btn-default active:bg-optistream-btn-clicked rounded  font-bold border 
                        text-optistream-txt-white m-2 cursor-pointer text-base ${
                          !fileToUpload && "opacity-50"
                        }`}
              onClick={() => {
                doUploadFile();
                onCloseCallback();
              }}
            >
              Upload configs
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal.Frame>
  );
}
