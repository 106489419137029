import React, { useState, useEffect } from "react";
import * as Utils from "./../misc/utils";
import * as ROUTES from "../../../constants/routes";
import { useSearchParams, useNavigate, Link } from "react-router-dom";

export default function WidgetBusinessProcessOverview({
  auditResult,
  currentAnalysis,
}) {
  const groupedBSPerDomain = auditResult.business_services.reduce(
    (acc, businessService) => {
      businessService.business_domain
        ? (acc[businessService.business_domain] =
            acc[businessService.business_domain] || []).push(businessService)
        : (acc[""] = acc[""] || []).push(businessService);

      return acc;
    },
    {}
  );

  const dictNodeToBS = Utils.buildDstNodeToBusinessServicesDict(auditResult);
  const resPerBusinessService = {};
  auditResult["result"].forEach((path) => {
    const targets = dictNodeToBS[path.dst_node];
    targets.forEach((target) => {
      resPerBusinessService[target.id] !== undefined
        ? (resPerBusinessService[target.id] = {
            path_analyzed: resPerBusinessService[target.id].path_analyzed + 1,
            path_with_pivots:
              resPerBusinessService[target.id].path_with_pivots +
              (path.nb_weaker_alternate_routes ? 1 : 0),
          })
        : (resPerBusinessService[target.id] = {
            path_analyzed: 1,
            path_with_pivots: path.nb_weaker_alternate_routes ? 1 : 0,
          });
    });
  });

  const navigate = useNavigate();
  return (
    <table className="text-sm text-left text-black-light w-full h-full">
      <thead className="text-optistream-txt-blue w-full bg-gray-background uppercase">
        <tr className="flex w-full items-center text-xs">
          <th scope="col" className="px-2 py-2 flex w-3/12">
            Business Process
          </th>
          <th scope="col" className="px-2 py-2 flex w-1/12 justify-center">
            Sensitivity
          </th>

          <th scope="col" className="px-2 py-2 flex w-2/12 justify-center">
            # Apps
          </th>
          <th scope="col" className="px-2 py-2 flex w-2/12 justify-center">
            # Paths analyzed
          </th>
          <th scope="col" className="px-2 py-2 flex w-2/12 justify-center">
            % secured paths
          </th>
          <th scope="col" className="px-2 py-2 flex w-2/12 justify-center">
            See more
          </th>
        </tr>
      </thead>
      <tbody className=" w-full">
        {groupedBSPerDomain &&
          Object.keys(groupedBSPerDomain).map((domain, index) => (
            <>
              <tr
                className="border-b h-8 bg-optistream-orangeFaded
                            flex flex-row w-full items-center font-normal mt-2"
              >
                <th scope="row" className="font-bold px-2 py-1 flex w-full">
                  <div>Business domain: {domain !== "" ? domain : "N/A"}</div>
                </th>
              </tr>
              {groupedBSPerDomain[domain].map((service, index) => (
                <tr
                  className="border-b h-8 hover:bg-blue-faded-medium
                            flex flex-row w-full items-center font-normal"
                >
                  <th scope="row" className="font-medium px-2 py-1 flex w-3/12">
                    <div>{service.name}</div>
                  </th>

                  <th className="px-2 flex w-1/12 justify-center">
                    {Utils.businessSensitivityLevels[service.sensitivity].label}
                  </th>
                  <th className="text-center flex w-2/12 justify-center">
                    <div>{service.apps.length}</div>
                  </th>
                  <th className="text-center flex w-2/12 justify-center">
                    {resPerBusinessService[service.id] &&
                      resPerBusinessService[service.id].path_analyzed}
                  </th>
                  <th className="text-center flex w-2/12 justify-center">
                    {resPerBusinessService[service.id] &&
                      Math.round(
                        100 -
                          (resPerBusinessService[service.id].path_with_pivots /
                            resPerBusinessService[service.id].path_analyzed) *
                            100
                      )}{" "}
                    %
                  </th>

                  <th className="text-center flex w-2/12 justify-center">
                    <button
                      type="button"
                      onClick={() =>
                        navigate(
                          `${ROUTES.INFILTRATION_DETAILS}/${currentAnalysis.id}?procid=${service.id}`
                        )
                      }
                      className="bg-optistream-btn-default active:bg-optistream-btn-clicked h-6 w-16
                                                                            text-optistream-txt-white rounded font-bold"
                    >
                      Load
                    </button>
                  </th>
                </tr>
              ))}
            </>
          ))}
      </tbody>
    </table>
  );
}
