import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import WidgetScoreChart from "./widgetScoreChart";
import WidgetScoreHistory from "./widgetScoreHistory";
import WidgetBusinessDomainRadar from "./widgetBusinessDomainRadar";
import WidgetBusinessProcessOverview from "./widgetBusinessProcessOverview";

import * as Utils from "../misc/utils";
import Spinner from "../misc/spinner";

export default function InfiltrationCore({
  currentAnalysis,
  businessServices,
  scoreHistory,
}) {
  const parsedAnalysisResult = JSON.parse(currentAnalysis.result);

  return (
    <div className="flex flex-row h-full w-full">
      {/* Left section */}
      <div className="flex flex-col p-4 w-96  ">
        {/* GLOBAL SCORE */}
        <div className="flex flex-col justify-center items-center mt-2 w-full">
          <div className="text-optistream-txt-blue font-bold text-base uppercase ">
            Global score
          </div>
          <div className="flex flex-col p-4">
            <div className="flex flex-col items-center w-56 ">
              <WidgetScoreChart
                valueScore={Utils.computeNbSecuredPaths(parsedAnalysisResult)}
              />
              <div className="text-optistream-txt-blue text-sm mt-4">
                Waterproof rate (%)
              </div>
            </div>
          </div>
        </div>

        {/* DOMAIN RADAR */}
        <div className="flex flex-col justify-center items-center mt-8 w-full ">
          <div className="flex text-optistream-txt-blue font-bold text-base uppercase">
            Business domain overview
          </div>
          {currentAnalysis && businessServices.length ? (
            <div className="flex flex-col justify-center items-center w-full h-80">
              <WidgetBusinessDomainRadar auditResult={parsedAnalysisResult} />
              <div className="text-optistream-txt-blue text-sm -mt-12">
                Waterproof rate (%) per business domain
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center w-full  px-4">
              <Spinner size="16" />
            </div>
          )}
        </div>
      </div>

      {/* Main section: bottom */}
      <div className="flex flex-col w-full p-4">
        <div className="flex flex-col justify-center items-center mt-2 w-full h-80">
          <div className="flex text-optistream-txt-blue font-bold text-base uppercase">
            Map score history
          </div>
          <div className="flex flex-row w-4/5 h-64 items-center p-2 mt-4">
            <WidgetScoreHistory historicalData={scoreHistory} />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-8 w-full">
          <div className="flex ml-2 text-optistream-txt-blue font-bold text-base uppercase ">
            Business processes insights
          </div>
          <div className="overflow-auto mt-6 w-4/5">
            <WidgetBusinessProcessOverview
              auditResult={parsedAnalysisResult}
              currentAnalysis={currentAnalysis}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
