import React, { memo, useEffect, useState } from "react";
import {
  Handle,
  Position,
  useStore,
  getConnectedEdges,
  useReactFlow,
  NodeToolbar,
} from "reactflow";

import {
  PcHorizontal,
  PcDisplayHorizontal,
  Diagram3,
  Check2Circle,
  FileEarmarkX,
  Star,
  StarFill,
  Ethernet,
} from "react-bootstrap-icons";

import { useAlert } from "react-alert";

import { DisplayMapContext } from "./displayMapContext";
import * as Utils from "../misc/utils";
import { useAPI } from "../../../utils/API";
import "../../../styles/octagon.css";

import AWSIcon from "react-aws-icons/dist/aws/logo/AWS";
import AWSCloud from "react-aws-icons/dist/aws/compute/AWSCloud";

const nodeTypeImage = {
  Server: <PcHorizontal />,
  PC: <PcDisplayHorizontal />,
  "LAN / VLAN": <Diagram3 />,
};

const connectionNodeIdSelector = (state) => state.connectionNodeId;

const ALERT_TIME_DELAY = 3000;
const TIME_OUT_DELAY = 1500;

function TerminalNode({ id, data }) {
  const alert = useAlert();
  var lastAlert = 0;
  var isHover = false;

  const [businessServices, setBusinessServices] = useState([]);
  const [show, setShow] = useState(false);
  const [maxBusinessSensitivity, setMaxBusinessSensitivity] =
    useState("Not defined");
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isTarget = connectionNodeId && connectionNodeId !== id;

  const targetHandleStyle = {
    zIndex: isTarget ? 3 : -1,
    height: "120%",
    width: "120%",
    opacity: 0,
    position: "absolute",
    isVisible: isTarget,
  };
  const reactFlowInstance = useReactFlow();

  const { listBusinessServices } = useAPI();

  const isValidConnection = (connection) => {
    const _target_node = reactFlowInstance.getNode(connection.target);
    const _source_node = reactFlowInstance.getNode(connection.source);

    if (_target_node.type === "group") {
      if (Date.now() - lastAlert > ALERT_TIME_DELAY) {
        alert.error("Groups can only be connected to network equipment");
        lastAlert = Date.now();
      }
      return false;
    }

    if (_target_node.type === "terminal") {
      if (Date.now() - lastAlert > ALERT_TIME_DELAY) {
        alert.error("Can't connect two terminal nodes together");
        lastAlert = Date.now();
      }
      return false;
    }

    const edgesConnectionSource = getConnectedEdges(
      [_source_node],
      reactFlowInstance.getEdges()
    );

    if (edgesConnectionSource.length) {
      if (Date.now() - lastAlert > ALERT_TIME_DELAY) {
        alert.error("Can't add a 2nd connection to this node");
        lastAlert = Date.now();
      }
      return false;
    }

    const edgesConnectionTarget = getConnectedEdges(
      [_target_node],
      reactFlowInstance.getEdges()
    );
    for (const elt of edgesConnectionTarget)
      if (elt.target === connection.source) {
        return false;
      }

    return true;
  };

  useEffect(() => {
    listBusinessServices()
      .then(Utils.groupBusinessServicesByDomain)
      .then(setBusinessServices)
      .catch((e) => void e);
  }, [""]);

  const getColor = (data, showAudit, attackPath, border = true) => {
    var color = "#9e9e9e";
    if (data.Pivot) color = "#E57373";
    else if (showAudit && id === attackPath.sourceId) color = "#81C784";
    else if (showAudit && id === attackPath.destinationId) color = "#005c98";
    else if (data.ExposureLevel === Utils.threatExposureLevels[0].value)
      color = "#008579";
    else if (data.ExposureLevel === Utils.threatExposureLevels[1].value)
      color = "#009EE3";
    else if (data.ExposureLevel === Utils.threatExposureLevels[2].value)
      color = "#F5A000";
    else if (data.ExposureLevel === Utils.threatExposureLevels[3].value)
      color = "#E30513";

    return border ? color : color + "11";
  };

  const printStars = (data, showAudit, attackPath) => {
    var maxSensitivity = -1;
    if (data.BusinessServices !== undefined) {
      data.BusinessServices.forEach((bs) => {
        Object.keys(businessServices).forEach((key) =>
          businessServices[key].forEach((bsRef) => {
            if (bsRef.id === bs.businessService) {
              if (bsRef.sensitivity > maxSensitivity)
                maxSensitivity = bsRef.sensitivity;
            }
          })
        );
      });

      if (maxSensitivity >= 0) {
        setMaxBusinessSensitivity(
          Utils.businessSensitivityLevels[maxSensitivity].label
        );
      }
    }
    return (
      <>
        {maxSensitivity >= 0 ? (
          <>
            <div
              className={`flex flex-row w-full -mt-1 text-sm items-center justify-center
      ${
        data.ExposureLevel === Utils.threatExposureLevels[0].value
          ? "text-exposure-limited"
          : data.ExposureLevel === Utils.threatExposureLevels[1].value
          ? "text-exposure-contained"
          : data.ExposureLevel === Utils.threatExposureLevels[2].value
          ? "text-exposure-strong"
          : data.ExposureLevel === Utils.threatExposureLevels[2].value
          ? "text-exposure-public"
          : "text-exposure-notDefined"
      }`}
            >
              <StarFill />
              {maxSensitivity >= 1 ? <StarFill /> : <Star />}
              {maxSensitivity >= 2 ? <StarFill /> : <Star />}
              {maxSensitivity >= 3 ? <StarFill /> : <Star />}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };
  return (
    <DisplayMapContext.Consumer>
      {({ displayInterface, showAudit, attackPath }) => (
        <div
          className={`w-28  h-28 rounded-full flex justify-center items-center
          bg-transparent hover:shadow-xl group z-2`}
          onMouseEnter={() => {
            isHover = true;
            setTimeout(() => {
              if (isHover) setShow(true);
            }, TIME_OUT_DELAY);
          }}
          onMouseLeave={() => {
            isHover = false;
            setShow(false);
          }}
        >
          <div className="octagonWrap">
            <div
              className="octagon"
              style={{
                background: "#FFFFFF", //getColor(data, showAudit, attackPath, false),
                border: "solid 3px " + getColor(data, showAudit, attackPath),
                zIndex: 0,
              }}
            >
              <div className="-rotate-45 flex h-full w-full items-center justify-start relative flex-col">
                <div className="flex flex-col items-center justify-evenly h-2/5 mt-1">
                  {!showAudit ? (
                    data.Computed ? (
                      <div className="items-center text-center align-middle text-green-500 text-lg font-black">
                        <Check2Circle />
                      </div>
                    ) : (
                      <div className="">
                        <button
                          className="flex justify-center
                             rounded-full h-6 w-6 p-2 items-center align-middle
                             border border-gray-500 cursor-help shadow-xl"
                          title="Configuration is missing"
                        >
                          <span className="text-gray-500 text-sm">
                            <FileEarmarkX />
                          </span>
                        </button>
                      </div>
                    )
                  ) : data.Pivot ? (
                    <button
                      className="flex justify-center
                       rounded-full h-6 w-6 p-1 items-center align-middle
                       bg-red-300 cursor-help shadow-xl"
                      title="Weakspots"
                    >
                      <span className="text-optistream-txt-white">
                        {/* <ExclamationCircle /> */}
                        {data.StepTerminalNode}
                      </span>
                    </button>
                  ) : null}

                  {printStars(data, showAudit, attackPath)}

                  {data.Vendor === "AWS" && (
                    <AWSCloud size={40} className="-my-2" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`px-1 absolute bottom-6 text-sm text-center text-gray-800  truncate ${
              showAudit && !data.Pivot && "mt-4"
            }`}
          >
            <div className="bg-white">
              {data.DisplayName || data.HostName || data.IP || "IP not set"}
            </div>
            <div className="text-xs text-gray-500 mt-1">
              {data.EquipmentType}
            </div>
          </div>
          <div
            className={` h-34 w-34 absolute -bottom-3.5 justify-center text-base p-1.5 border rounded-full text-gray-800 ${
              isTarget ? "bg-green-300" : "bg-white"
            }`}
            style={{
              border: "solid 1px " + getColor(data, showAudit, attackPath),
            }}
          >
            <Ethernet />
            <Handle
              className="targetHandle"
              style={targetHandleStyle}
              position={Position.Top}
              isValidConnection={isValidConnection}
              type="target"
            />
            <Handle
              // className="sourceHandle"
              style={
                !showAudit
                  ? {
                      zIndex: 2,
                      width: "150%",
                      height: "150%",
                      opacity: 0,
                      border: "none",
                    }
                  : { zIndex: -1, opacity: 0 }
              }
              position={Position.Right}
              isValidConnection={isValidConnection}
              isConnectableStart={!showAudit}
              type="source"
            />
          </div>
          {show && (
            <NodeToolbar isVisible={show} position={Position.Right}>
              <span className="absolute w-64 rounded bg-gray-800 p-2 text-sm text-optistream-txt-white ">
                <ul className="marker:text-sky-400 list-disc pl-5 space-y-1 text-slate-50">
                  <li>
                    <span className="font-bold">Name:</span>{" "}
                    {data.DisplayName || data.HostName}
                  </li>
                  <li>
                    <span className="font-bold">Exposure:</span>{" "}
                    {data.ExposureLevel ? data.ExposureLevel : "-"}
                  </li>
                  <li>
                    <span className="font-bold">Business sensitivity:</span>{" "}
                    {maxBusinessSensitivity}
                  </li>

                  <li>
                    <span className="font-bold">IP:</span>{" "}
                    {data.IP ? data.IP : "-"}
                  </li>

                  <li>
                    <span className="font-bold">NetMask:</span>{" "}
                    {data.NetMask ? data.NetMask : "-"}
                  </li>
                </ul>
              </span>
            </NodeToolbar>
          )}
        </div>
      )}
    </DisplayMapContext.Consumer>
  );
}

export default memo(TerminalNode);
