import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  Controls,
  MiniMap,
  Panel,
  getConnectedEdges,
} from "reactflow";

import "reactflow/dist/style.css";
import "../styles/flowmap.css";
import "../styles/contextMenu.css";
import "../styles/group.css";
import { v4 as uuidv4 } from "uuid";
import BarLoader from "react-spinners/BarLoader";

import Sidebar from "./components/map/sidebar";
import Spinner from "./components/misc/spinner";

import NoteNode from "./components/map/noteNodeObject";
import NetworkNode from "./components/map/networkNodeObject";
import TerminalNode from "./components/map/terminalNodeObject";
import FloatingEdge from "./components/map/connectionObject";
import ConnectionLine from "./components/map/connectionLine";
import GroupNode from "./components/map/groupNodeObject";
import EdgeOverlay from "./components/map/connectionOverlay";
import NodeOverlay from "./components/map/nodeOverlay";
import NoteNodeOverlay from "./components/map/noteNodeOverlay";
import ContextMenu from "./components/map/contextMenu";

import { useAlert } from "react-alert";
import { DisplayMapContext } from "./components/map/displayMapContext";
import { useAPI, ReactUtils } from "../utils";
import { findEdge } from "./components/map/reactflowUtils";
import GroupNodeOverlay from "./components/map/groupNodeOverlay";
import WorkspaceTabs from "./components/workspaceHeader/workspaceTabs";
import AnalysisLauncherOverlay from "./components/map/analysisLauncherOverlay";

const nodeTypes = {
  networkNode: NetworkNode,
  core: NetworkNode,
  terminal: TerminalNode,
  note: NoteNode,
  group: GroupNode,
};

const edgeTypes = {
  floating: FloatingEdge,
};

const defaultEdgeOptions = {
  style: { strokeWidth: 1, stroke: "#616161" },
  type: "floating",
};

const connectionLineStyle = {
  strokeWidth: 1,
  stroke: "gray",
};

export default function Map() {
  const reactFlowWrapper = useRef(null);
  const dragRef = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [showMinimap, setShowMinimap] = useState(true);
  const [currentMapName, setCurrentMapName] = useState("");
  const [saveStatus, setSaveStatus] = useState(false);
  const [currentSnapshotId, setCurrentSnapshotId] = useState("");
  const [currentSnapshotStatus, setCurrentSnapshotStatus] = useState(null);
  const [editNode, setEditNode] = useState(null);
  const previousEditNode = ReactUtils.usePrevious(editNode);
  const [editEdge, setEditEdge] = useState(null);
  const [dynamicPath, setDynamicPath] = useState([]);
  const [editMapName, setEditMapName] = useState("");
  const [loadingSnapshot, setLoadingSnapshot] = useState(true);
  const [isProcessingSnapshot, setIsProcessingSnapshot] = useState(false);
  const [recomputeMapWhenUpdate, setRecomputeMapWhenUpdate] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [menu, setMenu] = useState(null);
  const [target, setTarget] = useState(null);
  const [analysisToPerform, setAnalysisToPerform] = useState(null);
  const [controlAnalysis, setControlAnalysis] = useState(false);

  const {
    getSnapshotById,
    getLatestSnapshot,
    updateSnapshot,
    updateWorkspace,
    getWorkspaceById,
    getDefaultGateway,
  } = useAPI();
  const alert = useAlert();
  const [queryParameters] = useSearchParams();

  useEffect(() => {
    if (!editNode) return;
    if (!previousEditNode) return;

    const udpdatedNodes = nodes.map((node) => {
      if (node.id === editNode.id) {
        return {
          ...node,
          data: {
            ...editNode.data,
          },
          style: {
            ...editNode.style,
          },
        };
      } else return node;
    });

    setSaveStatus(true);
    setNodes(udpdatedNodes);
  }, [editNode]);

  useEffect(() => {
    setIsProcessingSnapshot(currentSnapshotStatus === "PROCESSING");
  }, [currentSnapshotStatus]);

  const [mapDisplayValue, setMapDisplayValue] = useState({
    displayInterface: false,
  });

  const switchShowInterface = () => {
    setMapDisplayValue({
      ...mapDisplayValue,
      displayInterface: !mapDisplayValue.displayInterface,
    });
  };

  const showMinimapOnClick = () => {
    setShowMinimap(!showMinimap);
  };

  const { workspaceId } = useParams();

  useEffect(() => {
    getWorkspaceById(workspaceId)
      .then((workspace) => {
        setCurrentMapName(workspace.name);
        setEditMapName(workspace.name);
        setLoadingSnapshot(true);

        getLatestSnapshot(workspaceId)
          .then((snapshot) => {
            setCurrentSnapshotStatus(snapshot.status.name);
            setCurrentSnapshotId(snapshot.id);

            const mapObj = JSON.parse(snapshot.map);

            setMapRef(mapObj);
            if (mapObj.nodes !== undefined) setNodes(mapObj.nodes);
            if (mapObj.edges !== undefined) setEdges(mapObj.edges);
            setLoadingSnapshot(false);
          })
          .catch((e) => void e);
      })
      .catch((e) => void e);
  }, []);

  useEffect(() => {
    if (currentSnapshotId) {
      getSnapshotById(workspaceId, currentSnapshotId)
        .then(configureSnapshotData)
        .catch((e) => void e);
    }
  }, [currentSnapshotId]);

  const configureSnapshotData = (snapshot) => {
    setCurrentSnapshotStatus(snapshot.status);
    const map = JSON.parse(snapshot.map);

    setMapRef(map);
    if (map.nodes !== undefined) setNodes(map.nodes);
    if (map.edges !== undefined) setEdges(map.edges);
  };

  ReactUtils.usePollingEffect(
    async () => {
      getSnapshotById(workspaceId, currentSnapshotId)
        .then((snapshot) => {
          if (snapshot.status !== "PROCESSING") {
            configureSnapshotData(snapshot);
          }
        })
        .catch((e) => void e);
    },
    () => currentSnapshotStatus !== "PROCESSING",
    [currentSnapshotStatus]
  );

  const onNodeContextMenu = useCallback(
    (event, node) => {
      // Prevent native context menu from showing
      event.preventDefault();

      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = reactFlowWrapper.current.getBoundingClientRect();

      setMenu({
        id: node.id,
        top: event.clientY < pane.height - 50 && event.clientY - pane.y,
        left: event.clientX < pane.width - 50 && event.clientX - pane.x,
        right: event.clientX >= pane.width - 50 && pane.right - event.clientX,
        bottom:
          event.clientY >= pane.height - 50 && pane.bottom - event.clientY,
        setSaveStatus: setSaveStatus,
        alert: alert,
        deleteNode: deleteNode,
      });
    },
    [setMenu]
  );

  // Close the context menu if it's open whenever the window is clicked.
  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  const onConnect = useCallback(
    (params) => {
      if (reactFlowInstance) {
        const nodeSource = reactFlowInstance.getNode(params.source);
        const nodeDest = reactFlowInstance.getNode(params.target);

        var nodeTerminal = "";
        var nodeCoreId = "";
        var connectionTerminalToCore = false;

        if (nodeSource.type === "core" && nodeDest.type === "terminal") {
          if (nodeDest.data.IP === undefined || nodeDest.data.IP === "") {
            alert.error("Define terminal node IP first");
            return;
          }
          connectionTerminalToCore = true;
          nodeTerminal = nodeDest;
          nodeCoreId = nodeSource.id;
        } else if (nodeSource.type === "terminal" && nodeDest.type === "core") {
          if (nodeSource.data.IP === undefined || nodeSource.data.IP === "") {
            alert.error("Define terminal node IP first");
            return;
          }

          connectionTerminalToCore = true;
          setRecomputeMapWhenUpdate(true);
          nodeTerminal = nodeSource;
          nodeCoreId = nodeDest.id;
        }

        if (
          connectionTerminalToCore &&
          (nodeTerminal.data.Gateway === undefined ||
            nodeTerminal.data.Gateway === "")
        ) {
          getDefaultGateway(
            workspaceId,
            currentSnapshotId,
            nodeTerminal.data.IP,
            nodeCoreId
          )
            .then((res) => {
              if (res.found) {
                const udpdatedNodes = nodes.map((node) => {
                  if (node.id === nodeTerminal.id) {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        NetMask: res.netmask,
                        Gateway: res.gateway,
                      },
                    };
                  } else return node;
                });

                setSaveStatus(true);
                setNodes(udpdatedNodes);
                _addEdge(params);
              } else alert.error("No netmask / gateway found");
            })
            .catch((e) => void e);
        } else {
          _addEdge(params);
        }
      } else console.log("Error: no access to reactFlowInstance");
    },
    [reactFlowInstance, nodes, edges]
  );

  const onClose = () => {
    setEditNode(null);
    setEditEdge(null);
  };

  const onNodeClick = useCallback(
    (event, node) => {
      if (!menu) {
        if (editNode === null || editNode.id !== node.id) {
          setEditNode(node);
        } else {
          onClose();
        }
      }
    },
    [editNode, edges]
  );

  const updateWorkspaceName = useCallback(() => {
    const params = {
      Name: editMapName,
    };

    updateWorkspace(workspaceId, params).catch((e) => void e);
  }, [editMapName]);

  const onEdgeClick = useCallback((event, edge) => {
    if (editNode !== null) setEditNode(null);

    if (editEdge === null || editEdge.id !== edge.id) {
      setEditEdge(edge);
    } else onClose();
  });

  const onInit = (reactFlowInstance) => {
    setReactFlowInstance(reactFlowInstance);
  };

  const onNodeDragStart = (evt, node) => {
    dragRef.current = node;
  };

  const onNodeDrag = (evt, node) => {
    if (node.type === "terminal") {
      // calculate the center point of the node from position and dimensions
      const centerX = node.position.x + node.width / 2;
      const centerY = node.position.y + node.height / 2;

      // find a node where the center point is inside
      const targetNode = nodes.find(
        (n) =>
          centerX > n.position.x &&
          centerX < n.position.x + n.width &&
          centerY > n.position.y &&
          centerY < n.position.y + n.height &&
          n.id !== node.id // this is needed, otherwise we would always find the dragged node
      );
      if (targetNode?.type === "group") {
        setTarget(targetNode);
      }
      if (!targetNode) setTarget(null);
    }
  };

  const addNodeToParentSave = (node, parent, position_x, position_y) => {
    setSaveStatus(true);
    setNodes((nodes) =>
      nodes.map((n) => {
        if (n.id === node.id && parent) {
          n = {
            ...node,
            parentNode: target.id,
            extent: "parent",
          };

          if (position_x && position_y)
            n.position = {
              x: position_x,
              y: position_y,
            };
        }
        return n;
      })
    );
    alert.success("Node successfully attached to group");
  };

  const _addEdge = (params) => {
    setSaveStatus(true);
    setEdges((eds) =>
      addEdge(
        {
          ...params,
          id: `${params.source}-${params.target}`,
          label: "Connection name",
          deletable: true,
          selected: false,
          sourceHandle: null,
          targetHandle: null,
          data: {
            Source: params.source,
            SourceInterfaceLabel: "",
            SourceInterfaceValue: "",
            Destination: params.target,
            DestinationInterfaceLabel: "",
            DestinationInterfaceValue: "",
          },
        },
        eds
      )
    );
  };

  const onNodeDragStop = (evt, node) => {
    // on drag stop, we swap the colors of the nodes
    setSaveStatus(true);
    if (target) {
      if (
        !(
          node.data?.IP &&
          node.data.IP !== "" &&
          node.data?.Gateway &&
          node.data.Gateway !== ""
        )
      ) {
        alert.error("Define node's IP & Gateway before adding it to a group");
        return;
      }

      const _relative_x = node.positionAbsolute.x - target.position.x;
      const _relative_y = node.positionAbsolute.y - target.position.y;

      const _target_edges = getConnectedEdges(
        [target],
        reactFlowInstance.getEdges()
      );

      const _child_edges = getConnectedEdges(
        [node],
        reactFlowInstance.getEdges()
      );

      // MANAGE LINK BETWEEN GROUP & CHILD
      //   if target has a connection
      //      if child has no connection : create connection btw child and target of the group
      //      if child has same connection : just add the node
      //      if child has different connection : don't add the node + alert(can't have different connection)
      //   else (no connection)
      //      if child has connection : create connection btw group and target of the child
      //      else (child has no connection) : just add the node

      if (_target_edges.length) {
        const _group_target_id =
          _target_edges[0].source !== node.id
            ? _target_edges[0].source
            : _target_edges[0].target;

        if (!_child_edges.length) {
          const _newEdgeParams = {
            ...defaultEdgeOptions,
            source: node.id,
            target: _group_target_id,
          };

          _addEdge(_newEdgeParams);
          addNodeToParentSave(node, target, _relative_x, _relative_y);
        } else {
          const _child_target_id =
            _child_edges[0].source !== node.id
              ? _child_edges[0].source
              : _child_edges[0].target;

          if (_child_target_id === _group_target_id) {
            addNodeToParentSave(node, target, _relative_x, _relative_y);
          } else {
            alert.error(
              "The terminal and the group are connected to different equipments"
            );
          }
        }
      } else {
        if (_child_edges.length) {
          const _group_parent =
            _child_edges[0].source !== node.id
              ? _child_edges[0].source
              : _child_edges[0].target;

          const _newEdgeParams = {
            ...defaultEdgeOptions,
            source: _group_parent,
            target: target.id,
          };
          _addEdge(_newEdgeParams);
          addNodeToParentSave(node, target, _relative_x, _relative_y);
        } else {
          addNodeToParentSave(node, target, _relative_x, _relative_y);
        }
      }

      setTarget(null);
    }

    dragRef.current = null;
  };
  const onDragOver = useCallback((event, node) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const deleteNode = useCallback(
    (node) => {
      setSaveStatus(true);
      if (node.type === "group") {
        const nodesIdToDelete = nodes.reduce((acc, currentNode) => {
          if (currentNode.id === node.id || currentNode?.parentNode === node.id)
            acc.push(currentNode.id);
          return acc;
        }, []);

        setNodes((nodes) =>
          nodes.filter((n) => n.id !== node.id && n?.parentNode !== node.id)
        );
        setEdges((edges) =>
          edges.filter(
            (edge) =>
              !nodesIdToDelete.includes(edge.source) &&
              !nodesIdToDelete.includes(edge.target)
          )
        );
      } else {
        setNodes((nodes) =>
          nodes.filter((n) => n.id !== node.id && n?.parentNode !== node.id)
        );
        setEdges((edges) =>
          edges.filter(
            (edge) => edge.source !== node.id && edge.target !== node.id
          )
        );
      }
    },
    [reactFlowInstance]
  );

  const deleteEdge = () => {
    setEdges((eds) => eds.filter((e) => e.id !== editEdge.id));
    setSaveStatus(true);
  };

  const refreshEdges = useCallback(
    (event) => {
      if (!editEdge) return;

      const udpdatedEdges = edges.map((edge) => {
        if (edge.id === editEdge.id) {
          return {
            ...edge,
            data: {
              ...editEdge.data,
            },
          };
        } else return edge;
      });
      setSaveStatus(true);
      setEdges(udpdatedEdges);
    },
    [editEdge]
  );

  useEffect(() => {
    const animatedEdgeIds = [];

    for (var i = 0; i < dynamicPath.length - 1; i++) {
      var src = dynamicPath[i];
      var dst = dynamicPath[i + 1];
      var edge = findEdge(mapRef, src, dst);

      //TODO: some edges are undefined but this should not happen
      if (edge) {
        animatedEdgeIds.push(edge.id);
      }
    }
    const targetEdges = edges.map((edge) =>
      animatedEdgeIds.includes(edge.id)
        ? {
            ...edge,
            animated: true,
          }
        : { ...edge, animated: false }
    );

    const targetNodes = nodes.map((node, index) =>
      dynamicPath.includes(node.id)
        ? {
            ...node,
            data: {
              ...node.data,
              Pivot: true,
              StepTerminalNode: dynamicPath.indexOf(node.id),
            },
          }
        : { ...node, data: { ...node.data, Pivot: false } }
    );
    setEdges(targetEdges);
    setNodes(targetNodes);
  }, [dynamicPath]);

  useEffect(() => {
    if (!reactFlowInstance) return;
    if (!saveStatus) return;

    const params = {
      Map: JSON.stringify(reactFlowInstance.toObject()),
      Recompute: recomputeMapWhenUpdate,
    };

    updateSnapshot(workspaceId, currentSnapshotId, params)
      .then((snapshot) => {
        if (recomputeMapWhenUpdate) alert.success("Computing network changes…");
        setCurrentSnapshotStatus(snapshot.status);
        setRecomputeMapWhenUpdate(false);
      })
      .catch((e) => void e);

    setSaveStatus(false);
  }, [nodes, edges]);

  useEffect(() => {
    if (saveStatus) {
      setSaveStatus(false);
      updateWorkspaceName();
    }
  }, [editMapName]);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      if (type === "core" || type === "terminal") {
        const uuid = uuidv4();
        const eqptType = event.dataTransfer.getData("eqptType");

        const newNode = {
          id: uuid,
          type,
          position,
          data: {
            ElementId: uuid,
            MapId: workspaceId,
            EquipmentType: eqptType,
            DisplayName: eqptType,
            NodeType: type,
          },
        };
        setNodes((nds) => nds.concat(newNode));
        setSaveStatus(true);
      }
      if (type === "group") {
        const uuid = uuidv4();
        const eqptType = event.dataTransfer.getData("eqptType");

        const newNode = {
          id: uuid,
          type,
          position,
          style: {
            backgroundColor: "rgba(244, 67, 54, 0.2)",
            borderColor: "rgba(244, 67, 54, 0.7)",
            borderWidth: 2,
            margin: 0,
            padding: 0,
            zIndex: -10,
            width: 300,
            height: 300,
          },
          data: {
            ElementId: uuid,
            MapId: workspaceId,
            DisplayName: "(V)LAN Name",
            GroupType: "VLAN",
            NodeType: type,
            Color: "#F44336",
          },
        };

        setNodes((nds) => nds.concat(newNode));
        setSaveStatus(true);
      }
      if (type === "note") {
        const uuid = uuidv4();
        const newNode = {
          id: uuid,
          type,
          position,
          data: {
            ElementId: uuid,
            MapId: workspaceId,
            Label: "Capture your thoughts here",
            NodeType: type,
          },
        };
        setNodes((nds) => nds.concat(newNode));
        setSaveStatus(true);
      }
    },
    [reactFlowInstance]
  );
  const callCheckAnalysisConditions = () => {
    if (analysisToPerform) setControlAnalysis(true);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-background w-full p-12 justify-center ">
      <WorkspaceTabs workspaceId={workspaceId} />
      <div className="flex flex-row w-full h-[calc(100vh-145px)] dndflow">
        <DisplayMapContext.Provider value={mapDisplayValue}>
          <ReactFlowProvider>
            <div className="flex flex-col h-full mr-4 bg-white">
              <Sidebar
                editMapName={editMapName}
                setEditMapName={setEditMapName}
                setSaveStatus={setSaveStatus}
                setCurrentSnapshotStatus={setCurrentSnapshotStatus}
                showMinimap={showMinimap}
                showMinimapOnClick={showMinimapOnClick}
                nodes={nodes}
                snapshotId={currentSnapshotId}
                workspaceId={workspaceId}
                dynamicPath={dynamicPath}
                setDynamicPath={setDynamicPath}
                analysisToPerform={analysisToPerform}
                setAnalysisToPerform={setAnalysisToPerform}
                callCheckAnalysisConditions={callCheckAnalysisConditions}
              />
            </div>
            <div className="flex flex-col h-full ml-4 w-full justify-between bg-white">
              {loadingSnapshot ? (
                <div className="flex flex-col justify-center items-center w-full h-full text-left mb-7 mx-2">
                  <div className="flex flex-row items-center text-optistream-txt-blue text-2xl uppercase mt-2 mb-4">
                    Loading workspace
                  </div>
                  <Spinner size="16" />
                </div>
              ) : (
                <div
                  className="flex flex-grow h-full w-full relative"
                  ref={reactFlowWrapper}
                >
                  {editNode &&
                  (editNode.type === "core" || editNode.type === "terminal") ? (
                    <NodeOverlay
                      editNode={editNode}
                      setEditNode={setEditNode}
                      onCloseCallback={onClose}
                      deleteNode={deleteNode}
                      workspaceId={workspaceId}
                      snapshotId={currentSnapshotId}
                      setRecomputeMapWhenUpdate={setRecomputeMapWhenUpdate}
                      setCurrentSnapshotStatus={setCurrentSnapshotStatus}
                    />
                  ) : null}
                  {editNode && editNode.type === "note" ? (
                    <NoteNodeOverlay
                      editNode={editNode}
                      setEditNode={setEditNode}
                      onCloseCallback={onClose}
                      deleteNode={deleteNode}
                    />
                  ) : null}
                  {editNode && editNode.type === "group" ? (
                    <GroupNodeOverlay
                      editNode={editNode}
                      setEditNode={setEditNode}
                      onCloseCallback={onClose}
                      deleteNode={deleteNode}
                    />
                  ) : null}
                  {editEdge ? (
                    <EdgeOverlay
                      editEdge={editEdge}
                      reactFlowInstance={reactFlowInstance}
                      setEditEdge={setEditEdge}
                      onCloseCallback={onClose}
                      refreshEdges={refreshEdges}
                      deleteEdge={deleteEdge}
                      setRecomputeMapWhenUpdate={setRecomputeMapWhenUpdate}
                    />
                  ) : null}
                  {controlAnalysis ? (
                    <AnalysisLauncherOverlay
                      workspaceId={workspaceId}
                      alert={alert}
                      analysisToPerform={analysisToPerform}
                      onCloseCallback={() => {
                        setControlAnalysis(false);
                        setAnalysisToPerform(null);
                      }}
                    />
                  ) : null}
                  <ReactFlow
                    ref={reactFlowWrapper}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={onInit}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    defaultEdgeOptions={defaultEdgeOptions}
                    connectionLineComponent={ConnectionLine}
                    connectionLineStyle={connectionLineStyle}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    onNodeDragStart={onNodeDragStart}
                    onNodeDrag={onNodeDrag}
                    onNodeDragStop={onNodeDragStop}
                    onNodeClick={onNodeClick}
                    onEdgeClick={onEdgeClick}
                    onPaneClick={onPaneClick}
                    onNodeContextMenu={onNodeContextMenu}
                    nodeDragThreshold={1}
                    className="bg-teal-50"
                    fitView
                  >
                    <Background color="#99b3ec" variant="dots" />
                    {menu && <ContextMenu onClick={onPaneClick} {...menu} />}
                    <Controls />
                    {showMinimap && (
                      <MiniMap nodeStrokeWidth={3} zoomable pannable />
                    )}
                    {isProcessingSnapshot && (
                      <Panel position="top-right" style={{ color: "grey" }}>
                        Computing changes{" "}
                        <BarLoader width="100%" color="#EF7B18" />
                      </Panel>
                    )}
                  </ReactFlow>
                  {isProcessingSnapshot && (
                    <div className="absolute inset-0 flex justify-center items-center z-10 bg-transparent cursor-wait"></div>
                  )}
                </div>
              )}
            </div>
          </ReactFlowProvider>
        </DisplayMapContext.Provider>
      </div>
    </div>
  );
}
