export const HOME = "/";

export const MAP = "/map";
export const INFILTRATION = "/infiltration";
export const INFILTRATION_DETAILS = "/infiltrationDetails";
export const RISKY_ROUTES = "/riskyRoutes";
export const RISKY_ROUTES_DETAILS = "/riskyRoutesDetails";

export const NOT_FOUND = "/not-found";

export const BUSINESS_COCKPIT = "/BusinessCockpit";
export const ANALYSIS_HISTORY = "/analysisHistory";
export const CONFIG_BUSINESS_SERVICE = "/businessServices";

export const SIGN_UP = "/signup";
export const LOGIN = "/login";
