import React, { useState } from "react";
import * as Modal from "../Modal/modal";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useAlert } from "react-alert";
import { useAPI } from "../../../utils";
import Select from "react-tailwindcss-select";

export default function SyncAWSConfig({
  workspaceId,
  snapshotId,
  setCurrentSnapshotStatus,
  onCloseCallback,
}) {
  const alert = useAlert();
  const [accessKeyId, setAccessKeyId] = useState("");
  const [secretAccessKey, setSecretAccessKey] = useState("");

  const [regions, setRegions] = useState(null);

  const handleAccessKeyIdChange = (e) => {
    setAccessKeyId(e.target.value);
  };

  const handleSecretAccessKeyChange = (e) => {
    setSecretAccessKey(e.target.value);
  };
  const regionsOptions = [
    { value: "eu-west-1", label: "Europe (Irlande) (eu-west-1)" },
    { value: "eu-west-2", label: "Europe (Londres) (eu-west-2)" },
    { value: "eu-west-3", label: "Europe (Paris) (eu-west-3)" },
    { value: "eu-central-1", label: "Europe (Francfort) (eu-central-1)" },
    { value: "eu-north-1", label: "Europe (Stockholm) (eu-north-1)" },
    { value: "eu-south-1", label: "Europe (Milan) (eu-south-1)" },
    { value: "eu-south-2", label: "Europe (Espagne) (eu-south-2)" },
    { value: "eu-central-2", label: "Europe (Zurich) (eu-central-2)" },
    { value: "us-east-1", label: "USA Est (Virginie du Nord) (us-east-1)" },
    { value: "us-east-2", label: "USA Est (Ohio) (us-east-2)" },
    { value: "us-west-1", label: "USA Ouest (Californie du Nord) (us-west-1)" },
    { value: "us-west-2", label: "USA Ouest (Oregon) (us-west-2)" },
    { value: "ap-south-1", label: "Asie Pacifique (Mumbai) (ap-south-1)" },
    {
      value: "ap-northeast-3",
      label: "Asie Pacifique (Osaka) (ap-northeast-3)",
    },
    {
      value: "ap-northeast-2",
      label: "Asie Pacifique (Séoul) (ap-northeast-2)",
    },
    {
      value: "ap-southeast-1",
      label: "Asie Pacifique (Singapour) (ap-southeast-1)",
    },
    {
      value: "ap-southeast-2",
      label: "Asie Pacifique (Sydney) (ap-southeast-2)",
    },
    {
      value: "ap-northeast-1",
      label: "Asie Pacifique (Tokyo) (ap-northeast-1)",
    },
    { value: "ca-central-1", label: "Canada (Central) (ca-central-1)" },
    { value: "sa-east-1", label: "Amérique du Sud (São Paulo) (sa-east-1)" },
    { value: "af-south-1", label: "Afrique (Le Cap) (af-south-1)" },
    { value: "ap-east-1", label: "Asie Pacifique (Hong Kong) (ap-east-1)" },
    { value: "ap-south-2", label: "Asie Pacifique (Hyderabad) (ap-south-2)" },
    {
      value: "ap-southeast-3",
      label: "Asie Pacifique (Jakarta) (ap-southeast-3)",
    },
    {
      value: "ap-southeast-4",
      label: "Asie Pacifique (Melbourne) (ap-southeast-4)",
    },
    { value: "ca-west-1", label: "Canada (Calgary) (ca-west-1)" },
    { value: "me-south-1", label: "Moyen-Orient (Bahreïn) (me-south-1)" },
    { value: "me-central-1", label: "Moyen-Orient (EAU) (me-central-1)" },
  ];

  const { syncAWSConfig } = useAPI();
  const selectedRegionsValues = regions
    ? regions.map((region) => region.value)
    : [];
  const doSyncAWSConfig = (accessKeyId, secretAccessKey, region) => {
    syncAWSConfig(workspaceId, snapshotId, accessKeyId, secretAccessKey, region)
      .then((data) => {
        setCurrentSnapshotStatus(data.snapshot.status);
        if (data.modified === true) {
          alert.success("AWS synchronization successful!");
        } else {
          alert.info("Configuration unchanged");
        }
        onCloseCallback();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          alert.error(error.response.data.error);
        } else {
          alert.error("AWS account not found, Check your credentials");
        }
      });
  };

  return (
    <Modal.Frame
      onClose={() => {
        onCloseCallback();
      }}
    >
      <Modal.Head>AWS Sync: AWS Credentials</Modal.Head>
      <Modal.Body>
        <div className="flex flex-col text-center text-black-light w-auto h-5/6 p-4 justify-evenly items-center">
          <h1 className="text-optistream-txt-blue font-bold text-lg uppercase">
            Provide AWS credentials
          </h1>
          <input
            type="text"
            placeholder="Access Key ID"
            className="w-full p-2 border border-gray-400 mb-4"
            value={accessKeyId}
            onChange={handleAccessKeyIdChange}
          />
          <input
            type="password"
            placeholder="Secret Access Key"
            className="w-full p-2 border border-gray-400 mb-4"
            value={secretAccessKey}
            onChange={handleSecretAccessKeyChange}
          />
          <Select
            primaryColor={"indigo"}
            value={regions}
            isMultiple={true}
            name="Regions"
            placeholder="Choose regions to load"
            onChange={(elements) => {
              setRegions(elements);
            }}
            options={regionsOptions}
            classNames={{
              menuButton: ({ isDisabled }) => {
                const hasRegions = Array.isArray(regions) && regions.length > 0;
                return `flex w-full border border-gray-300 rounded shadow-sm text-gray-base
          transition-all duration-300  bg-white
          mb-1 hover:border-gray-400
          focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500/20
          ${hasRegions ? "text-xs" : " text-sm"}`;
              },
              menu: "absolute z-10 w-full bg-white shadow-lg border rounded py-1 text-sm text-gray-200 mb-1",
              listItem: ({ isSelected }) =>
                `block transition duration-200 px-2 py-2 cursor-pointer
        select-none truncate rounded ${
          isSelected
            ? `text-optistream-txt-white bg-blue-menu`
            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
        }`,
            }}
          />

          <div className="flex flex-row w-full justify-evenly">
            <button
              className="w-44 p-2 bg-optistream-btn-alternative active:bg-btn-altClicked border text-optistream-txt-white m-2 cursor-pointer text-base"
              onClick={onCloseCallback}
            >
              Cancel
            </button>
            <button
              className="w-44 p-2 bg-optistream-btn-default active:bg-optistream-btn-clicked rounded  font-bold border text-optistream-txt-white m-2 cursor-pointer text-base"
              onClick={() => {
                if (accessKeyId && secretAccessKey && regions) {
                  doSyncAWSConfig(
                    accessKeyId,
                    secretAccessKey,
                    selectedRegionsValues
                  );
                } else {
                  alert.error("Please provide AWS credentials and regions");
                }
              }}
            >
              Sync AWS Configurations
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal.Frame>
  );
}
