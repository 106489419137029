import React, { useEffect, useState } from "react";
import Spinner from "../../misc/spinner";
import ReactJson from "react-json-view";

import { useAPI } from "../../../../utils";

export default function NodeOverlayTabACLs({
  editNode,
  workspaceId,
  snapshotId,
}) {
  const { listNodeACL } = useAPI();
  const [ACLs, setACLs] = useState(null);
  const [ACLsLoaded, setACLsLoaded] = useState(false);

  useEffect(() => {
    listNodeACL(workspaceId, snapshotId, editNode.id)
      .then(setACLs)
      .catch((e) => void e)
      .finally(() => setACLsLoaded(true));
  }, []);

  return (
    <div className="text-gray-base w-full">
      {!ACLsLoaded ? (
        <div className="flex flex-row justify-center items-center w-full h-full">
          <Spinner size="16" />
        </div>
      ) : ACLs ? (
        <div className="whitespace-pre-line font-mono">
          <ReactJson
            src={ACLs}
            iconStyle="square"
            displayDataTypes={false}
            name={false}
            collapsed={3}
          />
        </div>
      ) : null}
    </div>
  );
}
