import React, { useCallback, memo } from "react";
import { useReactFlow } from "reactflow";
import ConfirmDialog from "../misc/confirmDialog";

function ContextMenu({
  id,
  top,
  left,
  right,
  bottom,
  setSaveStatus,
  alert,
  deleteNode,
  ...props
}) {
  const { getNode, setNodes, setEdges } = useReactFlow();

  const node = getNode(id);

  const detachNode = useCallback(() => {
    setNodes((nodes) =>
      nodes.map((n) => {
        if (n.id === node.id) {
          delete n.parentNode;
          delete n.extent;
          n.position = {
            x: n.positionAbsolute.x,
            y: n.positionAbsolute.y,
          };
        }

        return n;
      })
    );
    setSaveStatus(true);

    alert.success("Node successfully detached from group");
  }, [node, setNodes, setEdges]);

  return (
    <div
      style={{ top, left, right, bottom }}
      className="context-menu"
      {...props}
    >
      {node.parentNode && <button onClick={detachNode}> Detach</button>}
      <button
        onClick={() =>
          ConfirmDialog(
            "You are about to definitely delete your element.",
            () => deleteNode(node)
          )
        }
      >
        Delete
      </button>
    </div>
  );
}

export default memo(ContextMenu);
