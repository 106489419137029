import React, { memo, useEffect, useState } from "react";
import {
  Handle,
  Position,
  useStore,
  getConnectedEdges,
  useReactFlow,
  NodeToolbar,
  NodeResizeControl,
  NodeResizer,
} from "reactflow";

import {
  Wifi,
  Bricks,
  HddNetwork,
  Check2Circle,
  FileEarmarkX,
  ArrowsFullscreen,
  PlusCircle,
  Globe,
} from "react-bootstrap-icons";

// VPC
// Subnet
// IGW
import VPC from "react-aws-icons/dist/aws/compute/VPC";
import VPCSubnet from "react-aws-icons/dist/aws/compute/VPCSubnet";
import InternetGateway from "react-aws-icons/dist/aws/compute/InternetGateway";
import Internet from "react-aws-icons/dist/aws/general/Internet";
import Router from "react-aws-icons/dist/aws/compute/Router";
import { AwsTransitGateway } from "../../../icons/awsTransitGateway";
import { DisplayMapContext } from "./displayMapContext";

const connectionNodeIdSelector = (state) => state.connectionNodeId;

function GroupNode({ id, data }) {
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const [resizeMinHeight, setResizeMinHeight] = useState(100);
  const [resizeMinWidth, setResizeMinWidth] = useState(100);

  const isTarget = connectionNodeId && connectionNodeId !== id;
  const targetHandleStyle = {
    zIndex: isTarget ? 15 : -1,
    height: "100%",
    width: "100%",
    //backgroundColor: "blue",
    opacity: 0,
    position: "absolute",
    right: "0%",
    top: "0%",
    transform: "translate(-50%,0%)",
  };

  const reactFlowInstance = useReactFlow();
  var isHover = false;
  const [show, setShow] = useState(false);
  const TIME_OUT_DELAY = 1500;

  const isValidConnection = (connection) => {
    const edgesConnectionTarget = getConnectedEdges(
      [reactFlowInstance.getNode(connection.target)],
      reactFlowInstance.getEdges()
    );

    for (const elt of edgesConnectionTarget)
      if (elt.target === connection.source) {
        return false;
      }
    return true;
  };

  const recomputeMinSize = () => {
    var maxX = -1;
    var maxY = -1;

    const children = reactFlowInstance
      .getNodes()
      .filter((n) => n.parentNode && n.parentNode === id);

    children.forEach((n) => {
      if (maxX < 0 || n.position.x + n.width > maxX)
        maxX = n.position.x + n.width;
      if (maxY < 0 || n.position.y + n.height > maxY)
        maxY = n.position.y + n.height;
    });
    setResizeMinWidth(maxX);
    setResizeMinHeight(maxY);
  };

  useEffect(() => recomputeMinSize(), [reactFlowInstance.getNodes()]);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  function ResizeIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="#ff0071"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ position: "absolute", right: 5, bottom: 5 }}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <polyline points="16 20 20 20 20 16" />
        <line x1="14" y1="14" x2="20" y2="20" />
        <polyline points="8 4 4 4 4 8" />
        <line x1="4" y1="4" x2="10" y2="10" />
      </svg>
    );
  }
  const controlStyle = {
    background: "transparent",
    border: "none",
  };

  return (
    <DisplayMapContext.Consumer>
      {({ displayInterface, showAudit, attackPath }) => (
        <div
          className="w-full h-full bg-transparent -z-10"
          //className="box2"
        >
          <div className="m-2 text-sm">{data.DisplayName}</div>
          <Handle
            type="target"
            style={targetHandleStyle}
            position={Position.Top}
            isValidConnection={isValidConnection}
          />

          <NodeResizeControl
            style={controlStyle}
            minHeight={resizeMinHeight}
            minWidth={resizeMinWidth}
            onResizeStart={recomputeMinSize}
          >
            <ResizeIcon />
          </NodeResizeControl>
        </div>
      )}
    </DisplayMapContext.Consumer>
  );
}

export default memo(GroupNode);
