import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

import { msalConfig } from "../authConfig";

export default function Login() {
  //    const history = useNavigate();

  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");
  const isInvalid = password === "" || emailAddress === "";

  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  useEffect(() => {
    document.title = "Login - optistream";
  }, []);

  return (
    <div className="flex flex-col w-screen h-screen items-center justify-center bg-gray-background">
      <div className="mb-24 justify-center items-center flex flex-col">
        <img src="/images/logo.png" className="flex w-96 mb-8" />
        <button
          className="bg-optistream-btn-default active:bg-optistream-btn-clicked text-optistream-txt-white uppercase
                text-center align-middle h-16 w-60 rounded text-xl"
          onClick={() => handleLogin("redirect")}
        >
          Sign in
        </button>
      </div>
    </div>
  );
}
