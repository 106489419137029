import React, { useState } from "react";

import {
  securityLevelToLabel,
  securityLevelToRate,
  securityLevelIsCritical,
} from "./utils";
import { CheckCircle, XSquareFill } from "react-bootstrap-icons";

import { nodeDisplayName } from "../misc/utils";

export default function DetailServiceAttackPath({
  analysis,
  targetBusinessServicePaths,
}) {
  const [expandIndex, setExpandIndex] = useState(null);

  const map = JSON.parse(analysis.snapshot.map);

  const nodeNameFromId = (nodeId) => {
    const node = map.nodes.find((node) => node.id === nodeId);

    return nodeDisplayName(node);
  };

  function sortRoutes(a, b) {
    return (
      securityLevelToRate(a.security_level) -
        securityLevelToRate(b.security_level) ||
      a.dst_node.localeCompare(b.dst_node)
    );
  }

  const displayServiceCategory = (protocols, category) => {
    return (
      <div className="flex flex-wrap fit">
        {protocols.map((label) => (
          <div className="group relative mx-4 flex justify-start">
            <div
              className={`mb-1 mr-1 rounded-full text-optistream-txt-white px-2 py-0.5 w-fit h-fit bg-vulnCategory-Pivot`}
            >
              {label}
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="flex flex-row w-full h-full bg-white p-4">
      <div className="flex flex-col w-full px-4 justify-start items-center mt-4">
        <div className=" overflow-auto mt-4">
          <table className=" text-xs text-left text-black-light ">
            <thead className="text-xs text-optistream-txt-blue uppercase bg-gray-background">
              <tr className="">
                <th
                  scope="col"
                  className="px-2 py-2 items-center justify-center  w-12 text-center"
                >
                  Is secured
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 items-center justify-start w-32 text-center "
                >
                  Security class
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 items-center justify-start  w-40"
                >
                  To <br /> (Target)
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 items-center justify-start w-40"
                >
                  From <br /> (Zone of origin)
                </th>

                <th
                  scope="col"
                  className="px-2 py-2 text-center items-center justify-center w-72"
                >
                  Common app. protocols
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 text-center items-center justify-center w-72"
                >
                  Admin protocols
                </th>
              </tr>
            </thead>
            <tbody className="">
              {targetBusinessServicePaths.sort(sortRoutes).map((res, index) => (
                <tr key={index}>
                  <td className="px-2 py-2 w-12">
                    {securityLevelIsCritical(res.security_level) ? (
                      <div className="w-full flex items-center justify-center">
                        <XSquareFill width={20} height={20} color="crimson" />
                      </div>
                    ) : (
                      <div className="w-full flex items-center justify-center">
                        <CheckCircle width={20} height={20} color="green" />
                      </div>
                    )}
                  </td>
                  <td className="px-1 py-2 w-32 text-start font-bold">
                    {securityLevelToLabel(res.security_level)}
                  </td>

                  <td
                    scope="row"
                    className="px-2 py-2 w-40 font-medium whitespace-nowrap "
                  >
                    {nodeNameFromId(res.dst_node)}
                  </td>
                  <td
                    scope="row"
                    className="px-2 py-2 w-40 font-medium whitespace-nowrap "
                  >
                    {nodeNameFromId(res.src_node)}
                  </td>

                  <td className="px-2 py-2 w-72">
                    {displayServiceCategory(
                      res.application_protocols || [],
                      "Admin protocols"
                    )}
                  </td>
                  <td className="px-2 py-2 w-72">
                    {displayServiceCategory(
                      res.admin_protocols || [],
                      "Application protocols"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
