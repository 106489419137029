import { Position, MarkerType } from "reactflow";

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition,
  } = intersectionNode;
  const {
    width: intersectionNodeWidthTgt,
    height: intersectionNodeHeightTgt,
    positionAbsolute: intersectionNodePositionTgt,
  } = targetNode;
  const targetPosition = targetNode.positionAbsolute;

  const wSrc = intersectionNodeWidth / 2;
  const hSrc = intersectionNodeHeight / 2;
  const wTgt = intersectionNodeWidthTgt / 2; // to update
  const hTgt = intersectionNodeHeightTgt / 2; // idem

  const x2 = intersectionNodePosition.x + wSrc;
  const y2 = intersectionNodePosition.y + hSrc;
  const x1 = targetPosition.x + wTgt;
  const y1 = targetPosition.y + hTgt;

  const xx1 = (x1 - x2) / (wSrc + wTgt) - (y1 - y2) / (hSrc + hTgt);
  const yy1 = (x1 - x2) / (wSrc + wTgt) + (y1 - y2) / (hSrc + hTgt);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = wSrc * (xx3 + yy3) + x2;
  const y = hSrc * (-xx3 + yy3) + y2;

  return { x, y };
}

function getNodeIntersectionCircle(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidthSrc,
    positionAbsolute: intersectionNodePositionSrc,
  } = intersectionNode;
  const {
    width: intersectionNodeWidthTgt,
    height: intersectionNodeHeightTgt,
    positionAbsolute: intersectionNodePositionTgt,
  } = targetNode;

  const wSrc = intersectionNodeWidthSrc / 2;
  const hSrc = wSrc; // Source == Circle
  const wTgt = intersectionNodeWidthTgt / 2;
  const hTgt =
    targetNode.type === "group" ? intersectionNodeHeightTgt / 2 : wTgt;

  const x2 = intersectionNodePositionSrc.x + wSrc;
  const y2 = intersectionNodePositionSrc.y + hSrc;
  const x1 = intersectionNodePositionTgt.x + wTgt;
  const y1 = intersectionNodePositionTgt.y + hTgt;

  const d = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));

  const x = (hSrc / d) * (x1 - x2) + x2;
  const y = (hSrc / d) * (y1 - y2) + y2;

  return { x, y };
}
// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint =
    source.type === "group"
      ? getNodeIntersection(source, target)
      : getNodeIntersectionCircle(source, target);
  const targetIntersectionPoint =
    target.type === "group"
      ? getNodeIntersection(target, source)
      : getNodeIntersectionCircle(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

export function createNodesAndEdges() {
  const nodes = [];
  const edges = [];
  const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

  nodes.push({ id: "target", data: { label: "Target" }, position: center });

  for (let i = 0; i < 8; i++) {
    const degrees = i * (360 / 8);
    const radians = degrees * (Math.PI / 180);
    const x = 250 * Math.cos(radians) + center.x;
    const y = 250 * Math.sin(radians) + center.y;

    nodes.push({ id: `${i}`, data: { label: "Source" }, position: { x, y } });

    edges.push({
      id: `edge-${i}`,
      target: "target",
      source: `${i}`,
      type: "floating",
      markerEnd: {
        type: MarkerType.Arrow,
      },
    });
  }

  return { nodes, edges };
}

export function findEdge(map, node1, node2) {
  return map.edges.find(
    (edge) =>
      (edge.source === node1 && edge.target === node2) ||
      (edge.source === node2 && edge.target === node1)
  );
}
