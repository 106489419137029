import { useState } from "react";

export default function NodeOverlayFormISP({
  editNode,
  setEditNode,
  setRecomputeMapWhenUpdate,
}) {
  const [ASNumber, setASNumber] = useState(editNode.data.ASNumber || "");

  return (
    <fieldset className="border border-gray-100 p-2 h-full">
      <legend className="font-bold">AS configuration</legend>
      <table className="w-full table-fixed  border-separate border-spacing-y-2">
        <tr>
          <td>
            <label for="ipAddress" className="text-gray-base">
              AS
            </label>
          </td>
          <td>
            <input
              id="ipAddress"
              type="text"
              pattern="[0-9]*"
              placeholder="E.g. 65200"
              className="text-sm text-gray-base w-full p-2 border border-gray-primary rounded disabled:bg-gray-200"
              value={ASNumber}
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              onChange={(evt) => {
                setASNumber(evt.target.value);
              }}
              onBlur={() => {
                if (ASNumber === editNode.data.ASNumber) {
                  return;
                }
                setRecomputeMapWhenUpdate(true);
                setEditNode({
                  ...editNode,
                  data: {
                    ...editNode.data,
                    ASNumber: ASNumber,
                  },
                });
              }}
            />
          </td>
        </tr>
      </table>
    </fieldset>
  );
}
