import { NavLink } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import { navData } from "./navData";
import { useState, useEffect } from "react";

import { useMsal } from "@azure/msal-react";

export default function Sidenav() {
  const [open, setopen] = useState(false);

  useEffect(() => {
    document.title = "optistream";
  }, []);

  const toggleOpen = () => {
    setopen(!open);
  };

  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "https://optistream.io",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  return (
    <div
      className={
        open
          ? "w-60 h-screen bg-blue-optistream pt-7 ease-in-out duration-300 flex flex-col justify-between"
          : "w-14 h-screen bg-blue-optistream pt-7 ease-in-out duration-300 items-start flex flex-col justify-between"
      }
    >
      <div className="w-full">
        <button
          className="text-optistream-txt-white cursor-pointer px-4 pt-6 mb-4"
          onClick={toggleOpen}
        >
          {open ? (
            <KeyboardDoubleArrowLeftIcon />
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </button>

        {navData.map((item, index) => {
          return (
            <>
              {item.type === "separator" ? (
                <hr key={index} className="w-4/5 mx-auto my-5" />
              ) : (
                <NavLink
                  key={item.id}
                  className="flex items-center py-4 px-4 cursor-pointer text-optistream-txt-white overflow-hidden hover:bg-orange-optistream whitespace-nowrap"
                  to={item.link}
                >
                  {item.icon}
                  <span className="pl-4">{item.text}</span>
                </NavLink>
              )}
            </>
          );
        })}
        <hr className="w-4/5 mx-auto my-5" />
        <button
          className="flex items-center py-4 px-4 cursor-pointer text-optistream-txt-white overflow-hidden hover:bg-orange-optistream whitespace-nowrap w-full"
          onClick={() => handleLogout("popup")}
        >
          <LogoutIcon />
          <span className="pl-4">Logout</span>
        </button>
      </div>
      <div className="flex w-full justify-center pb-12">
        <NavLink to="https://optistream.io" aria-label="optistream logo">
          <div className="">
            {open ? (
              <img
                src="/images/logo_orange_white.png"
                alt="optistream"
                className="mt-1 h-6 px-4 cursor-pointer  "
              />
            ) : (
              <img
                src="/images/favicon_white.png"
                alt="optistream"
                className="mt-1 max-h-6 px-4 cursor-pointer"
              />
            )}
          </div>
        </NavLink>
      </div>
    </div>
  );
}
