import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import * as ROUTES from "./constants/routes";

//import DragDrop from './pages/networkMap/DragDrop';
//import NetworkMap from "./pages/map/networkMap";

import Logout from "./pages/components/misc/logout";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import Login from "./pages/login";
import AnalysisHistory from "./pages/analysisHistory";
import BusinessServices from "./pages/configBusinessServices";
import Sidenav from "./pages/components/sidemenu/sidenav";
import Home from "./pages/home";
import Map from "./pages/map";
import Infiltration from "./pages/infiltration";
import InfiltrationDetails from "./pages/infiltrationDetails";
import HighRiskRoutes from "./pages/highRiskRoutes";
import HighRiskRoutesDetails from "./pages/highRiskRoutesDetails";
import BusinessCockpitPage from "./pages/businessSecurityCockpit";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  return (
    <>
      {isAuthenticated ? (
        <>
          <BrowserRouter>
            <Suspense fallback={<p> Loading...</p>}>
              <div className="flex">
                <Sidenav />
                <Routes>
                  <Route path={ROUTES.HOME} element={<Home />} />
                  <Route
                    path={`${ROUTES.MAP}/:workspaceId`}
                    element={<Map />}
                  />
                  <Route
                    path={`${ROUTES.INFILTRATION}/:workspaceId`}
                    element={<Infiltration />}
                  />
                  <Route
                    path={`${ROUTES.INFILTRATION_DETAILS}/:analysisId`}
                    element={<InfiltrationDetails />}
                  />
                  <Route
                    path={`${ROUTES.RISKY_ROUTES}/:workspaceId`}
                    element={<HighRiskRoutes />}
                  />
                  <Route
                    path={`${ROUTES.RISKY_ROUTES_DETAILS}/:analysisId`}
                    element={<HighRiskRoutesDetails />}
                  />
                  <Route
                    path={`${ROUTES.ANALYSIS_HISTORY}`}
                    element={<AnalysisHistory />}
                  />
                  <Route
                    path={`${ROUTES.BUSINESS_COCKPIT}`}
                    element={<BusinessCockpitPage />}
                  />
                  <Route
                    path={`${ROUTES.CONFIG_BUSINESS_SERVICE}`}
                    element={<BusinessServices />}
                  />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="*" element={<Navigate to="/" />} />
                  {/* TODO : Add 404 */}
                </Routes>
              </div>
            </Suspense>
          </BrowserRouter>
        </>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
