import React from "react";

export function Frame({ children, onClose = () => {} }) {
  return (
    <div className="fixed inset-0 z-10 p-8 text-optistream-txt-white bg-gray-primary/75 block">
      {/* container: `max-w-sm` to make it reasonably narrow, mx - auto` to center horizontally */}
      <div className="relative w-full h-2/3 max-w-6xl  mx-auto mt-8">
        {/* closer in the corner */}
        <button
          className="absolute -top-2 -right-2 flex justify-center rounded-full h-8 w-8 
                    bg-optistream-blue cursor-pointer shadow-xl"
          onClick={() => onClose()}
        >
          <span className="text-2xl leading-7 select-none">&times;</span>
        </button>
        {/* contents */}
        <div className="flex flex-col h-full bg-gray-background rounded shadow-xl">
          {children}
        </div>
      </div>
    </div>
  );
}

export const Head = ({ children }) => (
  <div className="block p-4 bg-optistream-blue">
    <h1 className="text-lg">{children}</h1>
  </div>
);

export const Body = ({ children }) => (
  <div className="p-4 h-full overflow-auto">{children}</div>
);
