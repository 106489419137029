import React, { useEffect, useState } from "react";
import * as Modal from "../Modal/modal";
import Select from "react-select";
import { useAlert } from "react-alert";

import ConfirmDialog from "../misc/confirmDialog";
import { CirclePicker } from "react-color";

export default function GroupNodeOverlay({
  editNode,
  setEditNode,
  onCloseCallback,
  deleteNode,
}) {
  const equipmentTypeList = [
    { value: "VLAN", label: "VLAN" },
    { value: "Subnet", label: "Subnet" },
    { value: "Custom group", label: "Custom group" },
  ];

  const [displayName, setDisplayName] = useState(
    editNode.data.DisplayName || ""
  );
  const [description, setDescription] = useState(editNode.data?.Description);
  const alert = useAlert();

  const deleteCurrentNode = () => {
    deleteNode(editNode);
    onCloseCallback();
    alert.success("Element successfully deleted");
  };

  const formatColorString = (color, a) =>
    `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${a})`;

  const handleColorChange = (color) => {
    setEditNode({
      ...editNode,
      style: {
        ...editNode.style,
        zIndex: -10,
        backgroundColor: formatColorString(color, 0.2),
        border: "none",
        borderColor: formatColorString(color, 0.0),
      },
      data: {
        ...editNode.data,
        Color: color.hex,
      },
    });
  };

  return (
    <Modal.Frame
      onClose={() => {
        onCloseCallback();
      }}
    >
      <Modal.Head>Group view</Modal.Head>
      <Modal.Body>
        <div className="flex flex-col text-gray-base w-full h-full">
          <div className="grow">
            <div className="flex flex-row h-full space-x-1">
              <div className="w-1/2">
                <fieldset className="border border-gray-100 p-2 h-full">
                  <legend className="font-bold">General information</legend>
                  <table className="w-full table-fixed border-separate border-spacing-y-2">
                    <tr>
                      <td>
                        <label htmlFor="displayname" className="text-gray-base">
                          Display name:
                        </label>
                      </td>
                      <td>
                        <input
                          type="text"
                          id="displayname"
                          value={displayName}
                          onChange={(evt) => {
                            setDisplayName(evt.target.value);
                          }}
                          onBlur={() => {
                            if (displayName === editNode.data.DisplayName) {
                              return;
                            }
                            setEditNode({
                              ...editNode,
                              data: {
                                ...editNode.data,
                                DisplayName: displayName,
                              },
                            });
                          }}
                          className="text-sm text-gray-base w-full p-2 border border-gray-primary rounded disabled:bg-gray-200"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="groupType" className="text-gray-base">
                          Group type:
                        </label>
                      </td>
                      <td>
                        {" "}
                        <Select
                          id="groupType"
                          options={equipmentTypeList}
                          className="text-sm"
                          name="groupType"
                          value={{
                            label: editNode.data.GroupType,
                            value: editNode.data.GroupType,
                          }}
                          onChange={(selectedOption) => {
                            if (
                              selectedOption.value === editNode.data.GroupType
                            )
                              return;

                            setEditNode({
                              ...editNode,
                              data: {
                                ...editNode.data,
                                GroupType: selectedOption.value,
                              },
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="description" className="text-gray-base">
                          Description:
                        </label>
                      </td>
                      <td>
                        <textarea
                          id="description"
                          placeholder="What is the group about?"
                          rows={7}
                          className="whitespace-pre-line bg-white grow my-1 w-full"
                          value={description}
                          onChange={(evt) => {
                            setDescription(evt.target.value);
                          }}
                          onBlur={() => {
                            if (description === editNode.data?.Description)
                              return;

                            setEditNode({
                              ...editNode,
                              data: {
                                ...editNode.data,
                                Description: description,
                              },
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </table>
                </fieldset>
              </div>
              <div className="w-1/2">
                <fieldset className="border border-gray-100 p-2 h-full">
                  <legend className="font-bold">Background color</legend>
                  <div className="h-full flex justify-center items-center">
                    <CirclePicker
                      color={editNode.data.Color}
                      onChangeComplete={handleColorChange}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end items-center pt-2">
            <button
              className="mt-2 bg-red-primary text-optistream-txt-white rounded h-8 font-bold text-sm w-24 disabled:opacity-50"
              disabled={editNode.data.NodeComputed === true}
              title={
                editNode.data.NodeComputed === true
                  ? "This node was automatically imported and cannot be deleted"
                  : null
              }
              onClick={() =>
                ConfirmDialog(
                  "You are about to definitely delete your element.",
                  deleteCurrentNode
                )
              }
            >
              Delete
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal.Frame>
  );
}
