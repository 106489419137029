import React, { memo, useState } from "react";
import {
  Handle,
  Position,
  useStore,
  getConnectedEdges,
  useReactFlow,
  NodeToolbar,
} from "reactflow";

import {
  Wifi,
  Bricks,
  HddNetwork,
  Check2Circle,
  FileEarmarkX,
  ArrowsFullscreen,
  Ethernet as EthernetIcon,
  Globe,
} from "react-bootstrap-icons";

// VPC
// Subnet
// IGW
import VPC from "react-aws-icons/dist/aws/compute/VPC";
import VPCSubnet from "react-aws-icons/dist/aws/compute/VPCSubnet";
import InternetGateway from "react-aws-icons/dist/aws/compute/InternetGateway";
import Internet from "react-aws-icons/dist/aws/general/Internet";
import Router from "react-aws-icons/dist/aws/compute/Router";
import { AwsTransitGateway } from "../../../icons/awsTransitGateway";
import { DisplayMapContext } from "./displayMapContext";
import { useAlert } from "react-alert";

const nodeTypeImage = {
  Switch: <HddNetwork />,
  Router: <ArrowsFullscreen />,
  Firewall: <Bricks />,
  Wifi: <Wifi />,
  // AWS
  aws_vpc: <VPC size={48} />,
  aws_subnet: <VPCSubnet size={60} />,
  aws_igw: <InternetGateway size={52} />,
  router: <Router size={48} />,
  aws_tgw: <AwsTransitGateway />,
  // Internet
  internet: <Globe size={40} className="text-gray-500" />,
  isp: <Internet size={48} />,
};

const connectionNodeIdSelector = (state) => state.connectionNodeId;
const ALERT_TIME_DELAY = 3000;
const TIME_OUT_DELAY = 1500;

function NetworkNode({ id, data }) {
  const alert = useAlert();
  var lastAlert = 0;

  const connectionNodeId = useStore(connectionNodeIdSelector);

  const isTarget = connectionNodeId && connectionNodeId !== id;
  const targetHandleStyle = {
    zIndex: isTarget ? 15 : -1,
    height: "120%",
    width: "120%",
    opacity: 0,
    position: "absolute",
    // right: "50%",
    // top: "0%",
    // transform: "translate(0%,0%)",
  };
  const reactFlowInstance = useReactFlow();
  var isHover = false;
  const [show, setShow] = useState(false);

  const isValidConnection = (connection) => {
    const targetNode = reactFlowInstance.getNode(connection.target);
    const edgesConnectionTarget = getConnectedEdges(
      [targetNode],
      reactFlowInstance.getEdges()
    );

    if (targetNode.type === "group" && edgesConnectionTarget.length) {
      if (Date.now() - lastAlert > ALERT_TIME_DELAY) {
        alert.info("Group is already connected to a network node");
        lastAlert = Date.now();
      }
      return false;
    }

    for (const elt of edgesConnectionTarget)
      if (elt.target === connection.source) {
        return false;
      }

    return true;
  };

  return (
    <DisplayMapContext.Consumer>
      {({ displayInterface, showAudit, attackPath }) => (
        <div
          className={`w-16 h-16 rounded-full flex items-center justify-center
        hover:shadow-lg  bg-white border-2 relative z-0
        ${!showAudit && data.Computed && "border-green-500"}
        ${!showAudit && !data.Computed && "border-gray-500"}
        
        `}
          style={{
            borderStyle: isTarget ? "dashed" : "solid",
            backgroundColor: isTarget ? "#FED4BA" : "#fbfbfb",
          }}
          onMouseEnter={() => {
            isHover = true;
            setTimeout(() => {
              if (isHover) setShow(true);
            }, TIME_OUT_DELAY);
          }}
          onMouseLeave={() => {
            isHover = false;
            setShow(false);
          }}
        >
          <div className="flex flex-col items-center align-middle w-full h-full">
            {!showAudit ? (
              data.Computed ? (
                <button
                  className="absolute -top-0.5 -right-0.5 flex justify-center 
                        rounded-full h-5 w-5 p-1 items-center align-middle
                        bg-white cursor-help shadow-xl"
                  title="Configuration uploaded"
                >
                  <span className="text-optistream-txt-green">
                    <Check2Circle />
                  </span>
                </button>
              ) : (
                <button
                  className="absolute -top-0.5 -right-0.5 flex justify-center 
                            rounded-full h-6 w-6 p-1 items-center align-middle
                            border border-gray-500 bg-white cursor-help shadow-xl"
                  title="Configuration is missing"
                >
                  <span className="text-gray-500">
                    <FileEarmarkX />
                  </span>
                </button>
              )
            ) : null}

            <div
              className="w-full h-full flex justify-center items-center 
                                 text-optistream-txt-blue text-3xl"
            >
              {nodeTypeImage[data.Type ? data.Type : data.EquipmentType]}{" "}
              {/*data.Type == legacy */}
            </div>
          </div>
          <div
            className={`h-30 w-30 absolute -bottom-5 border border-gray-500 
            justify-center text-sm p-1.5 border rounded-full text-gray-800 ${
              isTarget ? "bg-green-300" : "bg-white"
            }`}
          >
            <EthernetIcon />
            <Handle
              className="targetHandle"
              style={targetHandleStyle}
              position={Position.Top}
              isValidConnection={isValidConnection}
              type="target"
            />

            <Handle
              // className="sourceHandle"
              style={
                !showAudit
                  ? {
                      zIndex: 2,
                      width: "150%",
                      height: "150%",
                      backgroundColor: "transparent",
                      border: "none",
                    }
                  : { zIndex: -1, opacity: 0 }
              }
              position={Position.Right}
              isValidConnection={isValidConnection}
              isConnectableStart={!showAudit}
              type="source"
            />
          </div>
          {show && (
            <NodeToolbar isVisible={show} position={Position.Right}>
              <span className="absolute w-64 rounded bg-gray-800 p-2 text-sm text-optistream-txt-white ">
                <ul className="marker:text-sky-400 list-disc pl-5 space-y-1 text-slate-50">
                  <li>
                    <span className="font-bold">Hostname:</span> {data.HostName}
                  </li>
                  <li>
                    <span className="font-bold">Vendor:</span>{" "}
                    {data.Vendor ? data.Vendor : "-"}
                  </li>
                  <li>
                    <span className="font-bold">Type:</span>{" "}
                    {data.DisplayName ? data.DisplayName : "-"}
                  </li>
                </ul>
              </span>
            </NodeToolbar>
          )}
        </div>
      )}
    </DisplayMapContext.Consumer>
  );
}

export default memo(NetworkNode);
