export function AwsTransitGateway() {
  return (
    <svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1">
      <defs>
        <linearGradient
          x1="0%"
          y1="100%"
          x2="100%"
          y2="0%"
          id="linearGradient-1"
        >
          <stop stopColor="#4D27A8" offset="0%"></stop>
          <stop stopColor="#A166FF" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Icon-Architecture/64/Arch_AWS-Transit-Gateway_64"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M43.23,64.784 C44.303,63.866 45,62.519 45,61 C45,58.585 43.279,56.566 41,56.101 L41,47.93 C44.611,47.477 47.476,44.611 47.93,41 L56.101,41 C56.565,43.279 58.585,45 61,45 C62.519,45 63.866,44.304 64.784,43.23 C63.331,54.435 54.435,63.331 43.23,64.784 M15.215,43.23 C16.133,44.304 17.48,45 19,45 C21.414,45 23.434,43.279 23.898,41 L32.069,41 C32.523,44.611 35.388,47.477 39,47.93 L39,56.101 C36.72,56.566 35,58.585 35,61 C35,62.519 35.696,63.866 36.769,64.784 C25.564,63.331 16.668,54.435 15.215,43.23 M22,40 C22,41.654 20.654,43 19,43 C17.345,43 16,41.654 16,40 C16,38.346 17.345,37 19,37 C20.654,37 22,38.346 22,40 M36.769,15.216 C35.696,16.134 35,17.48 35,19 C35,21.414 36.72,23.434 39,23.899 L39,32.069 C35.388,32.523 32.523,35.388 32.069,39 L23.898,39 C23.434,36.721 21.414,35 19,35 C17.48,35 16.133,35.696 15.215,36.769 C16.668,25.564 25.564,16.669 36.769,15.216 M40,16 C41.654,16 43,17.346 43,19 C43,20.654 41.654,22 40,22 C38.345,22 37,20.654 37,19 C37,17.346 38.345,16 40,16 M40,46 C36.691,46 34,43.308 34,40 C34,36.691 36.691,34 40,34 C43.308,34 46,36.691 46,40 C46,43.308 43.308,46 40,46 M40,64 C38.345,64 37,62.654 37,61 C37,59.346 38.345,58 40,58 C41.654,58 43,59.346 43,61 C43,62.654 41.654,64 40,64 M64,40 C64,41.654 62.654,43 61,43 C59.345,43 58,41.654 58,40 C58,38.346 59.345,37 61,37 C62.654,37 64,38.346 64,40 M64.784,36.769 C63.866,35.696 62.519,35 61,35 C58.585,35 56.565,36.721 56.101,39 L47.93,39 C47.476,35.388 44.611,32.523 41,32.069 L41,23.899 C43.279,23.434 45,21.414 45,19 C45,17.48 44.303,16.134 43.23,15.216 C54.435,16.669 63.331,25.564 64.784,36.769 M40,13 C25.112,13 13,25.112 13,40 C13,54.888 25.112,67 40,67 C54.887,67 67,54.888 67,40 C67,25.112 54.887,13 40,13"
          id="AWS-Transit-Gateway_Icon_64_Squid"
          fill="#ff9900"
        ></path>
      </g>
    </svg>
  );
}
