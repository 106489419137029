import React, { useState } from "react";
import { useAlert } from "react-alert";
import * as Modal from "../Modal/modal";
import NodeOverlayTabGeneral from "./nodeOverlay/nodeOverlayTabGeneral";
import NodeOverlayTabRawConfig from "./nodeOverlay/nodeOverlayTabRawConfig";
import NodeOverlayTabACLs from "./nodeOverlay/nodeOverlayTabACLs";
import NodeOverlayTabNotes from "./nodeOverlay/nodeOverlayTabNotes";
import NodeOverlayTabApplications from "./nodeOverlay/nodeOverlayTabApplications";

export default function NodeOverlay({
  editNode,
  setEditNode,
  onCloseCallback,
  deleteNode,
  workspaceId,
  snapshotId,
  setRecomputeMapWhenUpdate,
  setCurrentSnapshotStatus,
}) {
  const alert = useAlert();

  const [modalTab, setModalTab] = useState("GENERAL");

  const deleteCurrentNode = () => {
    setRecomputeMapWhenUpdate(true);
    deleteNode(editNode);
    onCloseCallback();
    alert.success("Element successfully deleted");
  };

  const hasACLs = !(
    editNode.data.EquipmentType === "isp" ||
    editNode.data.EquipmentType === "internet"
  );

  const hasConfigFile = !(
    editNode.data.EquipmentType === "isp" ||
    editNode.data.EquipmentType === "internet"
  );

  return (
    <Modal.Frame
      onClose={() => {
        onCloseCallback();
      }}
    >
      <Modal.Head>Node view</Modal.Head>
      <Modal.Body>
        <div className="flex flex-col w-full h-full">
          <div className="flex flew-row pb-4 cursor-pointer text-sm text-gray-base font-bold space-x-6 uppercase">
            <span
              className={modalTab === "GENERAL" ? "text-blue-medium" : null}
              onClick={() => setModalTab("GENERAL")}
            >
              General
            </span>
            {editNode.type === "terminal" ? (
              <span
                className={modalTab === "APPS" ? "text-blue-medium" : null}
                onClick={() => setModalTab("APPS")}
              >
                Applications
              </span>
            ) : null}
            {hasConfigFile ? (
              <span
                className={modalTab === "CONFIG" ? "text-blue-medium" : null}
                onClick={() => setModalTab("CONFIG")}
              >
                Config file
              </span>
            ) : null}
            {hasACLs ? (
              <span
                className={modalTab === "ACLS" ? "text-blue-medium" : null}
                onClick={() => setModalTab("ACLS")}
              >
                ACLS
              </span>
            ) : null}
            <span
              className={modalTab === "NOTES" ? "text-blue-medium" : null}
              onClick={() => setModalTab("NOTES")}
            >
              Notes
            </span>
          </div>
          <div className="flex flex-row w-full grow">
            {modalTab === "GENERAL" ? (
              <NodeOverlayTabGeneral
                editNode={editNode}
                setEditNode={setEditNode}
                deleteCurrentNode={deleteCurrentNode}
                setRecomputeMapWhenUpdate={setRecomputeMapWhenUpdate}
              />
            ) : modalTab === "APPS" ? (
              <NodeOverlayTabApplications
                editNode={editNode}
                setEditNode={setEditNode}
              />
            ) : modalTab === "CONFIG" ? (
              <NodeOverlayTabRawConfig
                editNode={editNode}
                setRecomputeMapWhenUpdate={setRecomputeMapWhenUpdate}
                setCurrentSnapshotStatus={setCurrentSnapshotStatus}
                workspaceId={workspaceId}
                snapshotId={snapshotId}
              />
            ) : modalTab === "ACLS" ? (
              <NodeOverlayTabACLs
                workspaceId={workspaceId}
                snapshotId={snapshotId}
                editNode={editNode}
              />
            ) : modalTab === "NOTES" ? (
              <NodeOverlayTabNotes
                editNode={editNode}
                setEditNode={setEditNode}
              />
            ) : null}
          </div>
        </div>
      </Modal.Body>
    </Modal.Frame>
  );
}
