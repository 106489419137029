import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  ReactFlowProvider,
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
  Controls,
  MiniMap,
  Panel,
} from "reactflow";

import { securityLevelToRate } from "./utils";

import NoteNode from "../map/noteNodeObject";
import NetworkNode from "../map/networkNodeObject";
import GroupNode from "../map/groupNodeObject";
import TerminalNode from "../map/terminalNodeObject";
import FloatingEdge from "../map/connectionObject";
import { DisplayMapContext } from "../map/displayMapContext";
import { findEdge } from "../map/reactflowUtils";

import { nodeDisplayName } from "../misc/utils";

const nodeTypes = {
  networkNode: NetworkNode,
  core: NetworkNode,
  terminal: TerminalNode,
  note: NoteNode,
  group: GroupNode,
};

const edgeTypes = {
  floating: FloatingEdge,
};

const defaultEdgeOptions = {
  style: { strokeWidth: 1, stroke: "black" },
  type: "floating",
};

export default function DetailServiceMap({
  analysis,
  businessService,
  targetBusinessServicePaths,
}) {
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const map = JSON.parse(analysis.snapshot.map);

  const [nodes, setNodes, onNodesChange] = useNodesState(map.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(map.edges);

  const [isDraggable, setIsDraggable] = useState(false);
  const [isSelectable, setIsSelectable] = useState(true);
  const [isConnectable, setIsConnectable] = useState(false);
  const [zoomOnScroll, setZoomOnScroll] = useState(false);
  const [panOnScroll, setPanOnScroll] = useState(false);
  const [panOnScrollMode, setPanOnScrollMode] = useState("free");
  const [zoomOnDoubleClick, setZoomOnDoubleClick] = useState(false);
  const [panOnDrag, setpanOnDrag] = useState(true);
  const [captureZoomClick, setCaptureZoomClick] = useState(false);
  const [captureZoomScroll, setCaptureZoomScroll] = useState(false);
  const [captureElementClick, setCaptureElementClick] = useState(false);

  const [selectedPath, setSelectedPath] = useState("");
  const [mapDisplayValue, setMapDisplayValue] = useState({
    displayInterface: false,
    showAudit: true,
    attackPath: {},
  });

  const pairwise = (a) => a.slice(1).map((k, i) => [a[i], k]);

  const pathWithRoutes = targetBusinessServicePaths.filter(
    (p) => p.route && securityLevelToRate(p.security_level) <= 1
  );

  const onChangeAttackPath = (event) => {
    const auditedPathIndex = event.target.value;

    const auditedPath = pathWithRoutes[auditedPathIndex];
    const route = pathWithRoutes[auditedPathIndex].route;

    setMapDisplayValue({
      ...mapDisplayValue,
      attackPath: {
        sourceId: auditedPath.src_node,
        destinationId: auditedPath.dst_node,
      },
    });

    const animatedEdgeIds = pairwise(route).reduce((acc, [from, to]) => {
      var edge = findEdge(map, from, to);
      if (edge) {
        acc.push(edge.id);
      }
      return acc;
    }, []);
    const targetEdges = edges.map((edge) =>
      animatedEdgeIds.includes(edge.id)
        ? {
            ...edge,
            animated: true,
          }
        : { ...edge, animated: false }
    );

    setEdges(targetEdges);
    setSelectedPath(event.target.value);
  };

  const nodeNameFromId = (nodeId) => {
    const node = map.nodes.find((node) => node.id === nodeId);

    return nodeDisplayName(node);
  };

  return (
    <div className="flex w-full h-full bg-white dndflow">
      <DisplayMapContext.Provider value={mapDisplayValue}>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            defaultEdgeOptions={defaultEdgeOptions}
            onDragOver={onDragOver}
            elementsSelectable={isSelectable}
            nodesConnectable={isConnectable}
            nodesDraggable={isDraggable}
            zoomOnScroll={zoomOnScroll}
            panOnScroll={panOnScroll}
            panOnScrollMode={panOnScrollMode}
            zoomOnDoubleClick={zoomOnDoubleClick}
            panOnDrag={panOnDrag}
            className="bg-teal-50"
            fitView
          >
            <Background color="#99b3ec" variant="dots" />
            <Controls />

            <MiniMap nodeStrokeWidth={3} zoomable pannable />

            <Panel position="top-left">
              <div>
                <label className="text-sm font-medium">
                  Select the attack path you want to analyze
                </label>
              </div>
              <div>
                <label>
                  <select
                    id="attackPathSelect"
                    value={selectedPath}
                    placeholder="Test"
                    onChange={(event) => onChangeAttackPath(event)}
                    className="border border-blue-medium mt-1 p-1 rounded-md w-64"
                  >
                    <option value="" disabled selected>
                      {pathWithRoutes.length
                        ? "Select a path"
                        : "No risky route identified"}
                    </option>
                    {pathWithRoutes.map((auditedPath, auditedPathIndex) => (
                      <option key={auditedPathIndex} value={auditedPathIndex}>
                        {nodeNameFromId(auditedPath.src_node)} -{" "}
                        {nodeNameFromId(auditedPath.dst_node)}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mt-4">
                <span className="rounded-md border-4 border-green-300 py-1 px-2 text-sm">
                  Origin
                </span>
                <span className="rounded-md border-4 border-blue-medium py-1 px-2 text-sm ml-2">
                  Destination
                </span>
                <span className="rounded-md border-4 border-red-300 py-1 px-2 text-sm ml-2 ">
                  Pivots
                </span>
              </div>
            </Panel>
          </ReactFlow>
        </ReactFlowProvider>
      </DisplayMapContext.Provider>
    </div>
  );
}
