import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./components/header/header";
import Select from "react-tailwindcss-select";
import Creatable from "react-select/creatable";
import * as Utils from "./components/misc/utils";
import Spinner from "./components/misc/spinner";
import { useAPI } from "../utils/API";

import { Command } from "react-bootstrap-icons";

import ConfirmDialog from "./components/misc/confirmDialog";

import { Pencil } from "react-bootstrap-icons";

export default function BusinessServices() {
  const [newBusinessServiceName, setNewBusinessServiceName] = useState("");
  const [newBusinessServiceDomain, setNewBusinessServiceDomain] = useState("");
  const [newBusinessServiceDescription, setNewBusinessServiceDescription] =
    useState("");
  const [newBusinessServiceWorseImpact, setNewBusinessServiceWorseImpact] =
    useState("");
  const [newBusinessServiceSensitivity, setNewBusinessServiceSensitivity] =
    useState(null);

  const [businessServices, setBusinessServices] = useState(null);
  const [editBusinessServiceId, setEditBusinessServiceId] = useState(null);
  const [businessDomainOptions, setBusinessDomainOptions] = useState([]);

  const [editAppProcess, setEditAppProcess] = useState(null);
  const [newAppName, setNewAppName] = useState("");
  const [newAppDescription, setNewAppDescription] = useState("");
  const [processApps, setProcessApps] = useState([]);

  const [editApplication, setEditApplication] = useState(null);
  const [editApplicationInfo, setEditApplicationInfo] = useState(-1);

  const isInvalidBusinessService =
    newBusinessServiceDomain === "" ||
    newBusinessServiceName === "" ||
    newBusinessServiceDescription === "" ||
    newBusinessServiceSensitivity === null ||
    newBusinessServiceWorseImpact === "";

  const isInvalidApp = newAppName === "" || newAppDescription === "";
  const {
    listBusinessServices,
    createBusinessService,
    updateBusinessService,
    createApplication,
    deleteApplication,
    updateApplication,
    listApplications,
    deleteBusinessService,
  } = useAPI();

  const setEditBusinessService = (businessService) => {
    setNewBusinessServiceName(businessService.name);
    setNewBusinessServiceDomain(
      businessDomainOptions.filter(
        (element) => element.label === businessService.business_domain
      )[0]
    );
    setNewBusinessServiceSensitivity(
      Utils.businessSensitivityLevels[businessService.sensitivity]
    );
    setNewBusinessServiceDescription(businessService.description);
    setNewBusinessServiceWorseImpact(businessService.impact);
    setEditBusinessServiceId(businessService.id);
  };

  useEffect(() => {
    if (businessServices !== null) {
      const businessDomainsSet = Object.keys(businessServices).filter(
        (element) => element !== ""
      );
      setBusinessDomainOptions(
        businessDomainsSet.map(
          (domain, index) => new Object({ label: domain, value: index })
        )
      );
    }
  }, [businessServices]);

  useEffect(() => {
    if (editAppProcess !== null) {
      listApplications(editAppProcess.id)
        .then(setProcessApps)
        .catch((e) => void e);
    }
  }, [editAppProcess]);

  useEffect(() => {
    listBusinessServices()
      .then(Utils.groupBusinessServicesByDomain)
      .then(setBusinessServices)
      .catch((e) => void e);
  }, []);

  const createNewBusinessService = () => {
    const params = {
      name: newBusinessServiceName,
      sensitivity: newBusinessServiceSensitivity.value,
      description: newBusinessServiceDescription,
      impact: newBusinessServiceWorseImpact,
      business_domain: newBusinessServiceDomain.label,
    };
    createBusinessService(params)
      .then(() => {
        listBusinessServices()
          .then(Utils.groupBusinessServicesByDomain)
          .then(setBusinessServices)
          .catch((e) => void e);
        setNewBusinessServiceName("");
        setNewBusinessServiceDescription("");
        setNewBusinessServiceWorseImpact("");
        setNewBusinessServiceSensitivity(null);
      })
      .catch((e) => void e);
  };

  const createApplicationCallback = () => {
    const params = {
      name: newAppName,
      description: newAppDescription,
    };

    createApplication(editAppProcess.id, params)
      .then(() => {
        listApplications(editAppProcess.id)
          .then(setProcessApps)
          .catch((e) => void e);
        setNewAppName("");
        setNewAppDescription("");
      })
      .catch((e) => void e);
  };

  const deleteApplicationCallback = (bsId, appId) => {
    //TODO
    deleteApplication(bsId, appId)
      .then(() => {
        listApplications(editAppProcess.id)
          .then(setProcessApps)
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  };

  const setEditApplicationFromIndex = (index) => {
    const application = processApps[index];
    setNewAppName(application.name);
    setNewAppDescription(application.description);
    setEditApplication(application);
  };
  const AppcloseEdits = () => {
    setNewAppName("");
    setNewAppDescription("");
    setEditApplication(null);
  };

  const saveApplicationEdit = () => {
    const params = {
      name: newAppName,
      description: newAppDescription,
    };

    updateApplication(editBusinessServiceId, editApplication.id, params)
      .then(() => {
        listApplications(editAppProcess.id)
          .then(setProcessApps)
          .catch((e) => console.error(e));
        alert.success("Update successfully saved");
        AppcloseEdits();
      })
      .catch((e) => console.error(e));
  };

  const getAppDataByBusinessService = async (businessServiceId) => {
    listApplications(businessServiceId).then(setProcessApps);
  };

  const deleteBS = (id) => {
    deleteBusinessService(id)
      .then(() => {
        listBusinessServices()
          .then(Utils.groupBusinessServicesByDomain)
          .then(setBusinessServices)
          .catch((e) => void e);
      })
      .catch((e) => void e);
  };

  const closeEdits = () => {
    setNewBusinessServiceName("");
    setNewBusinessServiceSensitivity(null);
    setNewBusinessServiceDescription("");
    setNewBusinessServiceWorseImpact("");
    setEditBusinessServiceId(null);
    setNewBusinessServiceDomain(null);
  };

  const saveBusinessServiceEdit = () => {
    const params = {
      name: newBusinessServiceName,
      sensitivity: newBusinessServiceSensitivity.value,
      description: newBusinessServiceDescription,
      business_domain: newBusinessServiceDomain.label,
      impact: newBusinessServiceWorseImpact,
    };
    updateBusinessService(editBusinessServiceId, params)
      .then(() => {
        listBusinessServices()
          .then(Utils.groupBusinessServicesByDomain)
          .then(setBusinessServices)
          .catch((e) => void e);
        alert.success("Update successfully saved");
        closeEdits();
      })
      .catch((e) => void e);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-background w-full p-12 justify-center">
      <div className="flex flex-row h-full w-full justify-between">
        {editAppProcess ? (
          <div className="flex flex-col h-full w-1/2 bg-white mr-4 p-4 shrink-0">
            <div className="flex p-2 text-optistream-txt-blue font-bold text-base uppercase mb-2">
              Manage application for process:{" "}
              <span className="text-optistream-txt-orange ml-2">
                {editAppProcess.name}
              </span>
            </div>
            <div className="flex flex-col w-full justify-start items-center ml-2 h-full py-4">
              <div className="flex flex-col justify-evenly items-center h-1/2 w-full">
                <div className="flex flex-row items-center mb-2 w-full">
                  <div className="block text-gray-base text-sm font-bold align-center w-48 ">
                    Application name
                  </div>

                  <input
                    aria-label="Enter your new application name"
                    type="text"
                    placeholder="Enter your new application name"
                    className="text-sm text-gray-base w-96 py-2 px-4 h-10 border border-gray-primary rounded mb-1"
                    onChange={({ target }) => setNewAppName(target.value)}
                    value={newAppName}
                  />
                </div>

                <div className="flex flex-row items-start mb-2 w-full">
                  <div className="block text-gray-base text-sm font-bold align-center w-48">
                    Application description
                  </div>
                  <div>
                    <textarea
                      placeholder="Application description"
                      rows={4}
                      className="text-sm text-gray-base resize-none flex w-96 mt-1 px-4 py-2 border border-gray-primary rounded"
                      value={newAppDescription}
                      onChange={(evt) => setNewAppDescription(evt.target.value)}
                    />
                  </div>
                </div>

                {editBusinessServiceId ? (
                  <div className="flex flex-row">
                    <button
                      disabled={isInvalidApp}
                      onClick={() => saveBusinessServiceEdit()}
                      className={`bg-optistream-btn-default active:bg-optistream-btn-clicked text-optistream-txt-white h-8 text-sm w-44  rounded font-bold
          ${isInvalidApp && "opacity-50"}`}
                    >
                      Save edits
                    </button>
                    <button
                      disabled={isInvalidApp}
                      onClick={() =>
                        ConfirmDialog(
                          "Are you sure you want to discard your edits ?",
                          () => closeEdits()
                        )
                      }
                      className={`bg-optistream-btn-alternative active:bg-btn-altClicked text-optistream-txt-white h-8 text-sm w-44 ml-4 rounded font-bold`}
                    >
                      Discard
                    </button>
                  </div>
                ) : (
                  <div>
                    {editApplicationInfo < 0 ? (
                      <div className="flex flex-row">
                        <button
                          disabled={isInvalidApp}
                          onClick={() => createApplicationCallback()}
                          className={`bg-optistream-btn-default active:bg-optistream-btn-clicked text-optistream-txt-white h-8 text-sm w-44  rounded font-bold
                              ${isInvalidApp && "opacity-50"}`}
                        >
                          Add application
                        </button>
                        <button
                          onClick={() => setEditAppProcess(null)}
                          className={`bg-optistream-btn-alternative active:bg-btn-altClicked text-optistream-txt-white h-8 text-sm w-44 ml-4 rounded font-bold`}
                        >
                          Discard
                        </button>
                      </div>
                    ) : (
                      <div className="flex flex-row">
                        <button
                          disabled={isInvalidApp}
                          onClick={() => {
                            saveApplicationEdit();
                            getAppDataByBusinessService(editAppProcess.id);
                          }}
                          className={`bg-optistream-btn-default active:bg-optistream-btn-clicked text-optistream-txt-white h-8 text-sm w-44  rounded font-bold
                                                    ${
                                                      isInvalidApp &&
                                                      "opacity-50"
                                                    }`}
                        >
                          Save Edits
                        </button>
                        <button
                          onClick={() => {
                            AppcloseEdits();
                          }}
                          className={`bg-optistream-btn-alternative active:bg-btn-altClicked text-optistream-txt-white h-8 text-sm w-44 ml-4 rounded font-bold`}
                        >
                          Discard
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start h-1/2 w-full">
                {processApps.length ? (
                  <>
                    <div className="text-optistream-txt-blue">
                      Application attached to this process:
                    </div>
                    <div>
                      {processApps.map((app, index) => (
                        <div key={index} className="flex flex-row items-center">
                          <div>{app.name}</div>
                          <div className="flex">
                            <button
                              className="flex text-base bg-red-primary text-optistream-txt-white h-5 w-5 rounded-full items-center justify-center ml-2 pb-1"
                              onClick={() =>
                                deleteApplicationCallback(
                                  app.business_service_id,
                                  app.id
                                )
                              }
                            >
                              x
                            </button>
                            <button
                              className="flex text-base bg-optistream-blue text-optistream-txt-white h-5 w-5 rounded-full items-center text-sm justify-center ml-2 pb-1"
                              onClick={() => {
                                setEditApplicationFromIndex(index);
                                setEditApplicationInfo(index);
                              }}
                            >
                              <Pencil className="text-xs" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div>No application created for this process yet</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full w-1/2 bg-white mr-4 p-4 shrink-0">
            <div className="flex p-2 text-optistream-txt-blue font-bold text-base uppercase mb-2">
              Create a business process:
            </div>
            <div className="flex flex-col w-full justify-center items-center ml-2 h-full py-4">
              <div className="flex flex-col justify-center items-center h-full justify-evenly w-full">
                <div className="flex flex-row items-center mb-2 w-full">
                  <div className="block text-gray-base text-sm font-bold align-center w-48">
                    Business Domain
                  </div>
                  <div>
                    <Creatable
                      className="text-sm text-gray-base w-96 h-10 border border-gray-primary rounded"
                      isClearable={true}
                      value={newBusinessServiceDomain}
                      options={businessDomainOptions}
                      onChange={(selectedOption) => {
                        setNewBusinessServiceDomain(selectedOption);
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center mb-2 w-full">
                  <div className="block text-gray-base text-sm font-bold align-center w-48 ">
                    Business process name
                  </div>

                  <input
                    aria-label="Enter your new Business Service's name"
                    type="text"
                    placeholder="Enter your new Business Service's name"
                    className="text-sm text-gray-base w-96 py-2 px-4 h-10 border border-gray-primary rounded mb-1"
                    onChange={({ target }) =>
                      setNewBusinessServiceName(target.value)
                    }
                    value={newBusinessServiceName}
                  />
                </div>
                <div className="flex flex-row items-center mb-2 w-full">
                  <div className="block text-gray-base text-sm font-bold align-center w-48 ">
                    Process sensitivity
                  </div>
                  <div className="w-96">
                    <Select
                      primaryColor={"indigo"}
                      value={
                        newBusinessServiceSensitivity
                          ? {
                              value: newBusinessServiceSensitivity.value,
                              label: newBusinessServiceSensitivity.label,
                              disabled: false,
                            }
                          : null
                      }
                      isSearchable={true}
                      name="businessSensitivity"
                      placeholder="Sensitivity"
                      onChange={(element) => {
                        setNewBusinessServiceSensitivity(element);
                      }}
                      options={Utils.businessSensitivityLevels}
                      classNames={{
                        menuButton: ({ isDisabled }) =>
                          `flex w-96 text-sm border border-gray-300 rounded shadow-sm text-gray-base
                            transition-all duration-300 focus:outline-none bg-white
                            hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20 mb-1`,
                        menu: "absolute z-10 w-96 bg-white shadow-lg border rounded py-1 text-sm text-gray-200 mb-1",
                        listItem: ({ isSelected }) =>
                          `block transition duration-200 px-2 py-2 cursor-pointer
                                                          select-none truncate rounded ${
                                                            isSelected
                                                              ? `text-optistream-txt-white bg-blue-menu`
                                                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                                                          }`,
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-start mb-2 w-full">
                  <div className="block text-gray-base text-sm font-bold align-center w-48">
                    Process description
                  </div>
                  <div>
                    <textarea
                      placeholder="Business service description"
                      rows={4}
                      className="text-sm text-gray-base resize-none flex w-96 mt-1 px-4 py-2 border border-gray-primary rounded"
                      value={newBusinessServiceDescription}
                      onChange={(evt) =>
                        setNewBusinessServiceDescription(evt.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="flex flex-row items-start mb-2 w-full">
                  <div className="block text-gray-base text-sm font-bold align-center w-48">
                    Worse impact if hacked:
                  </div>
                  <div>
                    <textarea
                      placeholder="Worse impact if hacked"
                      rows={4}
                      className="text-sm text-gray-base resize-none w-96 mt-1 px-4 py-2 border border-gray-primary rounded"
                      value={newBusinessServiceWorseImpact}
                      onChange={(evt) =>
                        setNewBusinessServiceWorseImpact(evt.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              {editBusinessServiceId ? (
                <div className="flex flex-row">
                  <button
                    disabled={isInvalidBusinessService}
                    onClick={() => saveBusinessServiceEdit()}
                    className={`bg-optistream-btn-default active:bg-optistream-btn-clicked text-optistream-txt-white h-8 text-sm w-44  rounded font-bold
          ${isInvalidBusinessService && "opacity-50"}`}
                  >
                    Save edits
                  </button>
                  <button
                    disabled={isInvalidBusinessService}
                    onClick={() =>
                      ConfirmDialog(
                        "Are you sure you want to discard your edits ?",
                        () => closeEdits()
                      )
                    }
                    className={`bg-optistream-btn-alternative active:bg-btn-altClicked text-optistream-txt-white h-8 text-sm w-44 ml-4 rounded font-bold`}
                  >
                    Discard
                  </button>
                </div>
              ) : (
                <button
                  disabled={isInvalidBusinessService}
                  onClick={() => createNewBusinessService()}
                  className={`bg-optistream-btn-default active:bg-optistream-btn-clicked text-optistream-txt-white h-8 text-sm w-96  rounded font-bold
          ${isInvalidBusinessService && "opacity-50"}`}
                >
                  Create new business service
                </button>
              )}
            </div>
          </div>
        )}

        <div className="flex flex-col h-full w-1/2 bg-white p-4 ml-4">
          {businessServices !== null && Object.keys(businessServices).length ? (
            <div className="p-2 flex flex-col h-full w-full  shrink-0 grow-0 items-center">
              <div className="flex text-optistream-txt-blue font-bold text-base uppercase mb-2 w-full text-left">
                Business processes:
              </div>
              <div className="w-full h-full  mt-2 overflow-auto">
                <table className="text-sm text-left text-black-light w-full h-full">
                  <thead className="text-optistream-txt-blue w-full bg-gray-background uppercase">
                    <tr className="flex w-full items-center">
                      <th scope="col" className="px-2 py-2 flex w-4/12">
                        Business Process
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 flex w-2/12 justify-center"
                      >
                        Sensitivity
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 text-center flex w-2/12 justify-center"
                      >
                        Load
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 text-center flex w-2/12 justify-center"
                      >
                        Manage app
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 text-center flex w-2/12 justify-center"
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className=" w-full">
                    {businessServices &&
                      Object.keys(businessServices).map(
                        (domain, indexDomain) => (
                          <div key={"domain-" + indexDomain}>
                            <tr
                              className="border-b h-8 bg-optistream-orangeFaded
                            flex flex-row w-full items-center font-normal mt-2"
                            >
                              <th
                                scope="row"
                                className="font-bold px-2 py-1 flex w-full"
                              >
                                <div>
                                  Business domain:{" "}
                                  {domain !== "" ? domain : "N/A"}
                                </div>
                              </th>
                            </tr>
                            {businessServices[domain].map((service, index) => (
                              <tr
                                key={index}
                                className="border-b h-8 hover:bg-blue-faded-medium
                            flex flex-row w-full items-center font-normal"
                              >
                                <th
                                  scope="row"
                                  className="font-medium px-2 py-1 flex w-4/12"
                                >
                                  <div>{service.name}</div>
                                </th>

                                <th className="px-2 flex w-2/12 justify-center">
                                  {
                                    Utils.businessSensitivityLevels[
                                      service.sensitivity
                                    ].label
                                  }
                                </th>
                                <th className="text-center flex w-2/12 justify-center">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setEditAppProcess(null);
                                      setEditBusinessService(service);
                                    }}
                                    className="bg-optistream-btn-default 
                                                active:bg-optistream-btn-clicked h-6 w-16
                                                text-optistream-txt-white rounded font-bold"
                                  >
                                    Edit
                                  </button>
                                </th>
                                <th className="text-center flex w-2/12 justify-center">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      closeEdits();
                                      AppcloseEdits();
                                      setEditAppProcess(service);
                                      setEditApplicationInfo(-1);
                                    }}
                                    className="bg-optistream-btn-default 
                                                active:bg-optistream-btn-clicked h-6 w-16
                                                text-optistream-txt-white rounded font-bold"
                                  >
                                    Apps
                                  </button>
                                </th>
                                <th className="text-center flex w-2/12 justify-center">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      ConfirmDialog(
                                        "You are about to definitely delete your business service.",
                                        () => deleteBS(service.id)
                                      )
                                    }
                                    className="bg-optistream-btn-alternative active:bg-optistream-btn-clicked h-6 w-16
                                                                            text-optistream-txt-white rounded font-bold"
                                  >
                                    Delete
                                  </button>
                                </th>
                              </tr>
                            ))}
                          </div>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : businessServices !== null && !businessServices.length ? (
            <div className="flex flex-col h-full w-full bg-white p-4 shrink-0 grow-0 items-center">
              <div className="flex flex-col justify-center items-center text-left mb-7 mx-2 h-full w-full">
                <div className="flex flex-row items-center text-gray-500 text-2xl uppercase mt-2 mb-4">
                  <Command className="mr-4 text-3xl" />
                  Create your 1st Business Service
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col h-full w-full bg-white p-4 shrink-0 grow-0 items-center">
              <div className="flex flex-col justify-center items-center h-full w-full text-left mb-7 mx-2">
                <div className="flex text-optistream-txt-blue font-bold text-lg uppercase mt-2 mb-4">
                  Retrieving Business Services
                </div>
                <Spinner size="16" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
